import {
  ColumnDef,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table"
import { useMemo } from "react"

export const useServerTable = <TData extends object>({
  data,
  columns,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
  totalRows,
}: {
  data: TData[]
  columns: ColumnDef<TData>[]
  sorting: SortingState
  pagination: PaginationState
  onSortingChange: OnChangeFn<SortingState>
  onPaginationChange: OnChangeFn<PaginationState>
  totalRows: number
}) => {
  const pageCount = useMemo(
    () => (totalRows ? Math.ceil(totalRows / pagination.pageSize) : -1),
    [pagination.pageSize, totalRows],
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    pageCount,
    onSortingChange,
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
  })

  return table
}
