import cn from "classnames"
import nextPathSm from "images/careers/next_path_sm.svg"
import nextPath from "images/careers/next_path.svg"
import splitPathSm from "images/careers/split_path_sm.svg"
import splitPath from "images/careers/split_path.svg"
import React, { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { Card } from "v2/react/components/careers/Index/Card"
import { HalfCard } from "v2/react/components/careers/Index/HalfCard"
import { Section } from "v2/react/components/careers/Index/Section"
import type { PositionTypesConnectionNode } from "v2/redux/GraphqlApi/PositionTypesApi"

type CareerLadderPositionType = PositionTypesConnectionNode & {
  endOfLadder?: boolean
}

type LevelNode = CareerLadderPositionType | null

interface CareerLadderPositionTypes {
  current: CareerLadderPositionType
  next: LevelNode[][]
}

interface LadderSectionProps {
  positionTypes: CareerLadderPositionTypes
}

function LadderSection({ positionTypes }: LadderSectionProps) {
  const { t } = useTranslation()

  return (
    <Section title={t("v2.careers.index.jobs_in_ladder")}>
      <div className="-m-4 grid-cols-[1fr_1.5rem_1fr_1.5rem_1fr]  overflow-x-auto overflow-y-visible p-4 grid 1120:grid-cols-[1fr_2rem_1fr_2rem_1fr]">
        <CardWrapper>
          <Card positionType={positionTypes.current} currentPosition />
        </CardWrapper>
        <AdjacentCareerLevels positionTypes={positionTypes} />
      </div>
    </Section>
  )
}

const AdjacentCareerLevels = ({ positionTypes }: { positionTypes: CareerLadderPositionTypes }) => {
  if (positionTypes.current.endOfLadder) return null

  return positionTypes.next.map((level, index) => {
    const currentLevel = level
    const previousLevel = positionTypes.next[index - 1] || [positionTypes.current]
    return (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={`ladder-element-${index}`}>
        <LevelElements previousLevel={previousLevel} currentLevel={currentLevel} />
      </React.Fragment>
    )
  })
}

interface LevelElementsProps {
  previousLevel: LevelNode[]
  currentLevel: LevelNode[]
}

const LevelElements = ({ previousLevel, currentLevel }: LevelElementsProps) => {
  const currentLevelLength = currentLevel.length

  return (
    <>
      {getArrow(previousLevel, currentLevel)}
      <CardWrapper>
        {currentLevel.map((positionType) => {
          const nodeKey = `track-node-${positionType?.uniqueKey}`

          if (currentLevelLength === 1) {
            return <Card key={nodeKey} positionType={positionType} />
          }

          if (positionType) {
            return <HalfCard key={nodeKey} positionType={positionType} />
          }
          return <SpacerCard key={nodeKey} />
        })}
      </CardWrapper>
    </>
  )
}

const getArrow = (previousLevel: LevelNode[], currentLevel: LevelNode[]) => {
  const previousLength = previousLevel.filter(Boolean).length
  const currentLength = currentLevel.filter(Boolean).length

  if (previousLength === 1 && currentLength === 1) {
    return <NextArrow alignment="center" />
  }
  if (previousLength > 1 && currentLength > 1) {
    return <DoubleNextArrow />
  }
  if (previousLength === 1 && currentLength > 1) {
    return <SplitArrow />
  }
  if (previousLength > 1 && currentLength === 1) {
    const topTrack = currentLevel[0]
    const bottomTrack = currentLevel[1]
    if (topTrack) {
      return <NextArrow alignment="top" />
    }
    if (bottomTrack) {
      return <NextArrow alignment="end" />
    }
  }

  return null
}

const DoubleNextArrow = () => (
  <div className="grid-rows-[1fr_1fr] items-center px-[3px] grid">
    <div>
      <img src={nextPath} alt="Next job in career ladder" className="w-full hidden 1120:block" />
      <img src={nextPathSm} alt="Next job in career ladder" className="w-full block 1120:hidden" />
    </div>

    <div>
      <img src={nextPath} alt="Next job in career ladder" className="w-full hidden 1120:block" />
      <img src={nextPathSm} alt="Next job in career ladder" className="w-full block 1120:hidden" />
    </div>
  </div>
)

const NextArrow = ({ alignment }: { alignment: "top" | "center" | "end" }) => (
  <div className="grid-rows-[1fr_1fr] items-center px-[3px] grid">
    {alignment === "end" && <div />}

    <div
      className={cn({
        "row-start-1": alignment === "top",
        "row-span-2": alignment === "center",
        "row-start-2": alignment === "end",
      })}
    >
      <img src={nextPath} alt="Next job in career ladder" className="w-full hidden 1120:block" />
      <img src={nextPathSm} alt="Next job in career ladder" className="w-full block 1120:hidden" />
    </div>
    {alignment === "top" && <div />}
  </div>
)

const SplitArrow = () => (
  <div className="place-content-center px-[3px] grid">
    <img src={splitPath} alt="Next jobs in career ladder" className="w-full hidden 1120:block" />
    <img src={splitPathSm} alt="Next jobs in career ladder" className="w-full block 1120:hidden" />
  </div>
)

const CardWrapper = ({ children }: { children: ReactNode }) => (
  <div className="h-full min-w-[16rem] flex-col justify-end gap-2 flex md:min-w-0">{children}</div>
)

const SpacerCard = () => <div className="min-h-[5.5rem]" />

export type { CareerLadderPositionTypes, CareerLadderPositionType }
export { LadderSection }
