import React from "react"

import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

function ADP() {
  const { currentBox, toggleBox } = useSortedBoxes("adp", "position")
  const position = useAppSelector((state) => state.profilePanel.position)
  const externalIdentifier = position?.externalIdentifier

  if (!currentBox) return null

  const headerContent = <div className="title">{"adp".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{"ADP Position ID".t("org_chart")}</div>
        <div>{externalIdentifier}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { ADP }
