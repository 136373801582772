import classNames from "classnames"
import React from "react"

import { Mode, setMode } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function PanelToggle() {
  const dispatch = useAppDispatch()
  const person = useAppSelector((state) => state.profilePanel.person)
  const position = useAppSelector((state) => state.profilePanel.position)
  const panelMode = useAppSelector((state) => state.profilePanel.mode)

  const buttonStyles = (mode: Mode) =>
    classNames("btn", {
      active: mode === panelMode,
      "btn-disabled": (mode === "person" && !person) || (mode === "position" && !position),
    })
  const changePanelMode = (mode: Mode) => {
    dispatch(setMode(mode))
    window.App.Preferences.update({ org_chart: { profile_panel_mode: mode } })
  }

  return (
    <div className="react-profile-panel__toggle btn-group">
      <button
        type="button"
        disabled={!position}
        className={buttonStyles("position")}
        onClick={() => changePanelMode("position")}
      >
        {"Position".t("org_chart")}
      </button>
      <button
        type="button"
        disabled={!person}
        className={buttonStyles("person")}
        onClick={() => changePanelMode("person")}
      >
        {"Person".t("org_chart")}
      </button>
    </div>
  )
}

export { PanelToggle }
