import React, { useState } from "react"

import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { Collaborators } from "v2/react/shared/collection/Collaborators"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetParticipantDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { selectAnyErrors } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { useAppSelector } from "v2/redux/store"

import RootProvider from "../RootProvider"
import { AddNewPositionButton } from "./AddNewPositionButton"
import { ConfigureCollaboratorsModal } from "./ConfigureCollaboratorsModal"
import { GroupByToggleButton } from "./GroupByToggleButton"
import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { useShowGroupByDropzone } from "./hooks/useShowGroupByDropzone"
import { PlanTopbarMenu } from "./PlanTopbarMenu"
import { ParticipantDatasheet } from "./ShowParticipant/ParticipantDatasheet"
import { PlanTypeIndicator } from "./ShowParticipant/PlanTypeIndicator"
import { SubmitProposalButton, SubmitProposalModal } from "./ShowParticipant/SubmitProposalButton"
import { DisplayState } from "./types"

interface Props {
  headcountPlanId: string
  participantId: string
}

export function ShowParticipant({ headcountPlanId, participantId }: Props) {
  const participantPage = useGetParticipantDatasheetPageQuery({ headcountPlanId, participantId })
  const currentPerson = participantPage.data?.currentPerson
  const headcountPlan = participantPage.data?.headcountPlan
  const participant = headcountPlan?.participant
  const collaborators = participant?.collaborators
  const person = participant?.person
  const position = participant?.position
  const submitsToPerson = participant?.submitsTo?.person
  const hasSubmitted = participant?.status === "complete"
  const dropdownCellCollections = participantPage.data?.currentCompany?.dropdownCellCollections
  const isFinalized = !!headcountPlan?.lockedAt
  const [displayState, setDisplayState] = useState<DisplayState>("approved")
  const csvDownloadRef = React.useRef<HTMLButtonElement>(null)

  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = React.useState(false)
  const [collaboratorsModalIsOpen, setCollaboratorsModalIsOpen] = React.useState(false)
  const { showGroupByDropzone, setShowGroupByDropzone } = useShowGroupByDropzone(currentPerson)

  const datasheetHasErrors = useAppSelector(selectAnyErrors)
  const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
  const height = alertTop ? "calc(100vh - 100px)" : "100vh"

  const reportsTo = {
    id: position?.id ?? "",
    name: person?.name ?? "",
    title: position?.title ?? "",
    label: (person?.name || position?.title || position?.id) ?? "",
  }

  if (participantPage.isLoading) return <Spinner />

  return (
    <RootProvider>
      <div className={`h-[${height}]`}>
        <PageNav>
          <TitleBlockLarge>
            <HeadcountPlanningNavTitleWithSubtitle
              title={`${person?.name}, ${position?.title}`}
              subtitle={headcountPlan?.name}
              subtitleLinkTo={
                headcountPlan.currentUserCanManage
                  ? UrlHelper.headcountPlanPath(headcountPlanId)
                  : null
              }
            />
          </TitleBlockLarge>
          <LinkBlockSmall>
            <LinkGroup
              links={getNavLinks({
                isOwner: false,
                active: "Datasheet",
                headcountPlanId,
                participantId,
              })}
            />
          </LinkBlockSmall>
          <ActionBlock>
            <div className="gap-2 flex">
              <button
                className="flex-col justify-center bg-transparent flex"
                type="button"
                onClick={() => setCollaboratorsModalIsOpen(true)}
              >
                <Collaborators collaborators={[participant, ...collaborators]} />
              </button>
              <ConfigureCollaboratorsModal
                isOpen={collaboratorsModalIsOpen}
                setIsOpen={setCollaboratorsModalIsOpen}
                planner={{
                  id: person.id,
                  personId: person.id,
                  name: person.name,
                  avatarThumbUrl: person.avatarThumbUrl,
                }}
                existingCollaborators={collaborators}
                headcountPlanId={headcountPlanId}
                participantId={participantId}
                currentPersonId={currentPerson.id}
              />
              {isFinalized ? <PlanTypeIndicator type={displayState} /> : null}
              {hasSubmitted ? null : (
                <>
                  <SubmitProposalModal
                    submitsToPerson={submitsToPerson}
                    isOpen={submitProposalModalIsOpen}
                    setIsOpen={setSubmitProposalModalIsOpen}
                    headcountPlanId={headcountPlanId}
                    participantId={participantId}
                  />
                  <SubmitProposalButton
                    disabled={!participant?.canSubmit || datasheetHasErrors}
                    setIsOpen={setSubmitProposalModalIsOpen}
                  />
                </>
              )}
              <ExportButton csvDownloadRef={csvDownloadRef} />
              {isFinalized ? (
                <PlanTopbarMenu
                  isFinalized={isFinalized}
                  displayState={displayState}
                  setDisplayState={setDisplayState}
                  headcountPlanId={headcountPlanId}
                />
              ) : null}
            </div>
          </ActionBlock>
        </PageNav>

        <UtilityNav>
          <div className="w-full justify-between flex">
            <GroupByToggleButton on={showGroupByDropzone} setOn={setShowGroupByDropzone} />
            <AddNewPositionButton
              primary
              reportsTo={reportsTo}
              positionId={position?.id}
              headcountPlanId={headcountPlanId}
              participantId={participantId}
              startDate={headcountPlan?.startDate ?? ""}
            />
          </div>
        </UtilityNav>

        <div className="page-pad-t page-pad-x gap-8">
          <ParticipantDatasheet
            displayState={displayState}
            setDisplayState={setDisplayState}
            headcountPlanId={headcountPlanId}
            participantId={participantId}
            headcountPlan={headcountPlan}
            participant={participant}
            dropdownCellCollections={dropdownCellCollections}
            csvDownloadRef={csvDownloadRef}
            showGroupByDropzone={showGroupByDropzone}
          />
        </div>
      </div>
    </RootProvider>
  )
}
