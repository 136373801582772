import {
  GetPositionSuccessionPlanQuery,
  GetPositionSuccessionPlanQueryVariables,
} from "types/graphql.d"
import { GraphqlApi } from "v2/redux/GraphqlApi"
import { queryOperation } from "v2/redux/utils/endpoints"

export const PositionsApi = GraphqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getPositionSuccessionPlan: builder.query<
      GetPositionSuccessionPlanQuery,
      GetPositionSuccessionPlanQueryVariables
    >({
      query: queryOperation<GetPositionSuccessionPlanQueryVariables>("GetPositionSuccessionPlan"),
    }),
  }),
})

export const { useGetPositionSuccessionPlanQuery } = PositionsApi
