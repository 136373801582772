import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"

import { CareerLadder, CareerLaddersDestroyInput } from "types/graphql"
import { DeleteCareerLadderForm } from "v2/react/components/careerLadders/Index/DeleteCareerLadderForm"
import { useCareerLaddersDestroyMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"

interface Props {
  careerLadder: CareerLadder
  setShowList: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteCareerLadderButton = ({ careerLadder, setShowList }: Props) => {
  const { t } = useTranslation()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [mutate, { isLoading }] = useCareerLaddersDestroyMutation()

  const DeleteCareerLadder = (e: React.MouseEvent) => {
    e.preventDefault()
    setModalIsOpen(true)
  }

  const onClose = () => {
    setModalIsOpen(false)
    setShowList(false)
  }

  const onSubmit = async (event: FormEvent<Element>) => {
    event.preventDefault()

    const input: CareerLaddersDestroyInput = {
      careerLadderId: careerLadder.uniqueKey,
    } as CareerLaddersDestroyInput

    await mutate(input).unwrap()

    onClose()
  }

  return (
    <>
      <button
        className="dropdown-menu-link text-status-critical"
        type="button"
        onClick={DeleteCareerLadder}
      >
        <FontAwesomeIcon icon={["far", "trash-alt"]} className="icon-14 mr-2" />
        {t("v2.defaults.delete")}
      </button>

      <DeleteCareerLadderForm
        isOpen={modalIsOpen}
        isLoading={isLoading}
        onClose={onClose}
        title={t("v2.career_ladders.index.delete_career_ladder", {
          code: careerLadder.code,
          name: careerLadder.name ? ` - ${careerLadder.name}` : "",
        })}
        onSubmit={onSubmit}
      />
    </>
  )
}

export { DeleteCareerLadderButton }
