import {
  ColumnDef,
  ColumnSort,
  OnChangeFn,
  PaginationState,
  SortingState,
} from "@tanstack/react-table"
import React, { useEffect, useMemo } from "react"

import { NodeInterface, OrderClauseAttributes } from "types/graphql"
import { SortDirection } from "types/graphql.enums"
import { useServerTable } from "v2/react/hooks/useServerTable"
import { Table } from "v2/react/shared/Table"
import { useFetchPaginatedNodesQuery } from "v2/redux/GraphqlApi/DashboardApi"
import { updatePagination, updateSort } from "v2/redux/slices/DrillDownSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface DrillDownTableProps {
  columns: ColumnDef<NodeInterface>[]
  query: string
  handleDataLoaded: () => void
}

export const DrillDownTable = ({ columns, query, handleDataLoaded }: DrillDownTableProps) => {
  const { sorting, filters, pagination } = useAppSelector((state) => state.drillDown)
  const dispatch = useAppDispatch()

  const orderClauses: OrderClauseAttributes[] = useMemo(
    () =>
      sorting.map((sort: ColumnSort) => ({
        field: sort.id,
        direction: sort.desc ? SortDirection.Desc : SortDirection.Asc,
      })),
    [sorting],
  )

  const { data, isSuccess, isFetching } = useFetchPaginatedNodesQuery({
    query,
    key: `chart_${window.gon.official_chart_id}`,
    filters,
    orderClauses,
    // #paginate in rails is 1-based, but the table is 0-based
    page: pagination.pageIndex + 1,
  })

  useEffect(() => {
    if (isSuccess) {
      handleDataLoaded()
    }
  }, [isSuccess, handleDataLoaded])

  const totalCount = data?.nodeContainer?.nodePage.totalCount || 0

  const footerContent = `${"Positions Found".t("dashboard", null, "true", totalCount, [
    totalCount,
  ])}`

  // see: https://stackoverflow.com/questions/74721400/tanstack-table-how-can-i-use-redux-action-in-onpaginationchange-instead-of-set
  const setSorting: OnChangeFn<SortingState> = (updater) => {
    if (typeof updater === "function") {
      const nextState = updater(sorting)
      dispatch(updateSort(nextState))
    }
  }

  const setPagination: OnChangeFn<PaginationState> = (updater) => {
    if (typeof updater === "function") {
      const nextState = updater(pagination)
      dispatch(updatePagination(nextState))
    }
  }

  const table = useServerTable({
    data: data?.nodeContainer?.nodePage.offsetBasedNodePage || [],
    columns,
    sorting,
    pagination,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    totalRows: totalCount,
  })

  return data?.nodeContainer ? (
    <Table
      table={table}
      className="react-table__drill-down"
      footerContent={footerContent}
      isFetching={isFetching}
    />
  ) : null
}
