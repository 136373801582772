import { useListItem } from "@floating-ui/react"
import classNames from "classnames"
import React, { createElement, useContext } from "react"

import { DropdownContext } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { CopyInlineDropdownItem } from "v2/react/shared/collection/menus/Dropdown/DropdownItem/CopyInlineDropdownItem"
import { IconWrapper } from "v2/react/shared/collection/menus/Dropdown/DropdownItem/IconWrapper"

const DropdownItem = ({
  className,
  id,
  children,
  withIcon,
  onClick,
  closeAfterClick = true,
  as = "button",
  href,
  download,
  useActiveStyles,
}: {
  className?: string
  id?: string
  children: React.ReactNode
  withIcon?: React.ReactNode
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  closeAfterClick?: boolean
  as?: "button" | "div" | "a"
  href?: string
  download?: boolean
  useActiveStyles?: boolean
}) => {
  const { setIsOpen, floatingInfo } = useContext(DropdownContext)
  const { ref, index } = useListItem()

  const { activeIndex, getItemProps } = floatingInfo

  const isActive = activeIndex === index

  const Component = as

  const props = {
    ref,
    className: classNames("dropdown-menu-link", className, {
      "active !cursor-pointer": isActive,
      "bg-neutral-3": isActive && useActiveStyles,
    }),
    id,
    type: as === "button" ? "button" : undefined,
    href: as === "a" ? href : undefined,
    download: as === "a" ? download : undefined,
    tabIndex: isActive ? 0 : -1,
    role: "menuitem",
    // eslint-disable-next-line react/jsx-props-no-spreading
    ...getItemProps({
      onClick: (e) => {
        onClick?.(e)
        if (closeAfterClick) setIsOpen(false)
      },
    }),
  }

  const Children = withIcon ? <IconWrapper icon={withIcon}>{children}</IconWrapper> : children

  return createElement(Component, props, Children)
}

DropdownItem.CopyInline = CopyInlineDropdownItem

export { DropdownItem }
