import classNames from "classnames"
import React, { useState } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

interface NumericInputProps {
  id: string
  label?: string
  name?: string
  defaultValue?: string | undefined | number
  step?: string
  min?: string
  max?: string
  errors?: string
  showErrorMessage?: boolean
  useAttentionState?: boolean
  inputClassName?: string
  wrapperClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const NumericInput = ({
  id,
  label,
  name,
  defaultValue,
  step = "any",
  min = "0",
  max,
  errors,
  showErrorMessage = true,
  useAttentionState = false,
  inputClassName,
  wrapperClassName,
  onChange,
}: NumericInputProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState<string>(defaultValue?.toString() || "")

  const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange?.(e)
  }

  return (
    <InputWrapper
      label={label}
      errors={errors}
      showErrorMessage={showErrorMessage}
      id={id}
      className={classNames(wrapperClassName, {
        attention: showAttention,
      })}
    >
      <input
        type="number"
        id={id}
        name={name}
        className={classNames("numeric", "input", inputClassName)}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue || ""}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </InputWrapper>
  )
}

export { NumericInput }
