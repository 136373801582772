import React, { useState } from "react"

import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UtilityNav } from "v2/react/shared/navigation/UtilityNav"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetOwnerDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"
import { selectAnyErrors } from "v2/redux/slices/DatasheetSlice/datasheetSelectors"
import { useAppSelector } from "v2/redux/store"

import { AddNewPositionButton } from "./AddNewPositionButton"
import { GroupByToggleButton } from "./GroupByToggleButton"
import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { useShowGroupByDropzone } from "./hooks/useShowGroupByDropzone"
import { FinalizePlanButton, FinalizePlanModal } from "./Overview/FinalizePlanButton"
import { OwnerDatasheet } from "./Owner/OwnerDatasheet"
import { PlanTopbarMenu } from "./PlanTopbarMenu"
import { PlanTypeIndicator } from "./ShowParticipant/PlanTypeIndicator"
import { DisplayState } from "./types"

export function Owner({
  headcountPlanId,
  positionId,
}: {
  headcountPlanId: string
  positionId: string
}) {
  const ownerPage = useGetOwnerDatasheetPageQuery({ headcountPlanId })
  const currentPerson = ownerPage.data?.currentPerson
  const headcountPlan = ownerPage.data?.headcountPlan
  const isFinalized = !!headcountPlan?.lockedAt
  const dropdownCellCollections = ownerPage.data?.currentCompany?.dropdownCellCollections
  const [displayState, setDisplayState] = useState<DisplayState>("approved")
  const csvDownloadRef = React.useRef<HTMLButtonElement>(null)

  const [finalizePlanModalIsOpen, setFinalizePlanModalIsOpen] = useState(false)
  const { showGroupByDropzone, setShowGroupByDropzone } = useShowGroupByDropzone(currentPerson)

  const datasheetHasErrors = useAppSelector(selectAnyErrors)
  const alertTop = document.querySelector(".alert-top")?.getBoundingClientRect().height || 0
  const height = alertTop ? "calc(100vh - 50px)" : "100vh"
  const reportsTo = {
    id: currentPerson?.primaryPosition?.id,
    name: currentPerson?.name,
    title: currentPerson?.primaryPosition?.title,
    label:
      currentPerson?.name ||
      currentPerson?.primaryPosition?.title ||
      currentPerson?.primaryPosition?.id,
  }

  if (ownerPage.isLoading) return <Spinner />

  return (
    <div className={`h-[${height}]`}>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={headcountPlan.name}
            subtitle="Headcount Planning"
            subtitleLinkTo={UrlHelper.headcountPlansPath()}
          />
        </TitleBlockLarge>
        <LinkBlockSmall>
          <LinkGroup links={getNavLinks({ isOwner: true, active: "Datasheet", headcountPlanId })} />
        </LinkBlockSmall>
        <ActionBlock>
          <div className="gap-2 flex">
            {isFinalized ? <PlanTypeIndicator type={displayState} /> : null}
            {isFinalized ? null : (
              <>
                <FinalizePlanModal
                  headcountPlanId={headcountPlanId}
                  headcountPlanName={headcountPlan.name}
                  isOpen={finalizePlanModalIsOpen}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
                <FinalizePlanButton
                  canBeFinalized={headcountPlan.canBeFinalized && !datasheetHasErrors}
                  setIsOpen={setFinalizePlanModalIsOpen}
                />
              </>
            )}
            {csvDownloadRef ? <ExportButton csvDownloadRef={csvDownloadRef} /> : null}
            <PlanTopbarMenu
              isFinalized={isFinalized}
              displayState={displayState}
              setDisplayState={setDisplayState}
              headcountPlanId={headcountPlanId}
            />
          </div>
        </ActionBlock>
      </PageNav>

      <UtilityNav>
        <div className="w-full justify-between flex">
          <GroupByToggleButton on={showGroupByDropzone} setOn={setShowGroupByDropzone} />
          <AddNewPositionButton
            primary
            reportsTo={reportsTo}
            headcountPlanId={headcountPlanId}
            positionId={positionId}
            startDate={headcountPlan?.startDate}
          />
        </div>
      </UtilityNav>

      <div className="page-pad-t page-pad-x gap-8">
        <OwnerDatasheet
          displayState={displayState}
          headcountPlanId={headcountPlanId}
          csvDownloadRef={csvDownloadRef}
          headcountPlan={headcountPlan}
          dropdownCellCollections={dropdownCellCollections}
          showGroupByDropzone={showGroupByDropzone}
        />
      </div>
    </div>
  )
}
