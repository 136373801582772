import classNames from "classnames"
import React from "react"

import { DisplayValueCell } from "v2/react/shared/TableDatasheet/DisplayValueCell"

export function TypeCell({
  rowId,
  type,
  excluded,
  endDate,
}: {
  rowId: string
  type: "new" | "modified" | "unchanged"
  excluded?: boolean | null
  endDate?: string | null
}) {
  const display = getTypeDisplay(type, endDate)
  return (
    <DisplayValueCell
      id={`type-${rowId}`}
      className="non-editable-cell h-full flex-col justify-center flex"
    >
      <div
        className={classNames("justify-center flex", {
          "opacity-50": excluded,
        })}
      >
        <div className="headcount-plan-position-type">
          <div className="headcount-plan-position-type__dot-container">
            <div className={classNames("headcount-plan-position-type__dot", display.color)} />
          </div>
          <span>{display.text}</span>
        </div>
      </div>
    </DisplayValueCell>
  )
}

function getTypeDisplay(type: "new" | "modified" | "unchanged", endDate?: string | null) {
  if (type !== "new" && endDate) {
    return { color: "eliminated", text: "position_type_eliminated".t("headcount_plan") }
  }
  return { color: type, text: `position_type_${type}`.t("headcount_plan") }
}
