import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Position } from "types/graphql"
import { useEditPosition } from "v2/react/components/positions/hooks/useEditPosition"
import { ResponsibilitiesModal } from "v2/react/components/positionTypes/modals/ResponsibilitiesModal"
import { EmptyContent, EmptyState } from "v2/react/components/positionTypes/Show/EmptyState"
import { SmallEditButton } from "v2/react/components/positionTypes/Show/SmallEditButton"
import { useStringWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface Props {
  canEdit: boolean
  position: Position
}

const Responsibilities = ({ canEdit, position }: Props) => {
  const { t } = useTranslation()
  const [editModalOpen, setEditModalOpen] = useState(false)

  const uniqueKey = position.uniqueKey ?? `position_${position.id}`
  const {
    addSocketError,
    allErrors,
    cancelGeneratingFieldSuggestions,
    generateFieldSuggestions,
    resetAll,
    suggestionState,
    updatePosition,
    updateState,
  } = useEditPosition(uniqueKey)
  const { value: tasksAndResponsibilities } = useStringWithFieldSuggestion({
    addGenerateError: addSocketError,
    field: "tasks_and_responsibilities",
    getActual: () => position.tasksAndResponsibilities ?? null,
    getUniqueKey: () => uniqueKey,
    record: position,
  })

  const handleClose = () => {
    cancelGeneratingFieldSuggestions("tasks_and_responsibilities")
    resetAll()
    setEditModalOpen(false)
  }
  const handleEdit = () => setEditModalOpen(true)
  const handleSave = async (nextTasksAndResponsibilities: string) =>
    updatePosition({ tasksAndResponsibilities: nextTasksAndResponsibilities })

  return (
    <>
      <div className="module-card">
        <div className="module-card__header">
          <div className="module-title">{t("v2.positions.show.responsibilities")}</div>
          {tasksAndResponsibilities.length ? (
            <SmallEditButton
              disabled={!canEdit}
              disabledTooltipText={t("v2.positions.show.edit_disabled")}
              onClick={handleEdit}
            />
          ) : null}
        </div>
        <div className="module-card__body">
          {tasksAndResponsibilities.length ? (
            <div className="formatted-block">{sanitizeParse(tasksAndResponsibilities)}</div>
          ) : (
            <EmptyResponsibilities
              handleEdit={handleEdit}
              isAiAvailable={Boolean(position.title)}
            />
          )}
        </div>
      </div>
      <ResponsibilitiesModal
        contentType="position"
        errors={allErrors}
        isAiAvailable={Boolean(position.title)}
        isOpen={editModalOpen}
        isPerformingFieldSuggestionAction={suggestionState.isLoading}
        isSaving={updateState.isLoading}
        modalTitle={t("v2.positions.show.edit_position_responsibilities")}
        onClose={handleClose}
        onRegenerateFieldSuggestion={generateFieldSuggestions}
        onSave={handleSave}
        positionResponsibilities={tasksAndResponsibilities}
        record={position}
        uniqueKey={uniqueKey}
      />
    </>
  )
}

export { Responsibilities }

const EmptyResponsibilities = ({
  handleEdit,
  isAiAvailable,
}: {
  handleEdit: () => void
  isAiAvailable: boolean
}) => {
  const { t } = useTranslation()
  const property = t("v2.positions.show.responsibilities").toLowerCase()

  const emptyResponsibilities: EmptyContent = {
    buttonText: `${t("v2.defaults.add")} ${t("v2.positions.show.responsibilities")}`,
    note: sanitizeParse(
      isAiAvailable
        ? t("v2.positions.show.ai_add_note_html", { count: 2, property })
        : t("v2.positions.show.no_ai_add_note_html", { count: 2, property }),
    ),
    icon: "sparkles",
    onClick: handleEdit,
  }

  return (
    <EmptyState
      buttonText={emptyResponsibilities.buttonText}
      note={emptyResponsibilities.note}
      icon={emptyResponsibilities.icon}
      onClick={emptyResponsibilities.onClick}
    />
  )
}
