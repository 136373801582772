import classNames from "classnames"
import React from "react"

import { InputErrorText } from "v2/react/shared/forms/InputErrorText"
import { InputLabel } from "v2/react/shared/forms/InputLabel"

interface InputWrapperProps {
  children: React.ReactNode
  label?: string
  errors?: string
  showErrorMessage?: boolean
  renderErrors?: (errors: string) => React.ReactNode
  id?: string
  className?: string
  combineClassNames?: boolean
  onMouseEnterLabel?: React.MouseEventHandler
  onMouseLeaveLabel?: React.MouseEventHandler
}

const InputWrapper = ({
  children,
  label,
  errors,
  showErrorMessage = true,
  id,
  className,
  combineClassNames = true,
  renderErrors,
  onMouseEnterLabel,
  onMouseLeaveLabel,
}: InputWrapperProps) => (
  <div
    className={classNames(
      { "form-error": combineClassNames && errors, "input-wrapper": combineClassNames },
      className,
    )}
  >
    {label && (
      <InputLabel
        id={id}
        label={label}
        onMouseEnter={onMouseEnterLabel}
        onMouseLeave={onMouseLeaveLabel}
      />
    )}
    {children}
    {errors && showErrorMessage && renderErrors && renderErrors(errors)}
    {errors && showErrorMessage && !renderErrors && <InputErrorText message={errors} />}
  </div>
)

export { InputWrapper }
