import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createSelector } from "@reduxjs/toolkit"
import { TFunction } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

import { ImportTriggerState } from "types/graphql.enums"
import RootProvider from "v2/react/components/RootProvider"
import { useOutsideClick } from "v2/react/hooks/useOutsideClick"
import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { GraphqlApi, useGetImportTriggerStateQuery } from "v2/redux/GraphqlApi"
import { useAppSelector } from "v2/redux/store"

const selectTriggerState = GraphqlApi.endpoints.getImportTriggerState.select(undefined)
const withFallback = (_: unknown, fallback?: ImportTriggerState | null) => fallback
const selectLatestState = createSelector(
  [selectTriggerState, withFallback],
  ({ data }, fallback) => data ?? fallback ?? null,
)

/**
 * Any blank URL skips rendering a corresponding action in the dropdown.
 */
export type ImportDropdownProps = {
  adpEventsUrl?: string | null
  adpUrl?: string | null
  initialTriggerState?: ImportTriggerState | null
  listUrl?: string | null
  onlyLinks?: boolean
  paylocityUrl?: string | null
  photosUrl?: string | null
  isolvedUrl?: string | null
}

type AdpLinkProps = React.PropsWithChildren<{
  triggerState: ImportTriggerState | null
  url: string
}>

export function ImportDropdown({
  adpEventsUrl,
  adpUrl,
  initialTriggerState,
  listUrl,
  onlyLinks,
  paylocityUrl,
  photosUrl,
  isolvedUrl,
}: ImportDropdownProps) {
  const { t } = useTranslation()

  const dropdownRef = useOutsideClick<HTMLDivElement>(() => setOpen(false))
  const [open, setOpen] = React.useState(false)
  const toggleOpen = () => setOpen(true)

  const latestState = useAppSelector((state) => selectLatestState(state, initialTriggerState))
  const dropdownDisabled = isDropdownDisabled(latestState)
  const isDisabled = latestState !== ImportTriggerState.Available

  const queryConfig = { pollingInterval: isDisabled ? 500 : undefined }
  const { isLoading } = useGetImportTriggerStateQuery(undefined, queryConfig)
  if (isLoading && !latestState) return null

  const menuLinks = (
    <>
      {adpUrl ? (
        <AdpLink triggerState={latestState} url={adpUrl}>
          {onlyLinks && <i className="far fa-upload" />}
          {onlyLinks ? t("v2.orgchart.pagenav.import_adp") : t("v2.people.index.import_adp")}
        </AdpLink>
      ) : null}
      {adpEventsUrl ? (
        <AdpLink triggerState={latestState} url={adpEventsUrl}>
          {onlyLinks && <i className="far fa-upload" />}
          {onlyLinks
            ? t("v2.orgchart.pagenav.import_adp_events")
            : t("v2.people.index.import_adp_events")}
        </AdpLink>
      ) : null}
      {paylocityUrl ? (
        <a
          href={paylocityUrl}
          className="dropdown-menu-link items-center gap-2 flex"
          data-method="post"
        >
          {onlyLinks && <i className="far fa-upload" />}
          {onlyLinks
            ? t("v2.orgchart.pagenav.import_paylocity")
            : t("v2.people.index.import_paylocity")}
        </a>
      ) : null}
      {isolvedUrl ? (
        <a
          href={isolvedUrl}
          className="dropdown-menu-link items-center gap-2 flex"
          data-method="post"
        >
          {onlyLinks && <i className="far fa-upload" />}
          {onlyLinks
            ? t("v2.orgchart.pagenav.import_isolved")
            : t("v2.people.index.import_isolved")}
        </a>
      ) : null}
      {listUrl ? (
        <a href={listUrl} className="dropdown-menu-link items-center gap-2 flex">
          {onlyLinks && <i className="far fa-upload" />}
          {onlyLinks ? t("v2.orgchart.pagenav.import_list") : t("v2.people.index.import_list")}
        </a>
      ) : null}
      {photosUrl ? (
        <a
          href={photosUrl}
          className="dropdown-menu-link items-center gap-2 whitespace-nowrap flex"
        >
          {onlyLinks && <i className="far fa-upload" />}
          {onlyLinks ? t("v2.orgchart.pagenav.import_photos") : t("v2.people.index.import_photos")}
        </a>
      ) : null}
    </>
  )

  // This provides the menu links without the menu-trigger button
  if (onlyLinks) return menuLinks

  return (
    <div className="react-dropdown relative inline" role="presentation" ref={dropdownRef}>
      <button
        className="btn--large btn--secondary available"
        disabled={dropdownDisabled}
        onClick={toggleOpen}
        type="button"
      >
        <FontAwesomeIcon icon={["far", "upload"]} size="1x" />
        {dropdownLabelText(latestState, t)}
      </button>
      {open && !dropdownDisabled ? (
        <section className="react-dropdown__content react-dropdown__content--left z-10 mt-2 w-fit p-2">
          {menuLinks}
        </section>
      ) : null}
    </div>
  )
}

function AdpLink({ children, triggerState, url }: AdpLinkProps) {
  const { t } = useTranslation()
  const disabledDueToPendingChanges = isAdpLinkDisabled(triggerState)
  const preventClick: React.MouseEventHandler = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  if (disabledDueToPendingChanges) {
    return (
      <Tooltip gapSize={16} placement="right">
        <TooltipTrigger>
          <a
            href={url}
            className="dropdown-menu-link disabled items-center gap-2 whitespace-nowrap flex"
            onClick={preventClick}
          >
            {children}
          </a>
        </TooltipTrigger>
        <TooltipContent className="react-tooltip-content z-20">
          {adpLinkDisabledTooltipText(triggerState, t)}
        </TooltipContent>
      </Tooltip>
    )
  }

  return (
    <a
      href={url}
      className="dropdown-menu-link items-center gap-2 whitespace-nowrap flex"
      data-method="post"
    >
      {children}
    </a>
  )
}

export function ImportDropdownStandalone(props: ImportDropdownProps) {
  return (
    <RootProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ImportDropdown {...props} />
    </RootProvider>
  )
}

const dropdownLabelText = (triggerState: ImportTriggerState | null, t: TFunction) =>
  triggerState === ImportTriggerState.ImportBusy
    ? t("v2.people.index.import_busy")
    : t("v2.people.index.import")

const adpLinkDisabledTooltipText = (triggerState: ImportTriggerState | null, t: TFunction) => {
  switch (triggerState) {
    case ImportTriggerState.ImportBusy:
      return t("v2.people.index.import_in_progress")
    case ImportTriggerState.SyncBusy:
      return t("v2.people.index.import_blocked_by_sync_busy")
    case ImportTriggerState.SyncPausedWithErrors:
      return t("v2.people.index.import_blocked_by_sync_paused_with_errors")
    case ImportTriggerState.SyncPending:
      return t("v2.people.index.import_blocked_by_sync_pending")
    default:
      return null
  }
}

const isDropdownDisabled = (triggerState: ImportTriggerState | null) =>
  !triggerState || triggerState === ImportTriggerState.ImportBusy

const isAdpLinkDisabled = (triggerState: ImportTriggerState | null) =>
  !triggerState || triggerState !== ImportTriggerState.Available
