import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "usehooks-ts"

import { getNavLinks } from "v2/react/components/headcountPlanning/shared/navigation"
import { ExportButton } from "v2/react/shared/buttons/ExportButton"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { LinkBlockSmall } from "v2/react/shared/navigation/PageNav/LinkBlock"
import { LinkGroup } from "v2/react/shared/navigation/PageNav/LinkGroup"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { UrlHelper } from "v2/react/utils/urls"
import { useGetParticipantDatasheetPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import RootProvider from "../RootProvider"
import { HeadcountPlanningNavTitleWithSubtitle } from "./HeadcountPlanningNav"
import { ParticipantTopbarMenu } from "./ParticipantTopbarMenu"
import { TimelineView } from "./shared/TimelineView"
import { SubmitProposalButton, SubmitProposalModal } from "./ShowParticipant/SubmitProposalButton"

interface Props {
  headcountPlanId: string
  participantId: string
}

export function ParticipantTimeline({ headcountPlanId, participantId }: Props) {
  const participantPage = useGetParticipantDatasheetPageQuery({ headcountPlanId, participantId })

  const headcountPlan = participantPage.data?.headcountPlan
  const participant = headcountPlan?.participant
  const person = participant?.person
  const { t } = useTranslation()
  const csvDownloadName = [
    headcountPlan?.name?.replace(" ", ""),
    person?.name?.replace(" ", ""),
    t("v2.defaults.timeline"),
    dayjs(new Date()).format("YYYYMMDD"),
  ].join("_")
  const position = participant?.position
  const submitsToPerson = participant?.submitsTo?.person
  const hasSubmitted = participant?.status === "complete"
  const [csvDownloadRef, setCsvDownloadRef] = React.useState<HTMLButtonElement | null>(null)

  const [submitProposalModalIsOpen, setSubmitProposalModalIsOpen] = React.useState(false)

  const w = useWindowSize()

  const isExtraSmallScreen = w.width < 640

  if (participantPage.isLoading) return <Spinner />

  return (
    <RootProvider>
      <PageNav>
        <TitleBlockLarge>
          <HeadcountPlanningNavTitleWithSubtitle
            title={`${person?.name}, ${position?.title}`}
            subtitle={headcountPlan?.name}
            subtitleLinkTo={UrlHelper.headcountPlanPath(headcountPlanId)}
          />
        </TitleBlockLarge>
        <LinkBlockSmall>
          <LinkGroup
            links={getNavLinks({
              isOwner: false,
              active: "Timeline",
              headcountPlanId,
              participantId,
            })}
          />
        </LinkBlockSmall>
        <ActionBlock>
          <div className="gap-2 flex">
            {!hasSubmitted && !isExtraSmallScreen ? (
              <>
                <SubmitProposalModal
                  submitsToPerson={submitsToPerson}
                  isOpen={submitProposalModalIsOpen}
                  setIsOpen={setSubmitProposalModalIsOpen}
                  headcountPlanId={headcountPlanId}
                  participantId={participantId}
                />
                <SubmitProposalButton
                  disabled={!participant?.canSubmit}
                  setIsOpen={setSubmitProposalModalIsOpen}
                />
              </>
            ) : null}
            {isExtraSmallScreen ? (
              <ParticipantTopbarMenu
                participantId={participantId}
                headcountPlanId={headcountPlanId}
                showExportButton
                showSubmitProposalButton={!hasSubmitted}
                setRef={(element) => {
                  setCsvDownloadRef(element)
                }}
              />
            ) : (
              <ExportButton setRef={(element) => setCsvDownloadRef(element)} />
            )}
          </div>
        </ActionBlock>
      </PageNav>
      <TimelineView
        csvDownloadRef={{ current: csvDownloadRef }}
        csvDownloadName={csvDownloadName}
        headcountPlanId={headcountPlanId}
        participantId={participantId}
      />
    </RootProvider>
  )
}
