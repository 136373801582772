import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { asyncPatchPreferences } from "v2/redux/slices/ContainerSlice/containerThunks"
import { toggleSuperPanelFooter } from "v2/redux/slices/VisualizationSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

function ClosePanelButton() {
  const superPanelFooter = useAppSelector((state) => state.visualization.superPanelFooter)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    if (superPanelFooter) {
      /* eslint-disable no-alert, no-restricted-globals */
      const confirmed = confirm("You may lose changes".t("org_chart"))
      if (confirmed) {
        dispatch(toggleSuperPanelFooter(false))
        closeTab()
      }
    } else {
      closeTab()
    }
  }

  const closeTab = () => dispatch(asyncPatchPreferences({ super_panel_open: false }))

  return (
    <button type="button" className="close" onClick={handleClose}>
      <FontAwesomeIcon icon={["far", "times"]} className="my-1" />
    </button>
  )
}

export default ClosePanelButton
