import dayjs from "dayjs"
import React from "react"

import { FeatureFlags, IntegrationFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface PlanningAndForecastingProps {
  abilities: ProfilePanelAbilities
  featureFlags: FeatureFlags
  integrationFlags: IntegrationFlags
}

function PlanningAndForecasting({
  abilities,
  featureFlags,
  integrationFlags,
}: PlanningAndForecastingProps) {
  const { currentBox, toggleBox } = useSortedBoxes("position_planning", "position")
  const position = useAppSelector((state) => state.profilePanel.position)

  if (!position || !currentBox) return null
  if (!featureFlags.positionManagement) return null

  const canReadBudgeting = window.gon.can_manage_chart || abilities.canReadBudgeting
  const canReadSensitivePositionData =
    window.gon.can_manage_chart || abilities.canReadSensitivePositionData

  const employeeType = (
    <ModuleListItem>
      <div>{"Employee Type".t("org_chart")}</div>
      <div>{position?.employeeType?.labelTranslated}</div>
    </ModuleListItem>
  )

  const payGrade =
    integrationFlags.supportsPayGrades && canReadSensitivePositionData && canReadBudgeting ? (
      <ModuleListItem>
        <div>{"Pay Grade".t("org_chart")}</div>
        <div>{position.positionType?.payGrade?.codeNameLabel}</div>
      </ModuleListItem>
    ) : null

  const positionStatus = (
    <ModuleListItem>
      <div>{"Position Status".t("org_chart")}</div>
      <div>{position?.positionStatus?.label}</div>
    </ModuleListItem>
  )

  const projectedHireDate = (
    <ModuleListItem>
      <div>{"Projected Hire Date".t("org_chart")}</div>
      <div>
        {position?.projectedHireDate && dayjs(position?.projectedHireDate).format("MMM D, YYYY")}
      </div>
    </ModuleListItem>
  )

  const hiringPriority = (
    <ModuleListItem>
      <div>{"Hiring Priority".t("org_chart")}</div>
      <div>{position.hiringPriority?.t("org_chart")}</div>
    </ModuleListItem>
  )

  const budgetedBasePay = (
    <ModuleListItem>
      <div>{"Budgeted Base Pay".t("org_chart")}</div>
      <div>
        {position?.positionBasePay &&
          window.App.Helpers.formatCurrency(position.positionBasePay, { trailing: false })}
      </div>
    </ModuleListItem>
  )

  const variablePays = (position.variablePays || []).map((payType) =>
    payType.display_name ? (
      <ModuleListItem key={payType.display_name}>
        <div>{payType.display_name}</div>
        <div>{payType.amount ? payType.formatted_value : null}</div>
      </ModuleListItem>
    ) : null,
  )

  const totalAnnualBudget = (
    <ModuleListItem>
      <div>{"total_annual_budget".t("org_chart")}</div>
      <div>
        {position?.positionPayAnnualized &&
          window.App.Helpers.formatCurrency(position.positionPayAnnualized, { trailing: false })}
      </div>
    </ModuleListItem>
  )

  const numDaysOpen = (
    <ModuleListItem>
      <div>{"Num Days Open".t("org_chart")}</div>
      <div>{position?.openSince && dayjs(Date.now()).diff(position.openSince, "days")}</div>
    </ModuleListItem>
  )

  const fundingSources = (
    <ModuleListItem>
      <div>{"funding_source".t("org_chart")}</div>
      <div className="max-width-half">
        {(position?.fundingSources || []).map((source) => source.name).join(", ")}
      </div>
    </ModuleListItem>
  )

  const headerContent = () => <div className="title">{"position_planning".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent()}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {employeeType}
      {positionStatus}
      {projectedHireDate}
      {hiringPriority}
      {payGrade}
      {abilities.canReadBudgeting && budgetedBasePay}
      {abilities.canReadBudgeting && variablePays}
      {abilities.canReadBudgeting && totalAnnualBudget}
      {numDaysOpen}
      {abilities.canReadBudgeting && fundingSources}
    </AccordionModulePanel>
  )
}

export { PlanningAndForecasting }
