import classNames from "classnames"
import { GraphQLFormattedError } from "graphql"
import React from "react"

import { errorFor } from "v2/react/utils/errors"

export function PlanDates({
  disabled,
  defaultStartDate,
  defaultEndDate,
  isSuccess,
  errors,
}: {
  disabled?: boolean
  defaultStartDate?: string
  defaultEndDate?: string
  isSuccess: boolean
  errors: GraphQLFormattedError[]
}) {
  const startErrorMessage = errorFor("startDate", errors)
  const endErrorMessage = errorFor("endDate", errors)
  const isErrored = startErrorMessage || endErrorMessage
  const showEndErrorBorder = endErrorMessage && !startErrorMessage
  return (
    <div className="input-group">
      <div>
        <label htmlFor="startDate">{"time_span_label".t("headcount_plan")}</label>
      </div>
      <div className="gap-4 flex">
        <div className={classNames("w-full", { "form-error": startErrorMessage })}>
          <input
            type="date"
            id="startDate"
            className="input"
            name="startDate"
            disabled={disabled}
            defaultValue={defaultStartDate}
          />
        </div>
        <div className={classNames("w-full", { "form-error": showEndErrorBorder })}>
          <input
            type="date"
            id="endDate"
            className="input"
            name="endDate"
            disabled={disabled}
            defaultValue={defaultEndDate}
          />
        </div>
      </div>
      <div className={classNames({ "form-error": isErrored })}>
        <p className="form-error-message">{isSuccess && (startErrorMessage || endErrorMessage)}</p>
      </div>
    </div>
  )
}
