import React, { useState } from "react"

import { BioPublication, FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { AccordionModulePanelOverlay } from "v2/react/shared/layout/AccordionModulePanelOverlay"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioPublicationsProps {
  featureFlags: FeatureFlags
}

function BioPublications({ featureFlags }: BioPublicationsProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("bio_publications", "person")
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayContent, setOverlayContent] = useState<BioPublication | null>(null)

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bioPublications?.length) return null

  const headerContent = <div className="title">{"bio_publications".t("org_chart")}</div>
  const overlayHeader = <div className="title">{overlayContent?.articleName}</div>

  const overlayBody = (
    <>
      <ModuleListItem>
        <div>{"Journal or Publication Name".t("org_chart")}</div>
        <div>{overlayContent?.publicationName}</div>
      </ModuleListItem>
      <ModuleListItem>
        <div>{"Date Published".t("org_chart")}</div>
        <div>{overlayContent?.datePublished}</div>
      </ModuleListItem>
      {overlayContent?.link && (
        <ModuleListItem>
          <div>{"Link".t("org_chart")}</div>
          <div>
            <a href={overlayContent.link} target="_blank" rel="noreferrer" className="break-all">
              {overlayContent.link}
            </a>
          </div>
        </ModuleListItem>
      )}
      {overlayContent?.description && (
        <ModuleListItem>
          <div>
            <div>{"Description".t("org_chart")}</div>
            <p>{sanitizeParse(overlayContent.description)}</p>
          </div>
        </ModuleListItem>
      )}
    </>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
      showOverlay={showOverlay}
      overlayContent={
        <AccordionModulePanelOverlay
          isOpen={showOverlay}
          closePanel={() => {
            setOverlayContent(null)
            setShowOverlay(false)
          }}
          headerContent={overlayHeader}
        >
          {overlayBody}
        </AccordionModulePanelOverlay>
      }
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.bioPublications?.map((publication) => (
            <button
              key={publication.id}
              type="button"
              className="badge--link hover mb-2"
              onClick={() => {
                setOverlayContent(publication)
                setShowOverlay(true)
              }}
            >
              <div className="justify-start text-start">
                <div>{publication.articleName}</div>
                <div>
                  {publication.publicationName}
                  {publication.datePublished && `, ${publication.datePublished}`}
                </div>
              </div>
            </button>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioPublications }
