import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useEventListener } from "usehooks-ts"

import RootProvider from "v2/react/components/RootProvider"
import { useAllMutationErrors } from "v2/react/hooks/useAllMutationErrors"
import { Modal, SaveFooter } from "v2/react/shared/overlay/Modal"
import { AlertBanner } from "v2/react/shared/status/AlertBanner"
import { useUsersDestroyMutation } from "v2/redux/GraphqlApi"

type RevokeUserAccessDialogProps = {
  firstName: string
  fullName: string
  triggerSelector: string
  personId: string
  userId: string
}

function WithProviders({
  firstName,
  fullName,
  personId,
  triggerSelector,
  userId,
}: RevokeUserAccessDialogProps) {
  const { t } = useTranslation()

  const [destroyUser, destroyUserState] = useUsersDestroyMutation()
  const [isOpen, setIsOpen] = useState(false)

  useEventListener("pointerdown", (ev) => {
    const target = ev.target
    const isHTMLElement = target instanceof HTMLElement

    if (isHTMLElement && target.matches(triggerSelector)) {
      ev.preventDefault()
      handleOpen()
    }
  })

  const handleClose = () => setIsOpen(false)
  const handleDestroyUser = async () => {
    const result = await destroyUser({ personId, userId })

    const failed = "error" in result || result.data.usersDestroy?.errors?.length
    const succeeded = !failed

    if (succeeded && typeof document !== "undefined") document.location.reload()
    else if (succeeded) setIsOpen(false)
  }
  const handleOpen = () => setIsOpen(true)

  const inlineErrors = destroyUserState.data?.usersDestroy?.errors
  const rootErrors = destroyUserState.error
  const errors = useAllMutationErrors(rootErrors, inlineErrors)

  const bodyLead = t("v2.people.profile.modals.revoke_user_access.body", {
    first_name: firstName,
    full_name: fullName,
  })

  const bodyDisclaimer = t("v2.people.profile.modals.revoke_user_access.body_disclaimer", {
    first_name: firstName,
    full_name: fullName,
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("v2.people.profile.modals.revoke_user_access.modal_title")}
    >
      <div className="flex-col gap-4 px-6 py-5 flex">
        {errors.length ? <AlertBanner messages={errors} type="critical" /> : null}
        <p>{bodyLead}</p>
        <p>{bodyDisclaimer}</p>
      </div>
      <SaveFooter
        disabledText={t("v2.people.profile.modals.revoke_user_access.confirming")}
        enabledText={t("v2.people.profile.modals.revoke_user_access.confirm")}
        isSaving={destroyUserState.isLoading || destroyUserState.isSuccess}
        onCancel={handleClose}
        onSave={handleDestroyUser}
        saveType="critical"
      />
    </Modal>
  )
}

function RevokeUserAccessDialog({
  firstName,
  fullName,
  personId,
  triggerSelector,
  userId,
}: RevokeUserAccessDialogProps) {
  return (
    <React.StrictMode>
      <RootProvider>
        <WithProviders
          firstName={firstName}
          fullName={fullName}
          personId={personId}
          triggerSelector={triggerSelector}
          userId={userId}
        />
      </RootProvider>
    </React.StrictMode>
  )
}

export { RevokeUserAccessDialog, WithProviders }
