import {
  autoBatchEnhancer,
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"

import { GraphqlApi } from "v2/redux/GraphqlApi"
import { listenerMiddleware } from "v2/redux/listenerMiddleware"
import { AbilitySlice } from "v2/redux/slices/AbilitySlice"
import { AdpChangeSlice } from "v2/redux/slices/AdpChangeSlice"
import { ApprovalSlice } from "v2/redux/slices/ApprovalSlice"
import { ContainerSlice } from "v2/redux/slices/ContainerSlice"
import { DrillDownSlice } from "v2/redux/slices/DrillDownSlice"
import { GraphqlCableSlice } from "v2/redux/slices/GraphqlCableSlice"
import { GridSlice } from "v2/redux/slices/GridSlice"
import { MatrixSlice } from "v2/redux/slices/MatrixSlice"
import { NodeSlice } from "v2/redux/slices/NodeSlice"
import { NotificationSlice } from "v2/redux/slices/NotificationSlice"
import { PanelSidebarSlice } from "v2/redux/slices/PanelSidebarSlice"
import { ProfilePanelSlice } from "v2/redux/slices/ProfilePanelSlice"
import { RequisitionSlice } from "v2/redux/slices/RequisitionSlice"
import { SessionSlice } from "v2/redux/slices/SessionSlice"
import { VisualizationSlice } from "v2/redux/slices/VisualizationSlice"

import { DatasheetSlice } from "./slices/DatasheetSlice"
import { FieldSuggestionSlice } from "./slices/FieldSuggestionSlice"

type AppStore = ReturnType<typeof setupStore>
type AppGetState = typeof store.getState
type AppDispatch = typeof store.dispatch
type RootState = ReturnType<typeof reducer>

const reducer = combineReducers({
  [GraphqlApi.reducerPath]: GraphqlApi.reducer,
  ability: AbilitySlice.reducer,
  adpChange: AdpChangeSlice.reducer,
  approval: ApprovalSlice.reducer,
  container: ContainerSlice.reducer,
  datasheet: DatasheetSlice.reducer,
  drillDown: DrillDownSlice.reducer,
  fieldSuggestion: FieldSuggestionSlice.reducer,
  graphqlCable: GraphqlCableSlice.reducer,
  grid: GridSlice.reducer,
  matrix: MatrixSlice.reducer,
  node: NodeSlice.reducer,
  notification: NotificationSlice.reducer,
  panelSidebar: PanelSidebarSlice.reducer,
  profilePanel: ProfilePanelSlice.reducer,
  session: SessionSlice.reducer,
  requisition: RequisitionSlice.reducer,
  visualization: VisualizationSlice.reducer,
})

function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat(GraphqlApi.middleware),
    enhancers: [
      window.devToolsExtension ? window.devToolsExtension() : (anything: unknown) => anything,
      autoBatchEnhancer({ type: "timer", timeout: 4 }),
    ],
    reducer,
  })
}

/* eslint-disable no-underscore-dangle */
const store = setupStore(window.__PRELOADED_STATE__)

// hooks to bypass need for connect() to map state/dispatch to props
const useAppDispatch = () => useDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

if (window.__PRELOADED_STATE__) {
  delete window.__PRELOADED_STATE__
}
/* eslint-enable no-underscore-dangle */

export {
  AppDispatch,
  AppGetState,
  AppStore,
  RootState,
  setupStore,
  store,
  useAppDispatch,
  useAppSelector,
}
