import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { defaultMemoize } from "reselect"

import { Maybe, NodeInterface } from "types/graphql.d"
import { Column, NodeRow } from "v2/react/shared/Datasheet/types"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"

interface AvatarCellProps<TNode> {
  row: NodeRow
  column: Column<TNode>
  isFirst: boolean
  isLast: boolean
  style: React.CSSProperties
}

/**
 * @todo Refactor so this _does not_ use `formattedNodeProp` (couples it to the
 *   NodeInterface code).
 */
function AvatarCell<TNode>({ row, column, ...props }: AvatarCellProps<TNode>) {
  const imageSrc = formattedNodeProp(
    column.fieldKey as keyof NodeInterface,
    row.data as NodeInterface,
  )

  return (
    <div
      className={cellClassName(props.isLast)}
      style={style(props.style, props.isFirst, row.color)}
    >
      {imageSrc ? (
        <img
          role="presentation"
          className="circle circle-sm m-0"
          alt="user avatar"
          src={formattedNodeProp("avatar", row.data as NodeInterface)}
        />
      ) : (
        <div className="circle circle-sm items-center justify-center  bg-primary-8-solid text-primary-100">
          <FontAwesomeIcon icon={["far", "chair-office"]} className="icon-14" />
        </div>
      )}
    </div>
  )
}

const cellClassName = defaultMemoize((isLast: boolean) =>
  classNames("GridBody-cell", "avatar p-0 grid place-content-center", { last: isLast }),
)

const style = defaultMemoize((style, isFirst, color?: Maybe<string>) =>
  color && isFirst ? { ...(style || {}), borderLeft: `5px solid ${color}` } : style,
)

export { AvatarCell, AvatarCellProps }
