import { TextColors } from "v2/color_coding/color_coder"

import exportStyles from "../constants/exportStyles"
import { globals } from "../constants/options"
import { dimensions as descendantsIconDimensions } from "../svg/defs/defineDescendantsIcon"
import { hasStats } from "../utils/statsHelpers"

const { nodeWidth, nodePadding } = globals
const { borderWidth, height } = globals.statsBars

const innerWidth = nodeWidth - borderWidth * 2
const innerHeight = height - borderWidth * 2
const iconRightSpace = 5
const iconWidthWithSpacing = descendantsIconDimensions.width + iconRightSpace

// These values are declared here so they can be overwritten during a render
// call then used without needing to pass between the various rendering
// functions.
let y
let x
let cachedPositioningHelper

const createRoundedRectPath = (x, y, width, height, radius) =>
  `M${x},${y}
    h${width}
    v${height - radius}
    a${radius},${radius} 0 0 1 -${radius},${radius}
    h${-width + 2 * radius}
    a${radius},${radius} 0 0 1 -${radius},-${radius}
    Z`

// Recalculates common positioning values
const recalculateBasePositioning = () => {
  y =
    cachedPositioningHelper.cardYPosition() + cachedPositioningHelper.getNodeHeight() - innerHeight
  x = -(globals.nodeWidth / 2) + borderWidth
}

const cacheConfig = (positioningHelper) => {
  cachedPositioningHelper = positioningHelper
  recalculateBasePositioning(positioningHelper)
}

const update = (node, positioningHelper, clickHandler) => {
  cacheConfig(positioningHelper)
  const nodesWithDirectReportsData = (d) => (hasStats(d) ? [d] : [])

  const lines = node.selectAll("line.divider").data(nodesWithDirectReportsData)
  lines.exit().remove()
  lines.enter().append("line").attr("class", "divider")
  lines
    .attr("x1", x + nodePadding + borderWidth)
    .attr("x2", x + nodeWidth - nodePadding)
    .attr("y1", y)
    .attr("y2", y)
    .attr("stroke-width", `${borderWidth}px`)

  const texts = node.selectAll("text.stats-bar-text").data(nodesWithDirectReportsData)
  texts.exit().remove()
  texts.enter().append("text").attr("class", "stats-bar-text")
  texts
    .attr("x", -(innerWidth / 2) + iconWidthWithSpacing / 2)
    .attr("y", y + height - nodePadding) // Align text to midline
    .style(exportStyles.stats_bar_text)
    .text((d) => d.children_count)
    .attr("x", (d) => {
      const textWidth = cachedPositioningHelper.measureText(
        d.children_count,
        "direct_reports_text",
      ).width
      return -textWidth / 2 + iconWidthWithSpacing / 2
    })

  const icons = node.selectAll("use.stats-icon").data(nodesWithDirectReportsData)
  icons.exit().remove()
  icons.enter().append("use").attr("class", "stats-icon")
  icons
    // Not setting xlink:href here in order to give full control to the color
    // coder. There are two definitions for this icon; see +defineDescendantsIcon+
    .attr("x", (d) => {
      // Maybe a bit of a hacky solution for placing the icon to the left of
      // the text, but it works pretty well.
      const textLength = String(d.children_count ?? 0).length
      const numberWidth = 3

      return -(textLength * numberWidth) - iconWidthWithSpacing / 2
    })
    .attr("y", y + (height - descendantsIconDimensions.height) / 2)
    .attr("width", descendantsIconDimensions.width)
    .attr("height", descendantsIconDimensions.height)

  if (clickHandler) {
    // TODO / possible performance enhancement:
    // This element here could be appended on card hover instead of appended
    // always. It's only visible on hover.
    const clickTargets = node.selectAll("path.stats-bar").data(nodesWithDirectReportsData)
    clickTargets.exit().remove()
    clickTargets.enter().append("path").attr("class", "stats-bar")
    clickTargets
      .attr("d", () =>
        createRoundedRectPath(
          x - borderWidth,
          y,
          nodeWidth,
          height - borderWidth * 2,
          globals.nodeRadius,
        ),
      )
      .attr("fill", "transparent")
      .attr("x", x - borderWidth)
      .attr("y", y)
      .each(clickHandler)
      .on("mouseover", function (d) {
        if (d.textColorDomain) {
          const fillColor = TextColors[d.textColorDomain].extraLight

          return window.d3.select(this).attr("fill", fillColor)
        }
        return window.d3.select(this).selectAll("rect").attr("fill", TextColors.darkText.extraLight)
      })
      .on("mouseout", function () {
        window.d3.select(this).attr("fill", "transparent")
      })
  }
}

const statsBars = Object.freeze({
  update,
})

export default statsBars
