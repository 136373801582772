import React from "react"

import { FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { useAppSelector } from "v2/redux/store"

interface BioDescriptionProps {
  featureFlags: FeatureFlags
}

function BioDescription({ featureFlags }: BioDescriptionProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("bio", "person")

  if (!currentBox || !person) return null
  if (!featureFlags.bio || !person.bio) return null

  const headerContent = <div className="title">{"bio".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div>{sanitizeParse(person.bio)}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { BioDescription }
