import React, { useState } from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"
import { getCookie } from "v2/react/utils/cookies"

interface NavLinkProps {
  linkText: string
  iconClasses: string
}

function NavLink({ iconClasses, linkText }: NavLinkProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleTooltip = () => {
    if (getCookie("nav_open_state") === "true") return
    setShowTooltip(true)
  }

  return (
    <Tooltip open={showTooltip} placement="right">
      <TooltipTrigger
        onMouseEnter={handleTooltip}
        onMouseLeave={() => setShowTooltip(false)}
        className="w-full block"
      >
        <div className="nav-link--inner">
          <span>
            <i className={iconClasses} />
          </span>
          <p className="hide">{linkText}</p>
        </div>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content">
        <span className="break-all">{linkText}</span>
      </TooltipContent>
    </Tooltip>
  )
}

export { NavLink }
