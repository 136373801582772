import { TextColors } from "v2/color_coding/color_coder"

import { NodeData } from "./types"

type TextColorDomain = keyof typeof TextColors

const maxDotsToShow = 3
export const circleRadius = 4
const marginX = 4
const circleStrokeWidth = 1
const totalCircleWidth = circleRadius * 2 + marginX

const update = (
  d3Selection: d3.Selection<NodeData>,
  clickHandler: (id: number, personId: number) => void,
  yPosition: number,
): d3.Selection<NodeData> =>
  d3Selection.each(function (this: SVGElement, d) {
    if (!d.people_ids || d.people_ids.length <= 1) {
      return window.d3.select(this).selectAll("g.people-dots").remove()
    }

    const peopleIdsToRender = d.people_ids.slice(0, maxDotsToShow)
    const boundData = peopleIdsToRender.map((id) => ({
      id: d.id,
      person_id: id,
      selected: d.person_id === id,
      originalColor: TextColors.darkText.medium,
      textColorDomain: null as TextColorDomain | null,
    }))
    let peopleDotsGroup = window.d3.select(this).select(".people-dots")

    const dotsGroupWidth =
      peopleIdsToRender.length * (circleRadius * 2) +
      marginX * (peopleIdsToRender.length - 1) +
      marginX * 2
    const dotsGroupPosition = {
      x: -(dotsGroupWidth / 2),
      y: yPosition,
    }

    if (peopleDotsGroup.empty()) {
      peopleDotsGroup = window.d3
        .select(this)
        .append("g")
        .attr("class", "people-dots")
        .attr("transform", `translate(${dotsGroupPosition.x},${dotsGroupPosition.y})`)
    }

    // This "mask" line is here to hide the separator line in the boundaries of
    // this dots grouping. It gets colored with the node color coder as the
    // same color of the card. This makes the divider line look broken up. This
    // solution seemed simpler than drawing a path for the divider lines that
    // takes into account the presence and width of this people dots component.
    const personCirclesMask = peopleDotsGroup.selectAll("line.people-circles-mask").data([null])
    personCirclesMask.exit().remove()
    personCirclesMask.enter().append("line").attr("class", "people-circles-mask")

    personCirclesMask
      .attr("x1", 0)
      .attr("x2", dotsGroupWidth)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke-width", 2)

    const personCircles = peopleDotsGroup.selectAll("circle").data(boundData)

    personCircles.enter().append("circle")

    personCircles
      .attr("r", (data) => (data.selected ? circleRadius : circleRadius - circleStrokeWidth / 2))
      .attr("cx", (d, i) => circleRadius + marginX + totalCircleWidth * i)
      .attr("cy", 0)
      .attr("vector-effect", "non-scaling-stroke")
      .attr("stroke-width", 1)
      .on("click", (d) => {
        clickHandler(d.id, d.person_id)
      })
      .on("mouseover", function (this: SVGCircleElement, d) {
        // eslint-disable-next-line no-param-reassign
        d.originalColor = window.d3.select(this).attr("fill")
        const hoverColor =
          d.textColorDomain !== null
            ? TextColors[d.textColorDomain].medium
            : TextColors.darkText.medium

        window.d3.select(this).attr("fill", hoverColor)
      })
      .on("mouseout", function (this: SVGCircleElement, d) {
        // Restore the original color
        window.d3.select(this).attr("fill", d.originalColor)
      })

    personCircles.exit().remove()

    return d3Selection
  })

export default update
