import partial from "lodash/partial"

import { configureColorCoding } from "v2/redux/slices/VisualizationSlice"
import { selectColorCodingState } from "v2/redux/slices/VisualizationSlice/visualizationSelectors"
import { store } from "v2/redux/store"

import {
  codeCardColor,
  codeEdgeColor,
  codeTextColor,
  codeTextColorDomain,
} from "./color_coding/color_coder"

let self
let colorCoderWithState
let lastSeenState

/**
 * Provides a grab bag of utility functions and properties for managing
 * color codes and applying it to data.
 *
 * Initially populate the color coding data during a server side render:
 *   ColorCoding.load({ });
 *
 * Fetch/refresh color coding data:
 *   ColorCoding.asyncFetchState().then(data => console.log(data));
 *
 * Update a color code's card or edge color:
 *   ColorCoding.asyncUpdateColorInCode(
 *     'card',
 *     'potential',
 *     { id: 'high', name: 'High', true_value: 'high', order: 0 },
 *     'rgba(012, 020, 075, 0.8)'
 *   ).then(data => console.log(data));
 *
 * Access color coding state:
 *   ColorCoding.state
 *
 * Keep in mind that our chart is not yet reactive, so changes to the data store
 * don't necessarily propagate to components (no unidirectional flow).
 */
class ColorCoding {
  static get state() {
    return selectColorCodingState(store.getState())
  }

  /** Alias of updateState. */
  static load(initial = {}) {
    self.updateState(initial)
  }

  /** @see ColorCodingStore#update */
  static updateState(state) {
    return store.dispatch(configureColorCoding(state))
  }

  /**
   * Convenience function which returns the public color coding functions of
   * ColorCoding.ColorCoder. Each function is partially applied with a current
   * state snapshot.
   *
   * @example
   *   ColorCoding.getColorCoderWithState().codeCardColor(position);
   *   //#=> '#cccccc'
   *   ColorCoding.getColorCoderWithState().codeEdgeColor(position, 'fallback');
   *   //#=> 'fallback'
   *   ColorCoding.getColorCoderWithState().codeTextColor(position);
   *   //#=> 'rgba(012, 020, 075, 0.8)'
   * @return {object}
   */
  static getColorCoderWithState() {
    const currentState = self.state
    if (lastSeenState === currentState && colorCoderWithState) return colorCoderWithState

    const _ = partial.placeholder
    lastSeenState = currentState
    colorCoderWithState = {
      codeCardColor: partial(codeCardColor, currentState, _),
      codeEdgeColor: partial(codeEdgeColor, currentState, _),
      codeTextColor: partial(codeTextColor, currentState, _),
      codeTextColorDomain,
    }

    return colorCoderWithState
  }
}

self = ColorCoding

export default ColorCoding
export * from "./color_coding/color_coder"
export { ColorCoding }
