import React, { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { AITextArea } from "v2/react/components/positionTypes/modals/AITextArea"
import { ConfirmationModal } from "v2/react/components/positionTypes/modals/ConfirmationModal"
import { ErrorBanner } from "v2/react/components/positionTypes/modals/ErrorBanner"
import { InfoBanner } from "v2/react/components/positionTypes/modals/InfoBanner"
import { ContentType } from "v2/react/components/positionTypes/modals/types"
import { useStringWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { RecordWithSuggestions } from "v2/react/hooks/useTypedFieldWithSuggestion/types"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { FieldSuggestionState } from "v2/redux/slices/FieldSuggestionSlice"

type RecordWithSummary = {
  summary?: string | null
} & RecordWithSuggestions

interface Props<Record extends RecordWithSummary> {
  contentType: ContentType
  errors: string[]
  isAiAvailable?: boolean
  isOpen: boolean
  isPerformingFieldSuggestionAction: boolean
  isSaving: boolean
  modalTitle: string
  onRegenerateFieldSuggestion: (field: string) => void
  onClose: () => void
  onSave: (data: string, removeLinkedSummary: boolean) => Promise<boolean>
  positionSummary: string
  record: Record
  uniqueKey: string
}

function SummaryModal<Record extends RecordWithSummary>({
  contentType,
  errors,
  isAiAvailable = true,
  isOpen,
  isPerformingFieldSuggestionAction,
  isSaving,
  modalTitle,
  onClose,
  onRegenerateFieldSuggestion,
  onSave,
  positionSummary,
  record,
  uniqueKey,
}: Props<Record>) {
  const { t } = useTranslation()

  const {
    value: actualSummary,
    isAwaitingAction,
    isBusy,
  } = useStringWithFieldSuggestion({
    record,
    field: "summary",
    getActual: ({ summary }) => summary ?? null,
    getUniqueKey: () => uniqueKey,
    onStateChange: {
      handler: ({ suggested, fallback }) => setSummary(suggested ?? fallback),
      whenChangesTo: [FieldSuggestionState.Initialized, FieldSuggestionState.Generated],
    },
    withEphemeralEventsApplied: true,
  })

  const [summary, setSummary] = useState(actualSummary)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [removeLinkedSummary, setRemoveLinkedSummary] = useState(false)

  // `summary` is controlled by the user when the modal is open, so we refrain
  // from updating it except when an action triggered by the user finishes. We
  // do want to ensure this value synchronizes with the latest state when the
  // modal is closed.
  useEffect(() => {
    if (!isOpen) setSummary(actualSummary)
  }, [isOpen, actualSummary])

  const confirmationNeeded = () =>
    contentType === "definition" &&
    positionSummary.length &&
    !isAwaitingAction &&
    !summary.length &&
    !confirmationModalOpen

  const handleGenerateSummary = () => {
    onRegenerateFieldSuggestion("summary")
  }

  const handleSave = () => {
    const saveSummary = async () => {
      if (await onSave(summary, removeLinkedSummary)) handleClose()
      else setConfirmationModalOpen(false)
    }

    if (confirmationNeeded()) {
      setConfirmationModalOpen(true)
    } else {
      saveSummary()
    }
  }

  const handleClose = () => {
    setConfirmationModalOpen(false)
    onClose()
  }

  const handleCloseConfirmation = () => {
    setConfirmationModalOpen(false)
    handleClose()
  }

  const handleConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
    setRemoveLinkedSummary(e.target.value === "true")
  }

  if (confirmationModalOpen) {
    return (
      <ConfirmationModal
        handleRemoveLinkedData={handleConfirmation}
        modalTitle={modalTitle}
        onClose={handleCloseConfirmation}
        onSave={handleSave}
        properties={["summary"]}
        removeLinkedData={removeLinkedSummary}
      />
    )
  }

  return (
    <Modal
      footer={
        <ModalFooter
          onClose={handleClose}
          onSave={handleSave}
          saveButtonText={t("v2.defaults.save")}
          disabled={isSaving || isBusy}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      testId="summary-modal"
      title={modalTitle}
    >
      <div className="flex-col gap-y-6 p-6 flex">
        {isAiAvailable && <InfoBanner infoBannerType={contentType} />}
        <ErrorBanner errors={errors} />

        <AITextArea
          content={summary}
          handleGenerate={handleGenerateSummary}
          isAiAvailable={isAiAvailable}
          labelText={t("v2.position_types.show.summary")}
          loadingContent={isPerformingFieldSuggestionAction || isBusy}
          setData={setSummary}
        />
      </div>
    </Modal>
  )
}

export { SummaryModal }
