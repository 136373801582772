import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useState } from "react"

import { Maybe } from "types/graphql"
import { EditImageModal } from "v2/react/shared/EditImageModal"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { convertBase64 } from "v2/react/utils/files"
import { updatePersonAvatar } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch } from "v2/redux/store"

interface AvatarWithUploadProps {
  personId: string | null
  thumbUrl: Maybe<string> | undefined
  canUploadAvatar: boolean
}

// Alert the user of an error, and attempt to log it in Sentry.
const avatarErrorAlert = ({ error, logSentry = false }: { error: Error; logSentry?: boolean }) => {
  // eslint-disable-next-line no-alert
  alert("avatar_upload_error".t("org_chart"))
  if (logSentry) {
    window?.Sentry?.captureException(error)
  }
}

function AvatarWithUpload({ personId, thumbUrl, canUploadAvatar }: AvatarWithUploadProps) {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [base64Image, setBase64Image] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  if (!personId) return null

  const handleModalClose = () => setIsModalOpen(false)

  const handleUploadClick = () => fileInput.current?.click()

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      // Handles the case where the file is not an image.
      if (!file.type.startsWith("image/")) {
        if (fileInput.current) fileInput.current.value = ""
        avatarErrorAlert({ error: new Error("File is not an image") })
        return
      }

      const base64 = await convertBase64(file)
      if (base64) {
        setBase64Image(base64 as string)
        setIsModalOpen(true)
      }
    }
  }

  const submitAvatar = async (blob: Blob) => {
    const fileName = fileInput.current?.value.split(/(\\|\/)/g).pop() || "avatar.png"
    const fieldName = fileInput.current?.name || "person[avatar]"

    const formData = new FormData()
    formData.append(fieldName, blob, fileName)

    const response = await fetch(window.App.endpoint(["people", personId]), {
      method: "PUT",
      body: formData,
    })

    if (response.ok) return response.json()

    throw new Error(`HTTP error: ${response.status}`)
  }

  const handleImageSave = (blob: Blob) => {
    setIsLoading(true)
    submitAvatar(blob)
      .then((data) => {
        const avatarThumbUrl = data.avatar_thumb_url
        if (avatarThumbUrl) {
          dispatch(updatePersonAvatar(avatarThumbUrl))
        } else {
          throw new Error("Error uploading image.")
        }
      })
      .catch((error) => {
        setIsLoading(false)
        avatarErrorAlert({ error, logSentry: true })
      })
  }

  return (
    <>
      <div className="image-uploader m-0 h-fit w-fit">
        {thumbUrl && (
          <img
            src={thumbUrl}
            alt={"Profile Image".t("org_chart")}
            className="image-uploader-image img-large"
            onLoad={() => setIsLoading(false)}
          />
        )}
        {canUploadAvatar ? (
          <>
            <button type="button" className="image-uploader-action" onClick={handleUploadClick}>
              <div>
                <FontAwesomeIcon icon={["far", "plus"]} />
                <div>{"Upload Image".t("profile", "avatar")}</div>
              </div>
              <input
                type="file"
                name="person[avatar]"
                id="person_avatar"
                className="file_upload hidden"
                ref={fileInput}
                onChange={handleFileChange}
              />
            </button>
            {isLoading && (
              <div className="image-uploader-loading-indicator show">
                <Spinner />
              </div>
            )}
          </>
        ) : null}
      </div>
      {base64Image && (
        <EditImageModal
          isOpen={isModalOpen}
          onModalClose={handleModalClose}
          onModalSave={handleImageSave}
          uploadedImage={base64Image}
        />
      )}
    </>
  )
}

export { AvatarWithUpload }
