import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { JobFamilyDisplay } from "v2/react/components/careers/Index/JobFamilyDisplay"
import { CareerLadderPositionType } from "v2/react/components/careers/Index/LadderSection"
import { TruncatedTitle } from "v2/react/components/careers/Index/TruncatedTitle"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"
import { idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"

interface Props {
  currentPosition?: boolean
  positionType: CareerLadderPositionType | null
}

function Card({ currentPosition, positionType }: Props) {
  const { t } = useTranslation()

  if (!positionType) {
    return null
  }

  return (
    <motion.a
      href={
        positionType.uniqueKey
          ? UrlHelper.careerDetailsPath(idFromUniqueKey(positionType.uniqueKey))
          : "#"
      }
      role="button"
      aria-label={`View details for ${positionType.title}`}
      className={cn(
        "card hover:elevation--overlay focus:elevation--overlay text-decoration h-full min-h-[11.5rem] overflow-hidden p-0 text-neutral-100 flex hover:no-underline",
        {
          "border-2 border-solid border-primary-50": currentPosition,
        },
      )}
      whileHover={{ scale: 1.015 }}
    >
      <div className="w-full grid-rows-[auto_1fr] grid">
        <div
          className={cn("overflow-hidden border-b-neutral-8 py-4 pl-4 pr-3", {
            "bg-gradient-blue-to-l": currentPosition,
          })}
        >
          <div className="items-center justify-between overflow-hidden flex">
            <TruncatedTitle title={positionType.title || ""} titleClasses="text-base-bold" />
            {positionType.interestIndicated && (
              <FontAwesomeIcon className="text-accent-sunburst" icon={["fas", "star"]} />
            )}
          </div>
          <JobFamilyDisplay
            jobFamily={positionType.jobFamily?.name || ""}
            parentFamily={positionType.jobFamily?.parent?.name || ""}
          />
        </div>
        <div className="flex-col justify-between px-4 pb-4 pt-3 flex">
          {positionType.summary ? (
            <div className="line-clamp-3 text-neutral-64">
              {sanitizeParse(positionType.summary)}
            </div>
          ) : (
            <div />
          )}
          {currentPosition ? (
            <div className="badge--primary badge--sm mt-2 text-sm">
              {t("v2.careers.index.current_job")}
            </div>
          ) : (
            <div className="badge--blue badge--sm mt-2 text-sm">
              {t("v2.careers.index.position_count", {
                count: positionType.totalPositionCount || 0,
              })}
            </div>
          )}
        </div>
      </div>

      {positionType?.endOfLadder && <EndOfLadder currentPosition={currentPosition || false} />}
    </motion.a>
  )
}

export { Card }

export const EndOfLadder = ({ currentPosition }: { currentPosition: boolean }) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn("items-center flex", {
        "bg-primary-50 text-white": currentPosition,
        "bg-neutral-8 text-neutral-64": !currentPosition,
      })}
    >
      <p className="text-sideways text-nowrap">{t("v2.careers.index.end_of_ladder")}</p>
    </div>
  )
}
