import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React from "react"
import { useTranslation } from "react-i18next"

import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import { ErrorFallback } from "v2/react/shared/overlay/Modal/ErrorFallback"

interface ContentProps {
  title: string
  isError?: boolean
  isLoading?: boolean
  onClose: () => void
  children: React.ReactNode
}

const Content = ({
  title,
  isError = false,
  isLoading = false,
  onClose,
  children,
}: ContentProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="drawer-header">
        <p className="drawer-title">{title}</p>
        <button type="button" className="close" onClick={onClose}>
          <FontAwesomeIcon icon={["far", "times"]} className="my-1" />
        </button>
      </div>
      <ErrorFallback isError={isError} errorMessage={t("v2.defaults.error")}>
        <LoadingIndicator isLoading={isLoading}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25, type: "easeInEaseOut" }}
            className="drawer-section-content"
          >
            {children}
          </motion.div>
        </LoadingIndicator>
      </ErrorFallback>
    </>
  )
}

export { Content }
