import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import fp from "lodash/fp"
import { createStructuredSelector } from "reselect"

import { GetPersonQuery, GetPositionQuery, Person, Position } from "types/graphql"
import { RootState } from "v2/redux/store"

const selectProfileSettings = createStructuredSelector({
  sortedBoxes: (state: RootState) => state.profilePanel.sortedBoxes,
  person: (state: RootState) => state.profilePanel.person,
  personId: (state: RootState) => state.profilePanel.personId,
  position: (state: RootState) => state.profilePanel.position,
  positionId: (state: RootState) => state.profilePanel.positionId,
  mode: (state: RootState) => state.profilePanel.mode,
})

type Mode = "person" | "position"
type ProfileBox = { name: string; collapsed: boolean; mode: Mode }

type ResolvedPerson = Person | GetPersonQuery["person"]
type ResolvedPosition = Position | GetPositionQuery["position"]

interface ProfilePanelState {
  mode: Mode
  person: Person | null
  personId: string | null
  position: Position | null
  positionId: string | null
  sortedBoxes: ProfileBox[]
}

const InitialState: ProfilePanelState = {
  mode: "person",
  person: null,
  personId: null,
  position: null,
  positionId: null,
  sortedBoxes: [],
}

const ProfilePanelSlice = createSlice({
  name: "profilePanel",
  initialState: InitialState,
  reducers: {
    setMode: (state, { payload: options }: PayloadAction<Mode>) => fp.set("mode", options)(state),
    setProfilePerson: (state, { payload }: PayloadAction<ResolvedPerson | null>) =>
      fp.set("person", payload)(state),
    setPersonId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("personId", payload)(state),
    setProfilePosition: (state, { payload: options }: PayloadAction<ResolvedPosition | null>) =>
      fp.set("position", options)(state),
    setPositionId: (state, { payload }: PayloadAction<string | null>) =>
      fp.set("positionId", payload)(state),
    setSortedBoxes: (state, { payload: options }: PayloadAction<ProfileBox[]>) =>
      fp.set("sortedBoxes", options)(state),
    updatePersonAvatar: (state, { payload }: PayloadAction<string>) =>
      fp.set("person.avatarThumbUrl", payload)(state),
    updatePersonAttribute: (
      state,
      { payload }: PayloadAction<{ key: keyof Person; value: string }>,
    ) => fp.set(`person.${payload.key}`, payload.value)(state),
  },
})

export { Mode, ProfileBox, ProfilePanelSlice, ProfilePanelState, selectProfileSettings }
export const {
  setMode,
  setProfilePerson,
  setPersonId,
  setProfilePosition,
  setPositionId,
  setSortedBoxes,
  updatePersonAttribute,
  updatePersonAvatar,
} = ProfilePanelSlice.actions
