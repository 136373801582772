import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { HeadcountPlanMember, Person } from "types/graphql.d"
import { HeadcountPlanRole } from "types/graphql.enums"
import PageNav from "v2/react/shared/navigation/PageNav"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { headcountPlanMemberNameAsc } from "v2/react/utils/sorts"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetHeadcountPlanQuery,
  useSaveHeadcountPlanMembersMutation,
} from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import { InvitationsForm, InvitationsFormRef } from "./InvitationsForm"
import { StickyFooterBar } from "./StickyFooterBar"

interface Props {
  headcountPlanId: string
  companyId: number | null
  currentPersonId: number | null
}

function Invitations({ headcountPlanId, companyId, currentPersonId }: Props) {
  const [members, setMembers] = useState<HeadcountPlanMember[] | null>(null)
  const planQueryResult = useGetHeadcountPlanQuery({ id: headcountPlanId })
  const planMembers = planQueryResult.data?.headcountPlan?.members
  const [savePlanParticipants, { isLoading: isLoadingSave }] = useSaveHeadcountPlanMembersMutation()
  const selectedMembers: HeadcountPlanMember[] = (members || planMembers || [])
    .slice()
    .sort(headcountPlanMemberNameAsc)
  const formRef = React.useRef<InvitationsFormRef>(null)
  const { t } = useTranslation()

  if (!companyId || !currentPersonId || !planMembers) return null

  const addPerson = (selectedPerson: Person) => {
    const newMember = {
      id: selectedPerson.id,
      uniqueKey: `new_member_${selectedPerson.id}`,
      // Use "Participant" ("Planner" in the UI) by default
      role: HeadcountPlanRole.Participant,
      person: selectedPerson,
    }
    setMembers([...selectedMembers, newMember])
  }

  const removeMember = (selectedMember: HeadcountPlanMember) => {
    setMembers(
      selectedMembers.filter((m: HeadcountPlanMember) => m.uniqueKey !== selectedMember.uniqueKey),
    )
  }

  const updateMember = (updatedMember: HeadcountPlanMember) => {
    const withRemoved: HeadcountPlanMember[] = selectedMembers.filter(
      (m: HeadcountPlanMember) => m.uniqueKey !== updatedMember.uniqueKey,
    )
    setMembers(withRemoved.concat(updatedMember).sort(headcountPlanMemberNameAsc))
  }

  const onSave = () => !isLoadingSave && formRef.current?.handleInvitationsSubmit()

  const searchParams = new URLSearchParams(window.location.search)
  const cancelTo = searchParams.get("cancelTo")
  const mode = searchParams.get("mode")

  return (
    <>
      <PageNav>
        <TitleBlockLarge>
          <h1>
            {mode === "invite"
              ? t("v2.headcount_plan.invitations.invite.header")
              : t("v2.headcount_plan.invitations.manage.header")}
          </h1>
        </TitleBlockLarge>
      </PageNav>
      <InvitationsForm
        currentPersonId={currentPersonId}
        onMemberRemoved={removeMember}
        onMemberUpdated={updateMember}
        onPersonAdded={addPerson}
        selectedMembers={selectedMembers}
        headcountPlanId={headcountPlanId}
        savePlanParticipants={savePlanParticipants}
        isLoadingSave={isLoadingSave}
        ref={formRef}
      />
      <StickyFooterBar innerClassName="w-full sm:w-[544px]">
        <a
          href={cancelTo || UrlHelper.headcountPlanPath(headcountPlanId)}
          className="btn--large btn--secondary"
          type="submit"
        >
          {t("v2.defaults.cancel")}
        </a>
        <button
          className={`btn--large btn--primary ${isLoadingSave && "btn-disabled"}`}
          type={isLoadingSave ? "button" : "submit"}
          onClick={onSave}
        >
          {mode === "invite" ? "invitations_save_button".t("headcount_plan") : "Save".t("defaults")}
        </button>
      </StickyFooterBar>
    </>
  )
}

export { Invitations }
