import React from "react"

import { EditTableSettingsButton } from "v2/react/shared/TableUtilities/shared/EditTableSettingsButton"
import type { UtilityButtonProps } from "v2/react/shared/TableUtilities/UtilityButtons"

const Button = ({ active, onUtilityToggle }: UtilityButtonProps) => (
  <EditTableSettingsButton
    active={active}
    onClick={() => onUtilityToggle("edit-succession-table-settings")}
  />
)

export { Button }
