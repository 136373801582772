import classNames from "classnames"
import dayjs from "dayjs"
import React, { MutableRefObject, useEffect, useRef } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

interface DateInputProps {
  name: string
  label?: string
  defaultValue?: string | null
  inputRef?: MutableRefObject<HTMLInputElement | null>
  errors?: string
  inputClassName?: string
  wrapperClassName?: string
  focusOnMount?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const dateFormat = "YYYY-MM-DD"

const DateInput = ({
  inputRef,

  name,
  label,
  defaultValue,
  errors,
  inputClassName,
  wrapperClassName,
  focusOnMount = false,
  onChange,
}: DateInputProps) => {
  const dateRef = useRef<HTMLInputElement>(inputRef?.current || null)

  useEffect(() => {
    if (focusOnMount && dateRef?.current) {
      dateRef.current.focus()
    }
  }, [focusOnMount, inputRef])

  return (
    <InputWrapper label={label} errors={errors} id={name} className={wrapperClassName}>
      <input
        type="date"
        className={classNames("input", inputClassName)}
        name={name}
        defaultValue={defaultValue || dayjs(Date.now()).format(dateFormat)}
        onChange={onChange}
        ref={dateRef}
      />
    </InputWrapper>
  )
}

export { DateInput }
