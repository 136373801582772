import React from "react"

import { transitionTableCursor } from "v2/redux/slices/DatasheetSlice"
import { selectCursor } from "v2/redux/slices/DatasheetSlice/cursor/cursorSelectors"
import {
  inEitherReadOnEditable,
  inEitherWriteState,
  writingOnEditableCell,
} from "v2/redux/slices/DatasheetSlice/cursor/cursorStates"
import { CellCursor, CursorState } from "v2/redux/slices/DatasheetSlice/cursor/types"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { useDatasheetContext } from "../context"
import { TransitionKeys } from "./cursorKeyMovements"
import { createMovedCursor } from "./moveCursor"
import { Cell } from "./useCellState"

type KeyDownEvent = React.KeyboardEvent<HTMLDivElement>
type CellRef = React.RefObject<HTMLDivElement>

type Opts<TValue> = {
  moveDown?: () => void
  moveUp?: () => void
  getSaveValue: () => TValue | undefined
}

export function useCellHandlers<TValue>(
  cell: Cell,
  cellRef: CellRef,
  { getSaveValue }: Opts<TValue>,
) {
  const datasheetContext = useDatasheetContext()
  const appDispatch = useAppDispatch()
  const cursor = useAppSelector(selectCursor)

  const handleCellClick = React.useCallback(() => {
    if (
      inEitherWriteState(cursor) &&
      cursor.rowId === cell.rowId &&
      cursor.columnId === cell.columnId
    )
      return

    if (
      inEitherReadOnEditable(cursor) &&
      cursor.rowId === cell.rowId &&
      cursor.columnId === cell.columnId
    ) {
      appDispatch(
        transitionTableCursor({
          rowId: cell.rowId,
          columnId: cell.columnId,
          editable: true,
          state: CursorState.WritingOnEditable,
          fieldType: cell.fieldType,
          enteredBy: "transition",
          currentValue: null,
        }),
      )
    } else {
      appDispatch(
        transitionTableCursor(
          cell.editable
            ? {
                rowId: cell.rowId,
                columnId: cell.columnId,
                editable: true,
                state: CursorState.OnEditable,
                fieldType: cell.fieldType,
                enteredBy: "placement",
                currentValue: null,
              }
            : {
                rowId: cell.rowId,
                columnId: cell.columnId,
                editable: false,
                state: CursorState.OnNonEditable,
                fieldType: undefined,
                enteredBy: "placement",
                currentValue: null,
              },
        ),
      )
    }
  }, [cell, appDispatch, cursor])

  const handleCellKeyUp = React.useCallback(
    (event: KeyDownEvent) => {
      const cursorIfInWrite =
        inEitherWriteState(cursor) &&
        cursor.columnId === cell.columnId &&
        cursor.rowId === cell.rowId
          ? cursor
          : undefined
      const nativeEvent = "nativeEvent" in event ? event.nativeEvent : event
      if (!cursorIfInWrite) return { handled: false, event }

      const options = extractMoveAfterTo(cursorIfInWrite, nativeEvent)
      if (!options) return { handled: false, event }

      event.preventDefault()
      event.stopPropagation()
      cellRef.current?.blur?.()
      cell.dispatch({ type: "save", value: getSaveValue() })
      const nextCursor = createMovedCursor(cursor, datasheetContext.table, options.moveAfterTo)
      appDispatch(transitionTableCursor(nextCursor))
      return { handled: true, event }
    },
    [cursor, cell, cellRef, getSaveValue, datasheetContext, appDispatch],
  )

  return { handleCellClick, handleCellKeyUp }
}

const extractMoveAfterTo = (cursor: CellCursor, event: KeyboardEvent) => {
  if (!inEitherWriteState(cursor)) return undefined

  const transitionMovement = TransitionKeys.findMovement(event)

  if (writingOnEditableCell(cursor) && transitionMovement)
    return { moveAfterTo: transitionMovement.direction }

  return undefined
}
