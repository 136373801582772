import { AnimatePresence, motion } from "framer-motion"
import React from "react"

import { FeatureFlags } from "types/graphql"
import { BioAwards } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioAwards"
import { BioDescription } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioDescription"
import { BioEducation } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioEducation"
import { BioLicenses } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioLicenses"
import { BioOrganizations } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioOrganizations"
import { BioPublications } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioPublications"
import { BioSkills } from "v2/react/components/orgChart/ProfilePanel/PersonModules/BioSkills"
import { Compensation } from "v2/react/components/orgChart/ProfilePanel/PersonModules/Compensation"
import { CustomFields } from "v2/react/components/orgChart/ProfilePanel/PersonModules/CustomFields"
import { EmployeeInfo } from "v2/react/components/orgChart/ProfilePanel/PersonModules/EmployeeInfo"
import { Lists } from "v2/react/components/orgChart/ProfilePanel/PersonModules/Lists"
import { Schedule } from "v2/react/components/orgChart/ProfilePanel/PersonModules/Schedule"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { ProfileBox } from "v2/redux/slices/ProfilePanelSlice"
import { useAppSelector } from "v2/redux/store"

interface PersonModulesProps {
  abilities: ProfilePanelAbilities
}

function PersonModules({ abilities }: PersonModulesProps) {
  const { data, isFetching } = useGetFeatureFlagsQuery()
  const sortedBoxes = useAppSelector((state) => state.profilePanel.sortedBoxes)

  if (!data || !data?.currentCompany?.featureFlags || isFetching) return null
  const ff: FeatureFlags = data.currentCompany?.featureFlags

  const personModuleMap: Record<string, React.ReactNode> = {
    employee_info: <EmployeeInfo key="employee_info" abilities={abilities} featureFlags={ff} />,
    lists: <Lists key="lists" featureFlags={ff} />,
    compensation: <Compensation key="compensation" abilities={abilities} featureFlags={ff} />,
    bio: <BioDescription key="bio" featureFlags={ff} />,
    bio_skills: <BioSkills key="bio_skills" featureFlags={ff} />,
    bio_licenses: <BioLicenses key="bio_licenses" abilities={abilities} featureFlags={ff} />,
    bio_education: <BioEducation key="bio_education" featureFlags={ff} />,
    bio_publications: <BioPublications key="bio_publications" featureFlags={ff} />,
    bio_awards: <BioAwards key="bio_awards" featureFlags={ff} />,
    bio_organizations: <BioOrganizations key="bio_organizations" featureFlags={ff} />,
    custom_fields: <CustomFields key="custom_fields" featureFlags={ff} />,
    schedule: <Schedule key="schedule" />,
  }

  return (
    <AnimatePresence>
      <motion.div
        className="react-profile-panel__module"
        variants={opaqueBasic}
        initial="hidden"
        animate="shown"
        exit="hidden"
      >
        {sortedBoxes
          .filter((box: ProfileBox) => box.mode === "person")
          .map((box) => personModuleMap[box.name])}
      </motion.div>
    </AnimatePresence>
  )
}

export { PersonModules }
