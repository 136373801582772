import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ControlProps } from "@jsonforms/core"
import React, { FC, useState } from "react"

import { PreviewContainer } from "v2/react/shared/PreviewContainer"

const JsonModuleFileItem: FC<ControlProps> = ({ data, label, visible }) => {
  const [showPreview, setShowPreview] = useState(false)

  if (!visible || !data || !data.title) return null

  return (
    <>
      <div className="module-card-list-item">
        <label className="text-neutral-64">{label}</label>
        <button
          className="link gap-2 font-medium"
          type="button"
          onClick={() => setShowPreview(true)}
        >
          <FontAwesomeIcon icon={["far", "file"]} />
          <span>{data.title}</span>
        </button>
      </div>
      <PreviewContainer
        onClose={() => setShowPreview(false)}
        showPreview={showPreview}
        title={data.title}
        url={data.uploadUrl}
      />
    </>
  )
}

export { JsonModuleFileItem }
