import dayjs from "dayjs"
import React from "react"

import { GenericFormShape } from "v2/react/components/jobRequisitions/RequisitionForm/types/GenericFormShape"
import {
  annualBasePay,
  basePayLabel,
  compensationLabelPrefix,
  variablePayLabel,
} from "v2/react/shared/JsonFormControls/utils/compensation"
import { formNumericValue, formStringValue } from "v2/react/shared/JsonFormControls/utils/forms"

import { HtmlListItem } from "./HtmlListItem"
import { ListItem } from "./ListItem"

interface Props {
  data: GenericFormShape
}
type VariablePayTypeData = { amount: number | null; id: string; name: string; type: string }

function GenericModal({ data }: Props) {
  const amount = formNumericValue(data, ["position", "basePay", "amount"])
  const basePayTypeId = formStringValue(data, ["position", "basePay", "payType", "id"])
  const hours = formNumericValue(data, ["position", "basePay", "hoursPerWeek"])
  const annualBasePayAmount: number = annualBasePay(basePayTypeId, amount, hours)

  return (
    <div className="module-card">
      <div className="module-card__header">{"position_info".t("job_requisition")}</div>
      <div className="module-card__body">
        {ListItem("field_title".t("org_chart"), data.position.jobTitle?.name)}
        {ListItem("field_reports_to".t("org_chart"), data.position.reportsTo?.name)}
        {data.position.department &&
          ListItem("field_department".t("org_chart"), data.position.department?.name)}
        {data.position.businessUnit &&
          ListItem("field_business_unit".t("org_chart"), data.position.businessUnit?.name)}
        {data.position.costNumber &&
          ListItem("field_cost_number".t("org_chart"), data.position.costNumber?.name)}
        {(data.position.orgUnits || []).map((ou) => (
          <div key={ou.id}>{ListItem(ou.typeName, ou?.name ?? "")}</div>
        ))}
        {ListItem("field_location".t("org_chart"), data.position.location?.name)}
        {ListItem("field_employee_type".t("org_chart"), data.position.employeeType?.name)}
        {ListItem("field_fte".t("org_chart"), data.position.fte)}
        {ListItem("field_flsa_classification".t("org_chart"), data.position.flsa?.name)}
        {ListItem(
          "field_eeoc_classification".t("org_chart"),
          data.position.eeocClassification?.name,
        )}
        {ListItem("field_company_code".t("org_chart"), data.position.companyCode)}
        {ListItem("field_position_job_code".t("org_chart"), data.position.jobCode)}
        {ListItem("field_external_identifier".t("org_chart"), data.position.externalIdentifier)}
        {HtmlListItem("field_position_job_description".t("org_chart"), data.jobDescription)}
        {ListItem("field_hiring_priority".t("org_chart"), data.position.hiringPriority?.name)}
        {ListItem(
          "field_projected_hire_date".t("org_chart"),
          data.position.projectedHireDate
            ? dayjs(data.position.projectedHireDate).format("MM/DD/YYYY")
            : "",
        )}
        {ListItem(
          `${compensationLabelPrefix(data)} ${"field_base_pay".t("org_chart")}`,
          basePayLabel(
            data.position.basePay?.payType?.id,
            data.position.basePay?.amount,
            data.position.basePay?.hoursPerWeek,
          ),
        )}
        {(data.position.variablePayTypes || []).map((pt: VariablePayTypeData) => (
          <div key={pt.id}>
            {ListItem(
              `${compensationLabelPrefix(data)} ${pt?.name}`,
              variablePayLabel(annualBasePayAmount, pt?.type, pt?.amount),
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export { GenericModal }
