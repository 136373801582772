import classNames from "classnames"
import React from "react"
import { renderToString } from "react-dom/server"
import { useTranslation } from "react-i18next"

import { Spinner } from "v2/react/shared/loaders/Spinner"
import { pjaxReload } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { setActiveTab } from "v2/redux/slices/PanelSidebarSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

type SuccessionPlanTabType = "summary" | "compare" | "matrix"

interface TabsProps {
  positionId: string
  pjaxContainerRef: React.RefObject<HTMLDivElement>
}

function Tabs({ pjaxContainerRef, positionId }: TabsProps) {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector((state) => state.panelSidebar.activeTab)
  const { t } = useTranslation()

  const handleNavButtonClick = (activeTab: SuccessionPlanTabType) => {
    dispatch(setActiveTab(activeTab))
    // Temporary hack to show a spinner while pjax is loading. Note that if you
    // use the pjax:complete event, one must use jQuery events. Here we use a
    // brute force html replacement to insert some placeholder content, but if
    // pjax events are used, see:
    //   window
    //     .$(document)
    //     .on("pjax:complete", () => pjaxContainerRef.current?.classList.replace("hidden", "visible"))
    // https://stackoverflow.com/questions/21720367/pjax-listen-to-pjaxend-event-with-standard
    // https://github.com/defunkt/jquery-pjax/issues/349
    if (pjaxContainerRef.current) {
      const spinner = <Spinner className="" />
      const pjaxContainer = pjaxContainerRef.current
      pjaxContainer.innerHTML = renderToString(spinner)
    }

    pjaxReload({
      url: UrlHelper.successionPlanPanelPath(positionId, activeTab),
      container: "#succession-plan-panel-pjax-container",
    })
  }

  return (
    // NOTE: The container class is a temporary hack for react vs pjax
    // interplay.
    // See: app/assets/stylesheets/v2/components/succession-plan-panel.scss
    <div className="btn-group succession-plan-header-button-menu-container justify-end flex">
      <button
        className={classNames("btn", { active: activeTab === "summary" })}
        onClick={() => handleNavButtonClick("summary")}
        type="button"
      >
        {t("v2.succession_plan_panel.summary.summary")}
      </button>
      <button
        className={classNames("btn", { active: activeTab === "compare" })}
        onClick={() => handleNavButtonClick("compare")}
        type="button"
      >
        {t("v2.succession_plan_panel.compare.compare")}
      </button>
      <button
        className={classNames("btn", { active: activeTab === "matrix" })}
        onClick={() => handleNavButtonClick("matrix")}
        type="button"
      >
        {t("v2.succession_plan_panel.matrix.matrix")}
      </button>
    </div>
  )
}

export { Tabs }
