import dayjs from "dayjs"
import fp from "lodash/fp"

import { ChangeTrigger, Maybe, NodeInterface, Person } from "types/graphql"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import {
  EnhancedNodeInterface,
  FieldKey,
  NodeChangeset,
  NodePropValue,
} from "v2/redux/slices/NodeSlice/types"

const IgnoredKeys = Object.freeze([
  "custom_field_values_original",
  "id",
  "klass",
  "org_units_original",
  "variable_pays_original",
])

const DynamicFieldHashKeys = Object.freeze(["custom_field_values", "org_units", "variable_pays"])

const changePath: (key: string) => string[] = fp.cond([
  [fp.equals("custom_field_values_original"), (key) => [key]],
  [fp.equals("variable_pays_original"), (key) => [key]],
  [fp.startsWith("custom_field_"), (key) => ["custom_field_values", key]],
  [fp.startsWith("org_unit_"), (key) => ["org_units", key]],
  [fp.startsWith("variable_pay_"), (key) => ["variable_pays", key]],
  [fp.always(true), (key) => [key]],
])

/**
 * Makes a node changeset by detecting what fields changed from `prior` to
 * `next`.
 *
 * Changeset is probably not the best name, though this does capture a set of
 * changes. Really this is mostly for tracking what a prior value was and who
 * (or what) changed it to the current value.
 *
 * @public
 */
function makeNodeChangeset(
  meta: { triggeredBy?: Maybe<Person>; triggeredByType?: ChangeTrigger },
  next: NodeInterface,
  prior?: NodeInterface,
) {
  const fieldChangeset = buildBaseFieldChangeset(meta.triggeredByType, meta.triggeredBy)
  const priorOrEmpty: Partial<NodeInterface> = prior || {}
  const allKeys = getKeysToScan(next, priorOrEmpty) as FieldKey[]

  const intoChanges = (changes: NodeChangeset["changes"], key: FieldKey) => {
    const nextValue = formattedNodeProp(key, next)
    const priorValue = formattedNodeProp(key, priorOrEmpty as NodeInterface)
    if (fp.isEqual(nextValue, priorValue)) return changes

    return fp.set(key, { ...fieldChangeset, priorValue }, changes)
  }

  const changes = fp.reduce(intoChanges, {} as NodeChangeset["changes"], allKeys)
  return { nodeId: next.id, changes }
}

function pickChanges(keys: FieldKey[], node: NodeInterface) {
  return fp.reduce(
    (changes, key) => {
      const path = changePath(key)
      const val: NodePropValue = fp.propOr(null, path, node)

      if (path.length > 1) {
        const base = fp.propOr(node[path[0] as FieldKey] || {}, path[0], node)
        return fp.set(path[0], { ...base, [path[1]]: val }, changes)
      }

      return fp.set(path, val, changes)
    },
    {} as Partial<EnhancedNodeInterface>,
    addRelatedKeys(keys),
  )
}

const addRelatedKeys = (keys: FieldKey[]): (keyof EnhancedNodeInterface)[] => {
  // If we're updating any variable pays or custom fields, we need to also
  // update the `XXXX_original` field in order to keep the metadata in sync.
  return fp.concat(addRelatedCustomFieldKeys(keys), addRelatedVariablePayKeys(keys))
}

/** @private */
function getKeysToScan(next: NodeInterface, priorOrEmpty: Partial<NodeInterface>) {
  const allKeys = fp.union(fp.keys(priorOrEmpty || {}), fp.keys(next || {})) as FieldKey[]
  const onlyAllowed = fp.without(IgnoredKeys, allKeys)

  return fp.flatMap((key) => {
    if (fp.includes(key, DynamicFieldHashKeys)) {
      const dynamicHash = next[key as FieldKey] as { [key: string]: Maybe<string> }
      return fp.keys(dynamicHash)
    }

    return [key]
  }, onlyAllowed)
}

/** @private */
function buildBaseFieldChangeset(triggeredByType?: ChangeTrigger, triggeredBy?: Maybe<Person>) {
  return {
    authorAvatarUrl: triggeredBy ? triggeredBy.avatarThumbUrl : null,
    authorName: triggeredBy ? triggeredBy.name : null,
    authorId: triggeredBy ? triggeredBy.id : null,
    authorType: triggeredByType,
    timestamp: dayjs().toISOString(),
    priorValue: "",
  }
}

/** @private */
const addRelatedCustomFieldKeys = (keys: FieldKey[]): (keyof EnhancedNodeInterface)[] => {
  if (fp.some((key) => fp.startsWith("custom_field_", key), keys)) {
    return fp.union(keys, ["custom_field_values_original"])
  }
  return keys
}

/** @private */
const addRelatedVariablePayKeys = (keys: FieldKey[]): (keyof EnhancedNodeInterface)[] => {
  if (fp.some((key) => fp.startsWith("variable_pay_", key), keys)) {
    return fp.union(keys, ["variable_pays_original"])
  }
  return keys
}

export { makeNodeChangeset, pickChanges }
