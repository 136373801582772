import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

import { Tooltip, TooltipContent, TooltipTrigger } from "v2/react/shared/overlay/Tooltip"

interface EmailIconProps {
  email: string
}

function EmailIcon({ email }: EmailIconProps) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <a href={`mailto:${email}`} className="text-neutral-80 inline-block">
          <FontAwesomeIcon icon={["far", "envelope"]} />
        </a>
      </TooltipTrigger>
      <TooltipContent className="react-tooltip-content--sm break-all">
        {"click to email".t("org_chart", null, null, null, [email])}
      </TooltipContent>
    </Tooltip>
  )
}

export { EmailIcon }
