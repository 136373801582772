import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"

import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { Link } from "v2/react/shared/navigation/PageNav/LinkGroup"

interface Props {
  classes?: string
  links: Link[]
  menuText: string
}

function LinkMenuGroup({ classes, links, menuText }: Props) {
  const [showMenu, setShowMenu] = useState(false)
  const {
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "bottom-end" },
  })

  const handleOpen = () => {
    refs.domReference.current?.focus()
    setActiveIndex(showMenu ? null : 0)
  }

  const handleSelect = (url: string) => {
    window.location.href = url
  }

  return (
    <div className="dropdown--react nav-overflow-menu">
      <button
        type="button"
        className={classNames("btn dropdown-link items-center gap-1 !flex", classes)}
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({ onClick: handleOpen })}
      >
        <p>{menuText}</p>
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </button>
      <DropdownMenu
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, minWidth: "13.5rem", right: 0 }}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu !block z-10 !right-0 mt-[2px]"
        context={context}
      >
        {links.map((link: Link, index) => (
          <a
            key={link.url}
            href={link.url}
            className={classNames(
              "dropdown-menu-link items-center justify-between whitespace-nowrap flex",
              {
                active: link.active,
              },
            )}
            ref={(node) => {
              listRef.current[index] = node
            }}
            /* eslint-disable react/jsx-props-no-spreading */
            {...getItemProps({
              onClick: () => handleSelect(link.url),
            })}
          >
            <p>{link.text}</p>
            {link.active && (
              <FontAwesomeIcon className="selected-icon ml-1" icon={["far", "check-circle"]} />
            )}
          </a>
        ))}
      </DropdownMenu>
    </div>
  )
}

export { LinkMenuGroup }
