import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import React, { useRef, type ReactNode } from "react"

import { DraggableList } from "v2/react/shared/collection/DraggableList/DraggableList"
import { SearchableDropdown } from "v2/react/shared/forms/SearchableDropdown"

interface SortableItemType {
  id: string
  label: string
}

interface FieldsetWithSearchProps<T> {
  id: string
  label?: string
  description?: string
  selectedItems: T[]
  searchableItems: T[]
  onReorder: (updatedItems: T[]) => void
  onSearchSelect: (selectedItem: T) => void
  onCheckboxChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  makeLabel?: (item: T) => ReactNode
  makeCheckboxId?: (item: T) => string
  makeCheckboxName?: (item: T) => string
  searchPlaceholder?: string
  noResultsPlaceholder?: string
  /**
   * Adds a border around the search field and the list of selected items.
   */
  useBorder?: boolean
}

const FieldsetWithSearch = <T extends SortableItemType>({
  id,
  label,
  description,
  selectedItems,
  searchableItems,
  onReorder,
  onSearchSelect,
  onCheckboxChange,
  makeLabel,
  makeCheckboxId,
  makeCheckboxName,
  searchPlaceholder,
  noResultsPlaceholder,
  useBorder,
}: FieldsetWithSearchProps<T>) => {
  const dragContainerRef = useRef<HTMLDivElement>(null)

  return (
    <fieldset id={id} className="mb-0 min-w-0 flex-col flex">
      {label && (
        <legend
          className={cn("text-base-bold", {
            "mb-1": !description,
          })}
        >
          {label}
        </legend>
      )}
      {description && <p className="mb-1 text-neutral-64">{description}</p>}
      <div
        className={cn({
          "list-group is-draggable": useBorder,
        })}
      >
        <div
          className={cn("w-full pb-1", {
            "px-4 pt-2": useBorder,
          })}
        >
          <SearchableDropdown
            id={`${id}-search`}
            items={searchableItems}
            onSelect={onSearchSelect}
            searchPlaceholder={searchPlaceholder}
            noResultsPlaceholder={noResultsPlaceholder}
            makeLabel={makeLabel}
          />
        </div>
        <DraggableList
          dragContainerRef={dragContainerRef}
          items={selectedItems}
          handleReorder={onReorder}
          useListGroupStyle={!useBorder}
          draggableListClasses={cn({
            "shadow-none p-0 z-auto list-group is-draggable": !useBorder,
            "pt-1": selectedItems.length === 0 && useBorder,
            "pb-1": selectedItems.length > 0 && useBorder,
          })}
        >
          {selectedItems.map((item) => (
            <DraggableList.Item
              key={`${id}-${item.id}`}
              item={item}
              dragContainerRef={dragContainerRef}
              itemClasses={cn("py-1", {
                "pl-1 pr-2": !useBorder,
              })}
            >
              <div className="w-full gap-3 flex">
                <FontAwesomeIcon
                  icon={["fas", "grip-vertical"]}
                  size="sm"
                  className="mt-1 items-start self-stretch"
                />
                <label
                  htmlFor={makeCheckboxId ? makeCheckboxId(item) : item.id}
                  className="!m-0 w-full min-w-0 cursor-pointer items-start self-stretch font-medium transition-colors flex"
                >
                  <input
                    type="checkbox"
                    className="!mr-2 mt-1"
                    id={makeCheckboxId ? makeCheckboxId(item) : item.id}
                    name={makeCheckboxName ? makeCheckboxName(item) : item.id}
                    data-show-field={item.id}
                    onChange={onCheckboxChange}
                    value={item.id}
                    defaultChecked
                  />
                  <div className="w-full min-w-0 break-words">
                    {makeLabel ? makeLabel(item) : item.label}
                  </div>
                </label>
              </div>
            </DraggableList.Item>
          ))}
        </DraggableList>
      </div>
    </fieldset>
  )
}

export { FieldsetWithSearch }
export type { FieldsetWithSearchProps }
