import React from "react"

import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { StoreProvider } from "v2/redux/StoreProvider"

type InternalReqInfoProps = {
  requisitions: { reqId: string; approvalState?: string | null }[]
}

function InternalReqInfo({ requisitions }: InternalReqInfoProps) {
  return (
    <StoreProvider>
      <div className="w-full">
        {requisitions.map((req) => (
          <div key={req.reqId}>
            <div className="mb-4 justify-between flex">
              <div>Req ID</div>
              <div>{req.reqId}</div>
            </div>
            <div className="mb-4 justify-between flex">
              <div>Req Approval State</div>
              <div>
                {req.approvalState ? (
                  <StatusBadge status={req.approvalState} displayType="button" />
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </StoreProvider>
  )
}

export { InternalReqInfo }
