import React from "react"
import { useTranslation } from "react-i18next"

import { EmptyState } from "v2/react/components/careers/Show/EmptyState"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface ResponsibilitiesProps {
  responsibilities: string
}

const Responsibilities = ({ responsibilities }: ResponsibilitiesProps) => {
  const { t } = useTranslation()
  const responsibilitiesText = t("v2.position_types.show.responsibilities")

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{responsibilitiesText}</div>
      </div>
      <div className="module-card__body">
        {responsibilities ? (
          <div>{sanitizeParse(responsibilities)}</div>
        ) : (
          <EmptyState
            note={sanitizeParse(
              t("v2.careers.show.empty_module_html", {
                count: 2,
                property: responsibilitiesText.toLowerCase(),
              }),
            )}
            icon="far list-ul"
          />
        )}
      </div>
    </div>
  )
}

export { Responsibilities }
