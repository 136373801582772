import classNames from "classnames"
import React from "react"

import { CareerLadder, CareerLadderTrack } from "types/graphql"
import { EditableBadge } from "v2/react/components/careerLadders/Builder/EditableBadge"
import { TruncatedTitle } from "v2/react/components/careers/Index/TruncatedTitle"
import { useCareerLaddersUpdateCareerLadderTrackMutation } from "v2/redux/GraphqlApi/CareerLaddersApi"

type TitleColumnsForTrackProps = {
  careerLadder: CareerLadder
  editMode: boolean
  smallMode?: boolean
  track: CareerLadderTrack
}

const TitleColumnsForTrack = ({
  careerLadder,
  editMode,
  track,
  smallMode = false,
}: TitleColumnsForTrackProps) => {
  const childTracks = (careerLadder.careerLadderTracks || []).filter(
    (t: CareerLadderTrack) => t.parentTrackId === track.id,
  )

  // If there is just a primary track, we omit any labels. The primary track
  // does not have a label.
  if (!childTracks) return null

  return (
    <div
      className={classNames("mb-1 justify-center gap-2 grid", `grid-cols-${childTracks.length}`)}
    >
      {childTracks.map((track: CareerLadderTrack) => (
        <div className="w-full justify-center flex" key={track.id}>
          <TrackTitle
            careerLadder={careerLadder}
            editMode={editMode}
            smallMode={smallMode}
            track={track}
          />
        </div>
      ))}
    </div>
  )
}

export { TitleColumnsForTrack }

interface TrackTitleProps {
  careerLadder: CareerLadder
  editMode: boolean
  smallMode: boolean
  track: CareerLadderTrack
}

const TrackTitle = ({ careerLadder, editMode, smallMode, track }: TrackTitleProps) => {
  const [mutate] = useCareerLaddersUpdateCareerLadderTrackMutation()

  if (!editMode && !track.name) return <span />
  const displayOnly = smallMode

  const handleSave = async (inputValue: string) => {
    await mutate({
      careerLadderId: careerLadder.id,
      careerLadderTrackId: track.uniqueKey,
      name: inputValue,
    })
  }

  return displayOnly ? (
    <DisplayBadge displayValue={track.name || ""} />
  ) : (
    <EditableBadge
      displayValue={track.name || ""}
      editingValue={track.name || ""}
      isEditing={editMode}
      onSave={handleSave}
    />
  )
}

const DisplayBadge = ({ displayValue }: { displayValue: string }) => (
  <span className="badge--blue badge--sm !min-w-[auto] !max-w-[160px] truncate py-0">
    <TruncatedTitle title={displayValue} titleClasses="text-xs" />
  </span>
)
