import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import React from "react"

import { HeadcountPlanStatus } from "types/graphql.d"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import PageNav from "v2/react/shared/navigation/PageNav"
import { ActionBlock } from "v2/react/shared/navigation/PageNav/ActionBlock"
import { TitleBlockLarge } from "v2/react/shared/navigation/PageNav/TitleBlock"
import { StatusBadge } from "v2/react/shared/status/StatusBadge"
import { useGetListHeadcountPlansPageQuery } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

import RootProvider from "../RootProvider"
import { CreatePlanButton } from "./ListPlans/CreatePlanButton"

function Internal() {
  const listPlansPage = useGetListHeadcountPlansPageQuery({})
  const currentPerson = listPlansPage.data?.currentPerson
  const activeHeadcountPlans = listPlansPage.data?.currentCompany?.activeHeadcountPlans
  const pastHeadcountPlans = listPlansPage.data?.currentCompany?.pastHeadcountPlans

  if (listPlansPage.isLoading) return <Spinner />

  return (
    <>
      <PageNav>
        <TitleBlockLarge>
          <h1>{"list_plans_title".t("headcount_plan")}</h1>
        </TitleBlockLarge>
        <ActionBlock>
          {currentPerson?.isCompanyManagerOrHrAdmin ? <CreatePlanButton /> : null}
        </ActionBlock>
      </PageNav>
      <div className="m-8 flex-col gap-8 flex">
        <PlanTable
          title={"plan_table_column_active_plans".t("headcount_plan")}
          plans={activeHeadcountPlans}
        />
        {pastHeadcountPlans && pastHeadcountPlans.length > 0 ? (
          <PlanTable
            title={"plan_table_column_past_plans".t("headcount_plan")}
            plans={pastHeadcountPlans}
          />
        ) : null}
      </div>
    </>
  )
}

type PlanTableProps = {
  title: string
  plans?: {
    id: string
    name: string
    startDate: string
    endDate: string
    status: HeadcountPlanStatus
    finalAggregations: {
      totalPositionsCount?: number | null | undefined
      newPositionsCount?: number | null | undefined
    }
  }[]
}
function PlanTable({ title, plans }: PlanTableProps) {
  return (
    <div className="rounded-xl border border-solid border-neutral-8">
      <table className="rounded-xl bg-white">
        <thead>
          <tr className="border-b-solid border-b border-b-neutral-8">
            <th className="w-4/6">{title}</th>
            <th className="w-1/12 text-right">
              {"plan_table_column_total_positions".t("headcount_plan")}
            </th>
            <th className="w-1/12 text-right">
              {"plan_table_column_new_positions".t("headcount_plan")}
            </th>
            <th className="w-1/6 text-center">{"plan_table_column_status".t("headcount_plan")}</th>
          </tr>
        </thead>
        <tbody>
          {plans?.map((plan) => (
            <tr key={plan.id}>
              <td>
                <div className="gap-4 flex">
                  <div className="flex-col justify-center flex">
                    <PlanIcon />
                  </div>
                  <div>
                    <a
                      href={`/headcount_plans/${plan.id}`}
                      className="text-base font-bold text-neutral-100"
                    >
                      {plan.name}
                    </a>
                    <p className="text-sm">
                      {formatDate(plan.startDate)} - {formatDate(plan.endDate)}
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-right">{plan.finalAggregations.totalPositionsCount}</td>
              <td className="text-right">{plan.finalAggregations.newPositionsCount}</td>
              <td>
                <div className="justify-center flex">
                  <HCPStatusBadge status={plan.status} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function HCPStatusBadge({ status }: { status: HeadcountPlanStatus }) {
  if (status === HeadcountPlanStatus.Approved) {
    return <StatusBadge status="plan_approved" displayType="button" checkmarkOnApproved />
  }
  if (status === HeadcountPlanStatus.InProgress) {
    return <div className="badge--yellow">{"plan_status_in_progress".t("headcount_plan")}</div>
  }
  if (status === HeadcountPlanStatus.AllPlansSubmitted) {
    return <div className="badge--purple">{"plan_status_all_submitted".t("headcount_plan")}</div>
  }
}

function formatDate(date: string) {
  return dayjs(date).format("MMM D, YYYY")
}

function PlanIcon() {
  return (
    <div className="h-9 w-9 grid-cols-1 items-center justify-items-center rounded-full bg-primary-10 p-2 grid">
      <FontAwesomeIcon icon={["far", "usd-square"]} />
    </div>
  )
}

export function ListPlans() {
  return (
    <RootProvider>
      <Internal />
    </RootProvider>
  )
}
