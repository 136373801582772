import React, { useState } from "react"

import {
  Approver,
  ConfigureJobRequisitionApprovalsInput,
  NotificationRecipient,
  Person,
} from "types/graphql"
import { ApproverSelection } from "v2/react/components/approvals/ApproverSelection"
import { PersonSelection } from "v2/react/components/approvals/PersonSelection"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { ApproverType } from "v2/react/shared/status/statusUtils"
import { useConfigureJobRequisitionApprovalsMutation } from "v2/redux/GraphqlApi/JobRequisitionsApi"
import {
  setEditApproversModalOpen,
  setEditNotifyeesModalOpen,
} from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  approvers: ApproverType[]
  jobRequisitionId: string
  notificationRecipients: NotificationRecipient[]
}

function ApproverAndNotifyeeModals({ approvers, jobRequisitionId, notificationRecipients }: Props) {
  const [selectedApprovers, setSelectedApprovers] = useState<Approver[] | null>(null)
  const [selectedPeople, setSelectedPeople] = useState<Person[] | null>(null)
  const editApproversModalOpen = useAppSelector((state) => state.requisition.editApproversModalOpen)
  const editNotifyeesModalOpen = useAppSelector(
    (state) => state.requisition.editNotifyeessModalOpen,
  )

  const [mutate, { isLoading: isSaving }] = useConfigureJobRequisitionApprovalsMutation()

  const dispatch = useAppDispatch()

  const onPeopleUpdated = (selectedPeople: Person[]) => {
    setSelectedPeople(selectedPeople)
  }

  const onApproversUpdated = (selectedApprovers: Approver[]) => {
    setSelectedApprovers(selectedApprovers)
  }

  const cancelApproversModal = () => {
    setSelectedApprovers(null)
    dispatch(setEditApproversModalOpen(false))
  }

  const cancelNotifyeesModal = () => {
    setSelectedPeople(null)
    dispatch(setEditNotifyeesModalOpen(false))
  }

  const closeModal = () => {
    setSelectedApprovers(null)
    setSelectedPeople(null)
    dispatch(setEditApproversModalOpen(false))
    dispatch(setEditNotifyeesModalOpen(false))
  }

  const existingApprovers = (): Approver[] =>
    approvers.map((a) => ({
      id: a.id,
      state: a.state || "",
      person: a.person,
      sortOrder: a.sortOrder || 0,
      note: a.note || "",
    }))
  const existingNotificationRecipients = (): Person[] =>
    notificationRecipients.map((nr) => nr.person)

  const updatedApprovers: Approver[] = editApproversModalOpen
    ? selectedApprovers || existingApprovers()
    : existingApprovers()
  const updatedNotificationRecipients: Person[] = editNotifyeesModalOpen
    ? selectedPeople || existingNotificationRecipients()
    : existingNotificationRecipients()

  const handleSave = async () => {
    const input: ConfigureJobRequisitionApprovalsInput = {
      approvers: updatedApprovers.map((a) => ({
        approvableId: `job_requisition_${jobRequisitionId}`,
        approvableType: "JobRequisition",
        note: a.note,
        sortOrder: a.sortOrder || 0,
        state: a.state,
        personId: `person_${a.person.id}`,
      })),
      jobRequisitionId: `job_requisition_${jobRequisitionId}`,
      notificationRecipients: updatedNotificationRecipients.map((p) => ({
        // NOTE: We will need to account for notified_at in the future
        personId: `person_${p.id}`,
        notifiableId: `job_requisition_${jobRequisitionId}`,
        notifiableType: "JobRequisition",
      })),
    }

    const result = await mutate({ input }).unwrap()

    if (
      !result.configureJobRequisitionApprovals ||
      result.configureJobRequisitionApprovals.errors.length > 0
    ) {
      // eslint-disable-next-line no-console
      console.error(result.configureJobRequisitionApprovals?.errors)
    }
    closeModal()
  }

  return (
    <>
      <Modal
        footer={
          <ModalFooter
            disabled={isSaving}
            onClose={cancelNotifyeesModal}
            onSave={handleSave}
            saveButtonText={
              isSaving ? "save_disabled".t("button_defaults") : "save_enabled".t("button_defaults")
            }
          />
        }
        isOpen={editNotifyeesModalOpen}
        onClose={cancelNotifyeesModal}
        title={"overview_notification_recipients_modal_title".t("job_requisition")}
      >
        <div className="pb-4">
          <PersonSelection
            description={"overview_notification_recipients_description".t("job_requisition")}
            onPeopleUpdated={onPeopleUpdated}
            people={selectedPeople || existingNotificationRecipients()}
            title={"overview_notification_recipients_title".t("job_requisition")}
          />
        </div>
      </Modal>
      <Modal
        footer={
          <ModalFooter
            disabled={isSaving}
            onClose={cancelApproversModal}
            onSave={handleSave}
            saveButtonText={
              isSaving ? "save_disabled".t("button_defaults") : "save_enabled".t("button_defaults")
            }
          />
        }
        isOpen={editApproversModalOpen}
        onClose={cancelApproversModal}
        title={"overview_approvers_modal_title".t("job_requisition")}
      >
        <div className="pb-4">
          <ApproverSelection
            description={"overview_approvers_description".t("job_requisition")}
            onApproversUpdated={onApproversUpdated}
            approvers={selectedApprovers || existingApprovers()}
            title={"overview_approvers_title".t("job_requisition")}
            translationDomain="job_requisition"
          />
        </div>
      </Modal>
    </>
  )
}

export { ApproverAndNotifyeeModals }
