import fp, { isEmpty } from "lodash/fp"
import React, { useMemo } from "react"

import { NodeInterface } from "types/graphql"
import { useCellCursorConnection } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/useCellCursorConnection"
import { Cell, CellProps } from "v2/react/shared/Datasheet/Cell"
import { RowType } from "v2/react/shared/Datasheet/types"
import { skeletonSelectors } from "v2/redux/slices/GridSlice"
import { getFieldType } from "v2/redux/slices/GridSlice/gridHelpers/getFieldType"
import { NormalizedRow, SkeletonRow } from "v2/redux/slices/GridSlice/types"
import { changesetSelectors } from "v2/redux/slices/NodeSlice"
import { nodeSelectors } from "v2/redux/slices/NodeSlice/NodeApi"
import { formattedNodeProp } from "v2/redux/slices/NodeSlice/nodeHelpers/nodeProps"
import {
  selectCellErrorMessage,
  selectFieldTransition,
} from "v2/redux/slices/NodeSlice/nodeSelectors"
import { selectNodeColor } from "v2/redux/slices/VisualizationSlice/visualizationSelectors/selectNodeColor"
import { useAppSelector } from "v2/redux/store"

type OrgChartDatasheetCellProps = CellProps<string, NodeInterface>

function OrgChartDatasheetCell({
  boundary,
  column,
  isFirst,
  isFirstRow,
  isLast,
  isLastRow,
  onExpandCollapseGrouping,
  row,
  style,
}: OrgChartDatasheetCellProps) {
  const { id } = row
  const { fieldKey } = column
  const cursorConnection = useCellCursorConnection(id, column)
  const fullRowData = useFullRow(id, fieldKey, isFirst)
  const fieldTransition = useAppSelector((state) => selectFieldTransition(state, id, fieldKey))
  const editMode = useAppSelector((state) => state.visualization.editMode)
  const isEditable = editMode && !!fullRowData.isFieldEditable

  const fieldType = useMemo(
    () =>
      getFieldType({
        fieldKey: column.fieldKey,
        hasCollection: !!column.collection,
        isEditable,
      }),
    [column, isEditable],
  )

  if (isEmpty(fullRowData)) return <span />

  const { changeInfo, errorMessage, fullRow, value } = fullRowData

  return (
    <Cell<NodeInterface>
      boundary={boundary}
      changeInfo={changeInfo}
      column={column}
      currentValue={value}
      errorMessage={errorMessage}
      fieldTransition={fieldTransition}
      fieldType={fieldType}
      isEditable={isEditable}
      isInEditMode={editMode}
      isFirst={isFirst}
      isFirstRow={isFirstRow}
      isLast={isLast}
      isLastRow={isLastRow}
      isSelected={cursorConnection.inCursor}
      onClick={cursorConnection.requestCursorTransition}
      onExpandCollapseGrouping={onExpandCollapseGrouping}
      cursorConnection={cursorConnection}
      row={fullRow}
      style={style}
      focusCell={cursorConnection.inWrite}
    />
  )
}

function useFullRow(id: string, fieldKey: keyof NodeInterface, isFirst: boolean) {
  const skeleton = useAppSelector((state) => skeletonSelectors.selectById(state, id)) as SkeletonRow
  const node = useAppSelector((state) => nodeSelectors.selectById(state, id))
  const color = useAppSelector((state) => selectNodeColor(state, "edge", id))

  const errorMessage = useAppSelector((state) =>
    selectCellErrorMessage(state, id, fieldKey, isFirst),
  )
  const changeInfo = useAppSelector(
    (state) => changesetSelectors.selectById(state, id)?.changes[fieldKey],
  )

  if (!node && skeleton.rowType !== RowType.Group) return {}

  const fullRow: NormalizedRow =
    skeleton.rowType === RowType.Group
      ? skeleton
      : { id: skeleton.id, data: node as NodeInterface, rowType: RowType.Node, color }

  const isFieldEditable =
    fullRow.rowType === RowType.Node && fp.includes(fieldKey, fullRow.data.editable_fields || [])

  const value =
    fullRow.rowType === RowType.Group ? fullRow.value : formattedNodeProp(fieldKey, fullRow.data)

  return {
    fullRow,
    errorMessage,
    changeInfo,
    isFieldEditable,
    value,
  }
}

export { OrgChartDatasheetCell, OrgChartDatasheetCellProps }
