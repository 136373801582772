import classNames from "classnames"
import fp from "lodash/fp"
import React from "react"
import { useDrag } from "react-dnd"

import { ColumnSort } from "v2/react/shared/Datasheet/ColumnSort"
import { Column } from "v2/react/shared/Datasheet/types"
import { Select } from "v2/react/shared/forms/Select"

interface ColumnHeadProps<TNode> {
  column: Column<TNode>
  onClearFilter: (fieldKey: keyof TNode) => void
  onSelectFilter: (fieldKey: keyof TNode, term: string) => void
  onSortColumn: (fieldKey: keyof TNode) => void
  sorting: { fieldKey: keyof TNode; direction: "asc" | "desc" }
  selectFilterValues: (
    field: Column<TNode>,
    isFocused?: boolean,
  ) => { value: string | number; label: string }[]
}

function ColumnHead<TNode>({
  column,
  onClearFilter,
  onSelectFilter,
  selectFilterValues,
  onSortColumn,
  sorting,
}: ColumnHeadProps<TNode>) {
  const cellStyle = fp.pick(["width"], column)

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "column",
      item: { fieldKey: column.fieldKey },
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }),
    [],
  )

  return (
    <div
      className={classNames(
        "h-20 cursor-pointer flex-col gap-2 border-l-neutral-8 px-4 py-2 flex first:border-l-0",
        {
          "dragging opacity-40": isDragging,
        },
      )}
      style={cellStyle}
      ref={dragRef}
    >
      {!column.hideLabel && (
        <ColumnSort
          canSort
          columnLabel={column.label}
          onSort={() => onSortColumn(column.fieldKey)}
          sorted={sorting.fieldKey === column.fieldKey ? sorting.direction : false}
        />
      )}
      {column.filterable && (
        <Select
          className="select--xs"
          defaultValue={`Filter by ${column.label}`}
          id={`filter-${column.fieldKey.toString()}`}
          name={`filter-${column.fieldKey.toString()}`}
          onSelect={(event) =>
            event.target.value
              ? onSelectFilter(column.fieldKey, event.target.value)
              : onClearFilter(column.fieldKey)
          }
          options={selectFilterValues(column, true).map((o) => ({
            id: o.label,
            label: o.value as string,
          }))}
        />
      )}
    </div>
  )
}

const typedMemo: <T>(c: T) => T = React.memo
const MemoedColumnHead = typedMemo(ColumnHead)

export { ColumnHeadProps, MemoedColumnHead }
