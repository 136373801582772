import React, { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Option, Tag } from "types/graphql"
import { AITagTextArea } from "v2/react/components/positionTypes/modals/AITagTextArea"
import { ConfirmationModal } from "v2/react/components/positionTypes/modals/ConfirmationModal"
import { ErrorBanner } from "v2/react/components/positionTypes/modals/ErrorBanner"
import { InfoBanner } from "v2/react/components/positionTypes/modals/InfoBanner"
import { ContentType } from "v2/react/components/positionTypes/modals/types"
import { useCollectionSearch } from "v2/react/hooks/useCollectionSearch"
import { useTagsWithFieldSuggestion } from "v2/react/hooks/useTypedFieldWithSuggestion"
import { normalizedDomainTagsProp } from "v2/react/hooks/useTypedFieldWithSuggestion/tagFieldHelpers"
import {
  FieldValueWithSuggestionHash,
  RecordWithSuggestions,
  TagValue,
} from "v2/react/hooks/useTypedFieldWithSuggestion/types"
import { Modal, ModalFooter } from "v2/react/shared/overlay/Modal"
import { FieldSuggestionState } from "v2/redux/slices/FieldSuggestionSlice"

export type RequirementsTags = { characteristics: Option[]; skills: Option[] }

type TagsWithSuggestionState = FieldValueWithSuggestionHash<TagValue[]>

type RecordWithTags = {
  tags?: Tag[] | null
} & RecordWithSuggestions

interface Props<Record extends RecordWithTags> {
  canEditCharacteristics?: boolean
  canEditSkills?: boolean
  contentType: ContentType
  errors: string[]
  isAiAvailable?: boolean
  isOpen: boolean
  isSaving: boolean
  modalTitle: string
  onClose: () => void
  onRegenerateFieldSuggestion: (field: string) => Promise<boolean>
  onSave: (data: RequirementsTags, removeLinkedRequirements: boolean) => Promise<boolean>
  characteristicTags: Option[]
  record: Record
  skillTags: Option[]
  excludeRecord?: string
  uniqueKey: string
}

function RequirementsModal<Record extends RecordWithTags>({
  canEditCharacteristics,
  canEditSkills,
  characteristicTags,
  contentType,
  errors,
  excludeRecord,
  isAiAvailable = true,
  isOpen,
  isSaving,
  modalTitle,
  onClose,
  onRegenerateFieldSuggestion,
  onSave,
  record,
  skillTags,
  uniqueKey,
}: Props<Record>) {
  const [skillsSearchTerm, setSkillsSearchTerm] = React.useState<string>("")
  const [workerCharacteristicsSearchTerm, setWorkerCharacteristicsSearchTerm] =
    React.useState<string>("")
  const { collectionResult: skillOptions } = useCollectionSearch({
    fieldKey: "tags",
    filter: skillsSearchTerm,
    extraArgs: {
      tagDomain: "skills",
      taggableEntity: "position_types",
      excludeRecord,
    },
    skipQuery: false,
  })
  const { collectionResult: workerCharacteristicOptions } = useCollectionSearch({
    fieldKey: "tags",
    filter: workerCharacteristicsSearchTerm,
    extraArgs: {
      tagDomain: "worker_characteristics",
      taggableEntity: "position_types",
      excludeRecord,
    },
    skipQuery: false,
  })

  const { t } = useTranslation()

  const combinedTagState = useCombinedTagsFields({ record, uniqueKey })
  const { isAwaitingAction } = combinedTagState
  const { setSkills, setLoadingSkills, skills, skillsIsBusy } = combinedTagState
  const { characteristics, characteristicsIsBusy, setCharacteristics, setLoadingCharacteristics } =
    combinedTagState

  // Both `characteristicTags` and `skillTag` are controlled by the user when
  // the modal is open, so we refrain from updating them except when an action
  // triggered by the user finishes. We do want to ensure these values
  // synchronize with the latest state when the modal is closed.
  useEffect(() => {
    if (!isOpen) setCharacteristics(characteristicTags)
  }, [characteristicTags, isOpen, setCharacteristics])
  useEffect(() => {
    if (!isOpen) setSkills(skillTags)
  }, [isOpen, setSkills, skillTags])

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [removeLinkedRequirements, setRemoveLinkedRequirements] = useState(false)

  const confirmationNeeded = () =>
    contentType === "definition" &&
    ((skillTags.length && !skills.length) ||
      (characteristicTags.length && !characteristics.length)) &&
    !isAwaitingAction &&
    !confirmationModalOpen

  const handleGenerateRequirements = async (type: "skills" | "characteristics") => {
    if (type === "skills") {
      setLoadingSkills(true)
      await onRegenerateFieldSuggestion("skills")
      setLoadingSkills(false)
    } else {
      setLoadingCharacteristics(true)
      await onRegenerateFieldSuggestion("worker_characteristics")
      setLoadingCharacteristics(false)
    }
  }

  const handleSave = () => {
    const saveRequirements = async () => {
      if (await onSave({ characteristics, skills }, removeLinkedRequirements)) handleClose()
      else setConfirmationModalOpen(false)
    }

    if (confirmationNeeded()) {
      setConfirmationModalOpen(true)
    } else {
      saveRequirements()
    }
  }

  const handleClose = () => {
    setSkillsSearchTerm("")
    setWorkerCharacteristicsSearchTerm("")
    setConfirmationModalOpen(false)
    onClose()
  }

  const handleCloseConfirmation = () => {
    setConfirmationModalOpen(false)
    handleClose()
  }

  const handleConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
    setRemoveLinkedRequirements(e.target.value === "true")
  }

  const clearedRequirements = () => {
    if (skillTags.length && !skills.length && characteristicTags.length && !characteristics.length)
      return ["skills", "characteristics"]
    if (skillTags.length && !skills.length) return ["skills"]
    return ["characteristics"]
  }

  if (confirmationModalOpen) {
    return (
      <ConfirmationModal
        handleRemoveLinkedData={handleConfirmation}
        modalTitle={modalTitle}
        onClose={handleCloseConfirmation}
        onSave={handleSave}
        properties={clearedRequirements()}
        removeLinkedData={removeLinkedRequirements}
      />
    )
  }

  return (
    <Modal
      footer={
        <ModalFooter
          disabled={isSaving || combinedTagState.websocketIsBusy}
          onClose={handleClose}
          onSave={handleSave}
          saveButtonText={t("v2.defaults.save")}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      testId="requirements-modal"
      title={modalTitle}
    >
      <div className="flex-col gap-y-6 p-6 flex">
        {isAiAvailable && <InfoBanner infoBannerType={contentType} />}
        <ErrorBanner errors={errors} />

        <AITagTextArea
          content={skills}
          disabled={!canEditSkills}
          handleGenerate={() => handleGenerateRequirements("skills")}
          isAiAvailable={isAiAvailable}
          labelText={t("v2.position_types.show.skills")}
          loadingContent={skillsIsBusy}
          options={skillOptions}
          setData={setSkills}
          searchTerm={skillsSearchTerm}
          setSearchTerm={setSkillsSearchTerm}
        />

        <AITagTextArea
          content={characteristics}
          disabled={!canEditCharacteristics}
          handleGenerate={() => handleGenerateRequirements("characteristics")}
          isAiAvailable={isAiAvailable}
          labelText={t("v2.position_types.show.characteristics")}
          loadingContent={characteristicsIsBusy}
          options={workerCharacteristicOptions}
          setData={setCharacteristics}
          searchTerm={workerCharacteristicsSearchTerm}
          setSearchTerm={setWorkerCharacteristicsSearchTerm}
        />
      </div>
    </Modal>
  )
}

export { RequirementsModal }

function useCombinedTagsFields({
  record,
  uniqueKey,
}: {
  record: RecordWithTags
  uniqueKey: string
}) {
  const [loadingSkills, setLoadingSkills] = useState(false)
  const [loadingCharacteristics, setLoadingCharacteristics] = useState(false)

  const skillState: TagsWithSuggestionState = useTagsWithFieldSuggestion({
    record,
    field: "skills",
    getActual: normalizedDomainTagsProp("skills", "tags"),
    getUniqueKey: () => uniqueKey,
    onStateChange: {
      handler: ({ fallback, suggested }) => setSkills(suggested ?? fallback),
      whenChangesTo: [FieldSuggestionState.Initialized, FieldSuggestionState.Generated],
    },
    withEphemeralEventsApplied: true,
  })

  const characteristicsState: TagsWithSuggestionState = useTagsWithFieldSuggestion({
    record,
    field: "worker_characteristics",
    getActual: normalizedDomainTagsProp("worker_characteristics", "tags"),
    getUniqueKey: () => uniqueKey,
    onStateChange: {
      handler: ({ fallback, suggested }) => setCharacteristics(suggested ?? fallback),
      whenChangesTo: [FieldSuggestionState.Initialized, FieldSuggestionState.Generated],
    },
    withEphemeralEventsApplied: true,
  })

  const [skills, setSkills] = useState<Option[]>(skillState.value)
  const [characteristics, setCharacteristics] = useState<Option[]>(characteristicsState.value)

  return {
    actualCharacteristics: characteristicsState.value,
    actualSkills: skillState.value,
    characteristics,
    characteristicsIsBusy: loadingCharacteristics || characteristicsState.isBusy,
    isAwaitingAction: skillState.isAwaitingAction || characteristicsState.isAwaitingAction,
    setCharacteristics,
    setSkills,
    skills,
    skillsIsBusy: loadingSkills || skillState.isBusy,
    suggestedCharacteristics: characteristicsState.suggested,
    suggestedSkills: skillState.suggested,
    websocketIsBusy: skillState.isBusy || characteristicsState.isBusy,
    setLoadingSkills,
    setLoadingCharacteristics,
  }
}
