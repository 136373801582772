import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import dayjs from "dayjs"
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useState } from "react"

import { IntegrationFlags, Position } from "types/graphql"
import { OpenLinkInNewTab } from "v2/react/components/orgChart/ProfilePanel/Icons/OpenLinkInNewTab"
import { PanelToggle } from "v2/react/components/orgChart/ProfilePanel/PanelToggle"
import { PersonHeader } from "v2/react/components/orgChart/ProfilePanel/PersonHeader"
import { PersonModules } from "v2/react/components/orgChart/ProfilePanel/PersonModules/PersonModules"
import { PositionHeader } from "v2/react/components/orgChart/ProfilePanel/PositionHeader"
import { PositionModules } from "v2/react/components/orgChart/ProfilePanel/PositionModules/PositionModules"
import RootProvider from "v2/react/components/RootProvider"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { opaqueDelay } from "v2/react/utils/framerAnimationVariants"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import {
  useGetIntegrationFlagsQuery,
  useGetPersonQuery,
  useGetPositionQuery,
} from "v2/redux/GraphqlApi"
import { setMode, setProfilePerson, setProfilePosition } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

export interface ProfilePanelAbilities {
  canCreateCompensation: boolean
  canEditPerson: boolean
  canEditPosition: boolean
  canEditSuccessionPlan: boolean
  canManageOwnLogin: boolean
  canManagePerson: boolean
  canManageSubordinateLogin: boolean
  canReadBudgeting: boolean
  canReadCompensation: boolean
  canReadSensitivePersonData: boolean
  canReadSensitivePositionData: boolean
  canReadSuccessionPlan: boolean
  canReadSuccessionStrategy: boolean
  canShowPositionPage: boolean
}

interface ProfilePanelProps {
  fetchAbilities: (position: number | null, person: number | null) => Promise<ProfilePanelAbilities>
  isOfficialChart: boolean
}

function ProfilePanelInner({ fetchAbilities, isOfficialChart }: ProfilePanelProps) {
  const [abilities, setAbilities] = useState<ProfilePanelAbilities>()
  const dispatch = useAppDispatch()
  const person = useAppSelector((state) => state.profilePanel.person)
  const personId = useAppSelector((state) => state.profilePanel.personId)
  const position = useAppSelector((state) => state.profilePanel.position)
  const positionId = useAppSelector((state) => state.profilePanel.positionId)
  const panelMode = useAppSelector((state) => state.profilePanel.mode)

  const { data } = useGetPositionQuery(positionId ? { positionId } : skipToken, {
    refetchOnMountOrArgChange: true,
  })
  const { data: personData } = useGetPersonQuery(personId ? { personId } : skipToken, {
    refetchOnMountOrArgChange: true,
  })

  const { data: integrationFlagsData } = useGetIntegrationFlagsQuery()

  useEffect(() => {
    if (!personId) dispatch(setProfilePerson(null))
    if (!personId && !position?.people?.length) {
      dispatch(setMode("position"))
      window.App.Preferences.update({ org_chart: { profile_panel_mode: "position" } })
    }
  }, [personId, position, dispatch])

  useEffect(() => {
    if (!positionId) dispatch(setProfilePosition(null))
    if (!positionId && !person?.positions?.length) {
      dispatch(setMode("person"))
      window.App.Preferences.update({ org_chart: { profile_panel_mode: "person" } })
    }
  }, [positionId, person, dispatch])

  useEffect(() => {
    if (personData?.person) dispatch(setProfilePerson(personData.person))
  }, [personData, dispatch])

  useEffect(() => {
    if (data?.position) dispatch(setProfilePosition(data.position as Position))
  }, [data, dispatch])

  useEffect(() => {
    if (!positionId && !personId) return

    async function getAbilities() {
      const personIdInt = personId ? parseInt(personId, 10) : null
      const positionIdInt = positionId ? parseInt(positionId, 10) : null

      try {
        const abilityResponse = await fetchAbilities(positionIdInt, personIdInt)
        if (abilityResponse) setAbilities(abilityResponse)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
      return null
    }
    getAbilities()
  }, [personId, positionId, fetchAbilities])

  const integrationFlags: IntegrationFlags | undefined =
    integrationFlagsData?.currentCompany?.integrationFlags

  const conditionsForLoading =
    !abilities || (personId && !person) || (positionId && !position) || !integrationFlags

  const closePanel = () => {
    dispatch(setProfilePerson(null))
    dispatch(setProfilePosition(null))
  }

  const loadingState = (
    <div>
      <Spinner />
    </div>
  )

  const loadedState = (
    <AnimatePresence>
      {panelMode === "person" && abilities && integrationFlags && (
        <motion.div
          key="person"
          initial="hidden"
          animate="shown"
          exit="hidden"
          variants={opaqueDelay}
        >
          <PersonHeader abilities={abilities} />
          {abilities.canManagePerson &&
            person &&
            person.invitationSentAt &&
            !person.lastSignInAt && (
              <div className="alert alert-info mx-6">
                {"invitation_not_accepted_react".t("profile", null, null, null, [
                  dayjs(person.invitationSentAt).format("MMM D, YYYY"),
                  person.id,
                ])}
                <a
                  href={`/people/${person.id}/messages/new?invite=true`}
                  data-feature="multiple_users"
                  data-action="send-invitation"
                  onClick={(event) => {
                    event.preventDefault()
                    pjaxModalFor(`/people/${person.id}/messages/new?invite=true`)
                  }}
                >
                  {"invitation_not_accepted_click_react".t("profile")}
                </a>
              </div>
            )}
          <PersonModules abilities={abilities} />
        </motion.div>
      )}
      {panelMode === "position" && abilities && integrationFlags && (
        <motion.div
          key="position"
          initial="hidden"
          animate="shown"
          exit="hidden"
          variants={opaqueDelay}
        >
          <PositionHeader abilities={abilities} />
          <PositionModules abilities={abilities} integrationFlags={integrationFlags} />
        </motion.div>
      )}
    </AnimatePresence>
  )

  return (
    <div className="react-profile-panel">
      <div className="react-profile-panel__actions">
        <div />
        <PanelToggle />
        <div className="react-profile-panel__actions-icons">
          <motion.div layout>
            <div className="tooltip tooltip-right">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={["far", "cog"]}
                style={{ width: "20px", height: "20px" }}
                data-action="open-settings"
              />
              <span className="tooltip-content tooltip-content--sm">
                {"Configure Panel".t("org_chart")}
              </span>
            </div>
          </motion.div>
          <AnimatePresence>
            <motion.div layout>
              {panelMode === "person" && (
                <div className="tooltip tooltip-right">
                  <OpenLinkInNewTab url={UrlHelper.personProfilePath(person?.id || "")} />
                  <span className="tooltip-content tooltip-content--sm">
                    {"View Profile".t("org_chart")}
                  </span>
                </div>
              )}
              {panelMode === "position" && abilities?.canShowPositionPage && isOfficialChart && (
                <div className="tooltip tooltip-right">
                  <OpenLinkInNewTab url={UrlHelper.positionShowPath(position?.id || "")} />
                  <span className="tooltip-content tooltip-content--sm">
                    {"View Position".t("org_chart")}
                  </span>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
          <motion.div layout>
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={["far", "times"]}
              size="2x"
              data-action="hide-panel"
              onClick={closePanel}
            />
          </motion.div>
        </div>
      </div>
      {conditionsForLoading ? loadingState : loadedState}
    </div>
  )
}

function ProfilePanel({ fetchAbilities, isOfficialChart }: ProfilePanelProps) {
  return (
    <RootProvider>
      <ProfilePanelInner fetchAbilities={fetchAbilities} isOfficialChart={isOfficialChart} />
    </RootProvider>
  )
}

export { ProfilePanel }
