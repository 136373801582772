import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import invariant from "tiny-invariant"
import { z } from "zod"

import { Dropdown } from "v2/react/shared/collection/menus/Dropdown/Dropdown"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { entityFromUniqueKey, idFromUniqueKey } from "v2/react/utils/uniqueKey"
import { UrlHelper } from "v2/react/utils/urls"

interface PositionActionsProps {
  positionId: string
  systemIdentifier?: string | null
  containerKey?: string | null
  canEdit?: boolean
  canDelete?: boolean
  canViewHistory?: boolean
  canViewSystemIdentifier?: boolean
}

const ContainerTypeSchema = z.enum(["chart", "list"])

const actions = {
  editPosition: (positionId: string) => () => pjaxModalFor(UrlHelper.editPositionPath(positionId)),
  deletePosition: (positionId: string) => () => window?.App?.OrgChart?.deleteById(positionId),
  viewHistory:
    ({ containerKey, positionId }: Pick<PositionActionsProps, "containerKey" | "positionId">) =>
    () => {
      invariant(containerKey, "containerKey is required")
      const containerType = ContainerTypeSchema.parse(entityFromUniqueKey(containerKey))
      const containerId = idFromUniqueKey(containerKey)

      return pjaxModalFor(
        UrlHelper.positionHistoryLogPath({ containerType, containerId, positionId }),
      )
    },
}

const PositionActions = ({
  positionId,
  systemIdentifier,
  containerKey,
  canEdit,
  canDelete,
  canViewHistory,
  canViewSystemIdentifier,
}: PositionActionsProps) =>
  canEdit || canDelete || canViewHistory || canViewSystemIdentifier ? (
    <Dropdown align="right">
      <Dropdown.Trigger triggerClassName="btn btn--ghost position-actions">
        <FontAwesomeIcon icon={["far", "ellipsis-h"]} />
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {canEdit && (
          <Dropdown.Item
            onClick={actions.editPosition(positionId)}
            withIcon={<FontAwesomeIcon icon={["far", "pen"]} />}
          >
            {"Edit Position".t("org_chart")}
          </Dropdown.Item>
        )}
        {canViewSystemIdentifier && systemIdentifier && (
          <Dropdown.Item.CopyInline
            label={"Copy Position ID".t("org_chart")}
            valueToCopy={systemIdentifier}
          />
        )}
        {canViewHistory && (
          <Dropdown.Item
            onClick={actions.viewHistory({ containerKey, positionId })}
            withIcon={<FontAwesomeIcon icon={["far", "history"]} />}
          >
            {"View History".t("org_chart")}
          </Dropdown.Item>
        )}
        {canDelete && (
          <>
            <hr className="my-2" />
            <Dropdown.Item
              onClick={actions.deletePosition(positionId)}
              withIcon={
                <FontAwesomeIcon icon={["far", "trash-alt"]} className="!text-status-critical" />
              }
            >
              <span className="text-status-critical">{"Remove Position".t("org_chart")}</span>
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : null

export { PositionActions, actions }
