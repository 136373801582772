/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames"
import React from "react"

import { CompareValues } from "./CompareValues"
import { DropdownMenu } from "./DropdownMenu"
import { useCellHandlers } from "./hooks/useCellHandlers"
import { useCellState } from "./hooks/useCellState"
import { useCursorSync } from "./hooks/useCursorSync"
import { useSelectList } from "./hooks/useSelectList"
import { StyleLayers } from "./StyleLayers"
import { FieldType, SaveFn } from "./types"

const blankPlaceholder = "__blank__"

type Option = { id: string; label: string }

type Props = {
  options: Option[]
  currentValue: string
  compareValue?: string | null
  saveFn: SaveFn
  includeBlankOption?: boolean
  rowId: string
  columnId: string
}
export function DropdownCell({
  options: nonBlankOptions,
  saveFn,
  currentValue,
  compareValue,
  includeBlankOption,
  rowId,
  columnId,
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const cell = useCellState({
    currentValue,
    fieldType: FieldType.SelectDropdown,
    rowId,
    columnId,
    onEditing: () => {
      inputRef.current?.focus()
    },
    saveFn: (state) => saveFn(state.value),
  })

  const isInEditMode = cell.isEditing || cell.isSaving || cell.isSaved || cell.isErroredEditing

  const {
    activeIndex,
    setActiveIndex,
    listRef,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  } = useSelectList({ showList: cell.isEditing || cell.isErroredEditing })

  const cellRef = refs.reference as React.MutableRefObject<HTMLDivElement>

  useCursorSync({ cell, cellRef, value: currentValue })

  const options = includeBlankOption
    ? [{ id: "", label: blankPlaceholder }, ...nonBlankOptions]
    : nonBlankOptions

  const { handleCellClick, handleCellKeyUp } = useCellHandlers(cell, cellRef, {
    getSaveValue: () => (activeIndex !== null ? options[activeIndex].id : currentValue),
    moveDown: () =>
      setActiveIndex((prev) => (prev === null ? 0 : Math.min(options.length - 1, prev + 1))),
    moveUp: () => setActiveIndex((prev) => (prev === null ? 0 : Math.max(0, prev - 1))),
  })

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={refs.setReference}
      id={`${columnId}-${rowId}`}
      className="editable-cell"
      {...getReferenceProps()}
      onClick={handleCellClick}
      onKeyUp={handleCellKeyUp}
    >
      <CompareValues
        compareValue={isInEditMode ? null : getCellDisplayText(options, compareValue ?? "")}
      >
        <input
          className="bg-white"
          value={getCellDisplayText(options, currentValue)}
          disabled={!cell.isEditing}
          ref={inputRef}
        />
      </CompareValues>
      <DropdownMenu
        showList={(cell.isEditing || cell.isErroredEditing) && options.length > 0}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, zIndex: 6 }}
        floatingProps={getFloatingProps}
        wrapperClasses="SelectField"
        context={context}
      >
        {options.map((item, index) => (
          <li
            role="option"
            className={textClassName(activeIndex, index)}
            aria-selected={activeIndex === index}
            key={item.id}
            ref={(node) => {
              listRef.current[index] = node
            }}
            {...getItemProps({
              onClick: () => {
                cell.dispatch({ type: "save", value: item.id })
              },
            })}
          >
            <div className={optionClassName(item.id)}>{item.label}</div>
          </li>
        ))}
      </DropdownMenu>
      <StyleLayers cell={cell} fieldType={FieldType.SelectDropdown} />
    </div>
  )
}

const textClassName = (activeIndex: number | null, index: number) =>
  classNames("SelectField__result", { highlight: activeIndex === index })

const optionClassName = (itemId: string) =>
  classNames("SelectField__result-text", { blank: itemId === "" })

const getCellDisplayText = (options: Option[], value?: string) => {
  const label = options.find((option) => option.id === value)?.label
  if (label === blankPlaceholder) return ""
  return label
}
