import cn from "classnames"
import { TFunction } from "i18next"
import React from "react"

import { CustomFieldValue, IntegrationFlags, PositionType } from "types/graphql"

import { customFieldTitleLabel, customFieldValueLabel } from "./utils"

type CellData = {
  content: string | React.ReactNode
  title: string | React.ReactNode
}

type ColumnCellProps = {
  content: string | React.ReactNode
  cellCount: number
  index: number
  isEmpty?: boolean
  title: string | React.ReactNode
}

const ColumnCell = ({ content, cellCount, index, title, isEmpty }: ColumnCellProps) => {
  const hasDanglingCell = index === cellCount - 1 && index % 2 === 0

  const borderClasses = index % 2 ? cellLeftBorderClasses() : ""
  return (
    <>
      <div
        className={cn(
          "w-[100%] grow border-0 border-t border-solid border-neutral-8 lg:w-[50%]",
          borderClasses,
          { "hidden lg:block": isEmpty },
        )}
      >
        <div className="p-4 flex">
          <div className="basis-1/3 text-neutral-64">{title}</div>
          <div className="basis-2/3 pl-4 text-neutral-100">{content}</div>
        </div>
      </div>
      {hasDanglingCell && (
        <ColumnCell cellCount={cellCount} content="" index={index + 1} title="" isEmpty />
      )}
    </>
  )
}

type CustomFieldCellProps = {
  customFieldValue: CustomFieldValue
  index: number
}
const CustomFieldCell = ({ customFieldValue, index }: CustomFieldCellProps) => (
  <ColumnCell
    content={customFieldValueLabel(customFieldValue)}
    title={customFieldTitleLabel(customFieldValue)}
    cellCount={2}
    index={index}
    // eslint-disable-next-line react/no-array-index-key
    key={`custom-field-${index}`}
  />
)

const cellLeftBorderClasses = (): string => "border-0 lg:border-l border-solid border-neutral-8"

const jobLevelCell = (
  t: TFunction,
  integrationFlags: IntegrationFlags,
  positionType?: PositionType | null,
): CellData[] => {
  if (!positionType) return []
  if (!integrationFlags.supportsJobLevels) return []
  return [
    {
      content: positionType.jobLevel?.codeNameLabel || "-",
      title: t("v2.positions.show.job_level"),
    },
  ]
}

const payGradeCell = (
  t: TFunction,
  integrationFlags: IntegrationFlags,
  positionType?: PositionType | null,
): CellData[] => {
  if (!positionType) return []
  if (!integrationFlags.supportsPayGrades) return []
  return [
    {
      content: (
        <div className="gap-0.5 grid">
          <p>{positionType.payGrade?.codeNameLabel || "-"}</p>
        </div>
      ),
      title: t("v2.positions.show.pay_grade"),
    },
  ]
}

export { CellData, ColumnCell, CustomFieldCell, jobLevelCell, payGradeCell }
