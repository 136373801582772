import { Boundary } from "@floating-ui/react"
import classNames from "classnames"
import React, { useRef } from "react"

import { CollectionAutocomplete } from "v2/react/shared/Datasheet/Cell/Autocomplete/CollectionAutocomplete"
import { OrgUnitAutocomplete } from "v2/react/shared/Datasheet/Cell/Autocomplete/OrgUnitAutocomplete"
import { PersonComplete } from "v2/react/shared/Datasheet/Cell/Autocomplete/PersonComplete"
import { PositionAutocomplete } from "v2/react/shared/Datasheet/Cell/Autocomplete/PositionAutocomplete"
import { AvatarCell } from "v2/react/shared/Datasheet/Cell/AvatarCell"
import { DisplayValueCell } from "v2/react/shared/Datasheet/Cell/DisplayValueCell"
import { EditableTextCell } from "v2/react/shared/Datasheet/Cell/EditableTextCell"
import { GroupCell } from "v2/react/shared/Datasheet/Cell/GroupCell"
import { SelectDropdown } from "v2/react/shared/Datasheet/Cell/SelectDropdown"
import { CellStyleType, StyleLayers } from "v2/react/shared/Datasheet/Cell/StyleLayers/StyleLayers"
import {
  ChangeMeta,
  Column,
  CursorConnection,
  FieldType,
  GroupRow,
  NodeRow,
  RowType,
} from "v2/react/shared/Datasheet/types"
import { useAppSelector } from "v2/redux/store"

import { fallbackCursorConnection } from "./utils/constants"

interface CellProps<TNode, CType = TNode> {
  column: Column<CType>
  changeInfo?: ChangeMeta
  currentValue?: string
  cursorConnection?: CursorConnection
  errorMessage?: string
  fieldTransition?: { type: "saved"; duration: number }
  isEditable?: boolean
  isFirst: boolean
  isFirstRow: boolean
  isInEditMode?: boolean
  isLast: boolean
  isLastRow: boolean
  isSelected?: boolean
  onClick: (row: NodeRow<TNode>, column: Column<CType>) => void
  onExpandCollapseGrouping: (row: GroupRow) => void
  row: GroupRow | NodeRow<TNode>
  boundary?: Boundary
  style: React.CSSProperties
  fieldType?: FieldType
  focusCell?: boolean
}

function Cell<TNode, CType = TNode>({
  boundary,
  column,
  changeInfo,
  currentValue,
  cursorConnection = fallbackCursorConnection,
  row,
  onClick,
  errorMessage,
  fieldTransition,
  focusCell = false,
  isEditable = false,
  isInEditMode = false,
  isSelected = false,
  fieldType = FieldType.NonEditable,
  ...props
}: CellProps<TNode, CType>) {
  const cellRef = useRef<HTMLDivElement>(null)
  let cellStyleLayer: CellStyleType = focusCell ? "enter" : "default"
  if (fieldTransition?.type === "saved") cellStyleLayer = "save"
  const cellStyle = {
    ...props.style,
    borderTop:
      Number(props.style.top) === 0 && !props.isFirstRow
        ? "1px solid rgba(012, 020, 075, 0.08)"
        : "none",
  }
  const mergeAvatarAndName = useAppSelector((state) => state.grid.mergeAvatarAndName)
  const collection = column.collection
  const selected = row.rowType === RowType.Node ? isSelected : false
  const selectedNone = !selected
  const selectedEditable = selected && isEditable
  const selectedStatic = selected && !isEditable

  const handleClick = () => onClick(row as NodeRow<TNode>, column)

  if (row.rowType === RowType.Group) {
    return (
      <GroupCell
        row={row}
        isFirst={props.isFirst}
        onExpandCollapseGrouping={props.onExpandCollapseGrouping}
        style={cellStyle}
      />
    )
  }

  if (row.rowType === RowType.Node && column.fieldKey === "avatar" && !mergeAvatarAndName) {
    return (
      <AvatarCell
        row={row}
        style={cellStyle}
        isFirst={props.isFirst}
        isLast={props.isLast}
        column={column}
      />
    )
  }

  const preventMouseDownDefault = (event: React.MouseEvent<HTMLInputElement>) => {
    if (isInEditMode) event.preventDefault()
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNames({ "last-row": props.isLastRow, "first-row": props.isFirstRow })}
      id={`cell-${row.id}-${String(column.fieldKey)}`}
      role="button"
      tabIndex={-1}
      ref={cellRef}
      onClick={handleClick}
      onMouseDown={preventMouseDownDefault}
    >
      {selectedNone && (
        <DisplayValueCell
          column={column}
          row={row}
          className={changeInfo ? "Cell__content Cell__content--changed" : undefined}
          isFirst={props.isFirst}
          isLast={props.isLast}
          mergeAvatarAndName={mergeAvatarAndName}
          style={cellStyle}
          value={currentValue || ""}
        />
      )}
      {selectedEditable && fieldType === FieldType.Standard && (
        <EditableTextCell
          row={row}
          className={changeInfo ? "Cell__editable--changed" : undefined}
          currentValue={currentValue}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          cursorConnection={cursorConnection}
        />
      )}
      {selectedEditable && fieldType === FieldType.PersonAutocomplete && (
        <PersonComplete
          cursorConnection={cursorConnection}
          row={row}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          focusCell={focusCell}
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.OrgUnitAutocomplete && (
        <OrgUnitAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          currentValue={currentValue}
        />
      )}
      {selectedEditable && fieldType === FieldType.PositionAutocomplete && (
        <PositionAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.SuggestedAutocomplete && (
        <CollectionAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          focusCell={focusCell}
          allowCustomInput
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.ForcedAutocomplete && (
        <CollectionAutocomplete<TNode, CType>
          cursorConnection={cursorConnection}
          row={row}
          column={column}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          focusCell={focusCell}
          allowCustomInput={false}
          noBorder
        />
      )}
      {selectedEditable && fieldType === FieldType.SelectDropdown && collection && (
        <SelectDropdown<TNode>
          cursorConnection={cursorConnection}
          row={row}
          isFirst={props.isFirst}
          isLast={props.isLast}
          style={cellStyle}
          html={currentValue || null}
          collection={collection}
          noBorder
        />
      )}
      {selectedStatic && fieldType === FieldType.NonEditable && (
        <DisplayValueCell
          column={column}
          row={row}
          isFirst={props.isFirst}
          isLast={props.isLast}
          mergeAvatarAndName={mergeAvatarAndName}
          style={cellStyle}
          value={currentValue || ""}
        />
      )}
      <StyleLayers
        boundary={boundary}
        cellStyleLayer={cellStyleLayer}
        changeInfo={changeInfo}
        currentValue={currentValue}
        errorMessage={errorMessage}
        isLast={props.isLast}
        styles={props.style}
      />
    </div>
  )
}

const MemoCell = React.memo(Cell)

export { Cell, CellProps, MemoCell }
