import React, { useState } from "react"

import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"

import ChooseColumnsListForm from "./ChooseColumnsListForm"

interface Props {
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  onSelectedColumnsChange: (selectedColumns: string[]) => void
}

export default function GridExportModal({
  isOpen,
  onClose,
  onSave,
  onSelectedColumnsChange,
}: Props) {
  const [isSaving, setIsSaving] = useState(false)
  const handleSave = async () => {
    setIsSaving(true)
    onSave()
    setIsSaving(false)
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={"Export".t("org_chart")}>
      <div className="p-6">
        <ChooseColumnsListForm onSelectedColumnsChange={onSelectedColumnsChange} />
      </div>
      <SaveFooter
        isSaving={isSaving}
        onSave={handleSave}
        enabledText={"Export".t("org_chart")}
        disabledText={"export_disabled".t("button_defaults")}
      />
    </Modal>
  )
}
