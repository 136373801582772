import React from "react"
import { createRoot } from "react-dom/client"

import { AddNewPositionButton } from "v2/react/components/headcountPlanning/AddNewPositionButton"
import { ShowParticipant } from "v2/react/components/headcountPlanning/ShowParticipant"
import { mountDatasheetListeners } from "v2/redux/listeners/tableDatasheetListeners"
import { StoreProvider } from "v2/redux/StoreProvider"

const containerId = "react-headcount-plans-show-participant-container"
const buttonId = "react-headcount-plans-add-new-position-button"
const propsId = "react-headcount-plans-props"

mountDatasheetListeners()

function getHeadcountPlanId() {
  const el = document.getElementById(propsId)
  return el?.dataset.headcountPlanId ?? ""
}

function getHeadcountPlanStartDate() {
  const el = document.getElementById(propsId)
  return el?.dataset.headcountPlanStartDate ?? ""
}

function getParticipantId() {
  const el = document.getElementById(propsId)
  return el?.dataset.participantId ?? ""
}

function getPositionId() {
  const el = document.getElementById(propsId)
  return el?.dataset.positionId ?? ""
}

function getReportsTo() {
  const el = document.getElementById(propsId)
  return JSON.parse(el?.dataset.reportsTo ?? "{}")
}

$.onmount(`#${buttonId}`, () => {
  const button = document.getElementById(buttonId)
  const root = createRoot(button)
  root.render(
    <StoreProvider>
      <AddNewPositionButton
        headcountPlanId={getHeadcountPlanId()}
        participantId={getParticipantId()}
        positionId={getPositionId()}
        reportsTo={getReportsTo()}
        startDate={getHeadcountPlanStartDate()}
        refresh
        primary
      />
    </StoreProvider>,
  )
})

$.onmount(`#${containerId}`, () => {
  const container = document.getElementById(containerId)
  const root = createRoot(container)
  root.render(
    <StoreProvider>
      <ShowParticipant headcountPlanId={getHeadcountPlanId()} participantId={getParticipantId()} />
    </StoreProvider>,
  )
})
