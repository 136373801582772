import { isEmpty } from "lodash/fp"
import React, { useEffect, useState } from "react"

import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import { usePreparedUpdatePersonPositionNodeMutation } from "v2/react/components/orgChart/OrgChartDatasheet/hooks/useUpdatePersonPositionNodeFunc"
import { TitleChangeFollowUpModal } from "v2/react/components/orgChart/OrgChartDatasheet/Modals/TitleChangeFollowUp/TitleChangeFollowUpModal"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import { preparePersonPositionNodeAttributes } from "v2/redux/slices/NodeSlice/mutations/preparePersonPositionNodeAttributes"
import { useFetchPositionTypeControlledAttributesWithValuesQuery } from "v2/redux/slices/NodeSlice/NodeApi"

/**
 * This component is responsible for handling the follow-up modal for title
 * changes. It fetches the controlled attributes for the new position type
 * and if there are any attributes set on that position type, it confirms with
 * the user that they still want to make the change. This is done b/c the position
 * will take on any set attributes from the new position type.
 *
 * If there are no controlled attributes set on the position type, then the
 * modal is skipped and the title change is saved immediately.
 */
const TitleChangeFollowUp = ({ isOpen, field, row }: FollowUpModalProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const { cancelFollowUp, saveModalSuccess } = useDatasheetListenerActions()
  const { execute } = usePreparedUpdatePersonPositionNodeMutation()

  const { data, isError, isLoading, isSuccess } =
    useFetchPositionTypeControlledAttributesWithValuesQuery(
      {
        title: field.value,
      },
      {
        skip: !isOpen,
      },
    )

  const controlledAttributeNamesWithSetValuesHash =
    data?.positionType?.controlledAttributeNamesWithSetValuesHash
  const noControlledAttributesSetOnPositionType =
    isSuccess && isEmpty(controlledAttributeNamesWithSetValuesHash)

  const handleSave = async () => {
    const attributes = preparePersonPositionNodeAttributes({
      fieldKey: field.fieldKey,
      node: row.data,
      value: field.value,
    })

    // It is expected that the NodeApi module will dispatch error information
    // into Redux, so there's no special handling here.
    const result = await execute({ id: row.id, attributes, primaryTrigger: field.fieldKey })
    if (result.ok) saveModalSuccess()
  }

  useEffect(() => {
    // If there are no controlled attributes set on the position type, then
    // there's no need to prompt the user for a confirmation, so we proceed
    // with the save.
    // Note: This creates a bit of a delay in the UX as the data fetches. Future
    // improvements could include adding a loadings state to the cell, or
    // including the necessary data in the title dropdown so we could skip
    // the follow up flow entirely if we don't need it.
    if (noControlledAttributesSetOnPositionType) {
      handleSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noControlledAttributesSetOnPositionType])

  if (isLoading || isError || noControlledAttributesSetOnPositionType) {
    return null
  }

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    setIsSaving(true)
    handleSave()
  }

  return (
    <TitleChangeFollowUpModal
      maybeInitialTitle={row.data.title}
      maybeNewTitle={field.value}
      controlledAttributeNamesWithSetValuesHash={controlledAttributeNamesWithSetValuesHash}
      isOpen={isOpen}
      isSaving={isSaving}
      handleClose={cancelFollowUp}
      handleSubmit={handleSubmit}
    />
  )
}

export { TitleChangeFollowUp }
