import { AnimatePresence, motion } from "framer-motion"
import React from "react"

import { FeatureFlags, IntegrationFlags } from "types/graphql"
import { ADP } from "v2/react/components/orgChart/ProfilePanel/PositionModules/ADP"
import { Allocations } from "v2/react/components/orgChart/ProfilePanel/PositionModules/Allocations"
import { CustomFields } from "v2/react/components/orgChart/ProfilePanel/PositionModules/CustomFields"
import { JobDescription } from "v2/react/components/orgChart/ProfilePanel/PositionModules/JobDescription"
import { PlanningAndForecasting } from "v2/react/components/orgChart/ProfilePanel/PositionModules/PlanningAndForecasting"
import { PositionInfo } from "v2/react/components/orgChart/ProfilePanel/PositionModules/PositionInfo"
import { Recruiting } from "v2/react/components/orgChart/ProfilePanel/PositionModules/Recruiting"
import { SuccessionPlan } from "v2/react/components/orgChart/ProfilePanel/PositionModules/SuccessionPlan"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { opaqueBasic } from "v2/react/utils/framerAnimationVariants"
import { useGetFeatureFlagsQuery } from "v2/redux/GraphqlApi"
import { ProfileBox } from "v2/redux/slices/ProfilePanelSlice"
import { useAppSelector } from "v2/redux/store"

interface PositionModulesProps {
  abilities: ProfilePanelAbilities
  integrationFlags: IntegrationFlags
}

function PositionModules({ abilities, integrationFlags }: PositionModulesProps) {
  const { data, isFetching } = useGetFeatureFlagsQuery()
  const sortedBoxes = useAppSelector((state) => state.profilePanel.sortedBoxes)

  if (!data || !data?.currentCompany?.featureFlags || isFetching) return null
  const ff: FeatureFlags = data.currentCompany?.featureFlags

  const positionModuleMap: Record<string, React.ReactNode> = {
    position_info: (
      <PositionInfo
        key="position_info"
        abilities={abilities}
        featureFlags={ff}
        integrationFlags={integrationFlags}
      />
    ),
    job_description: (
      <JobDescription key="job_description" abilities={abilities} featureFlags={ff} />
    ),
    position_planning: (
      <PlanningAndForecasting
        key="position_planning"
        abilities={abilities}
        featureFlags={ff}
        integrationFlags={integrationFlags}
      />
    ),
    succession_plan: (
      <SuccessionPlan key="succession_plan" abilities={abilities} featureFlags={ff} />
    ),
    recruiting: <Recruiting key="recruiting" featureFlags={ff} />,
    adp: <ADP key="adp" />,
    custom_fields: <CustomFields key="custom_fields" featureFlags={ff} />,
    allocations: <Allocations key="allocations" abilities={abilities} featureFlags={ff} />,
  }

  return (
    <AnimatePresence>
      <motion.div
        className="react-profile-panel__module"
        variants={opaqueBasic}
        initial="hidden"
        animate="shown"
        exit="hidden"
      >
        {sortedBoxes
          .filter((box: ProfileBox) => box.mode === "position")
          .map((box) => positionModuleMap[box.name])}
      </motion.div>
    </AnimatePresence>
  )
}

export { PositionModules }
