import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useState } from "react"

import type { Column, CursorConnection, NodeRow } from "v2/react/shared/Datasheet/types"
import {
  toEncodedIdNamePair,
  toEncodedInitialData,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"

import { CollectionAutocomplete, PrepareValueHandler } from "./CollectionAutocomplete"

type OrgUnitAutocompleteProps<TNode, CType> = {
  column: Column<CType>
  currentValue?: string
  cursorConnection: CursorConnection
  isFirst: boolean
  isLast: boolean
  row: NodeRow<TNode>
  style: React.CSSProperties
}

const prepareValue: PrepareValueHandler = (arg) => {
  switch (arg.type) {
    case "custom":
      return toEncodedInitialData(arg.inputValue)
    case "noChange":
      // Handle edge case where the initial value is an org unit, it hasn't
      // changed, and the autocomplete component doesn't have an id for it. By
      // returning the unencoded value, a later check skips saving.
      return arg.inputValue
    case "suggestion":
      return toEncodedIdNamePair(arg.id, arg.label)
    default:
      // Default case is for "blank"
      return ""
  }
}

/**
 * Presents an autocomplete for the org unit type along with an action to add a
 * new org unit.
 *
 * @public
 */
function OrgUnitAutocomplete<TNode, CType>({
  column,
  currentValue,
  cursorConnection,
  isFirst,
  isLast,
  row,
  style,
}: OrgUnitAutocompleteProps<TNode, CType>) {
  const footerClassName = classNames(
    "AutocompleteField__footer",
    "align-center flex justify-center py-2 px-4",
  )
  const [inputValue, setInputValue] = useState(currentValue ?? "")
  const handleAddNewClick = () => {
    cursorConnection.saveWrite(toEncodedInitialData(inputValue))
  }

  return (
    <CollectionAutocomplete
      allowCustomInput
      column={column}
      cursorConnection={cursorConnection}
      fallbackOption={
        <div className={footerClassName}>
          <button className="btn--sm btn--primary" onMouseDown={handleAddNewClick} type="button">
            <FontAwesomeIcon icon={["far", "plus"]} />
            {"Add %s".t("defaults", null, null, null, [column.label])}
          </button>
        </div>
      }
      focusCell={false}
      html={currentValue || null}
      isFirst={isFirst}
      isLast={isLast}
      noBorder
      onInputValueChange={setInputValue}
      prepareValue={prepareValue}
      row={row}
      style={style}
    />
  )
}

export { OrgUnitAutocomplete, OrgUnitAutocompleteProps }
