import classNames from "classnames"
import React from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

interface TextInputProps {
  autoFocus?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  id: string
  label?: string
  name?: string
  defaultValue?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors?: string
  placeholder?: string
  disabled?: boolean
  showErrorMessage?: boolean
  showAttention?: boolean
  maxLength?: number
  className?: string
}

const TextInput = ({
  autoFocus,
  inputRef,
  id,
  label,
  name,
  defaultValue,
  onChange,
  errors,
  placeholder,
  disabled,
  showErrorMessage,
  showAttention,
  maxLength,
  className,
}: TextInputProps) => (
  <InputWrapper
    label={label}
    errors={errors}
    showErrorMessage={showErrorMessage}
    id={id}
    className={classNames(className, {
      attention: showAttention,
    })}
  >
    <input
      ref={inputRef}
      id={id}
      name={name}
      type="text"
      // The use of autoFocus may not be the best choice from an accessibility
      // perspective. Consider revising if possible.
      // https://brucelawson.co.uk/2009/the-accessibility-of-html-5-autofocus/
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-autofocus.md
      //
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className="input"
      defaultValue={defaultValue || ""}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
    />
  </InputWrapper>
)

export { TextInput }
