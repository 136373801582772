import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import currencyIcons from "app_config/currency_icons.json"
import classNames from "classnames"
import React, { forwardRef, useState } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"
import { prepareIconClass } from "v2/react/utils/misc"

type IconClass = (typeof currencyIcons)[keyof typeof currencyIcons]

interface CurrencyInputProps {
  id: string
  name: string
  iconClass?: IconClass
  label?: string
  defaultValue?: string | undefined | number
  errors?: string
  showErrorMessage?: boolean
  useAttentionState?: boolean
  readOnly?: boolean
  disabled?: boolean
  inputClassName?: string
  wrapperClassName?: string
  wrapperCombineClassNames?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  renderErrors?: (errors: string) => React.ReactNode
}

const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      id,
      name,
      iconClass = window.gon.currency_icon_class || "far fa-dollar-sign",
      label,
      defaultValue,
      errors,
      showErrorMessage = true,
      useAttentionState = false,
      readOnly = false,
      disabled = false,
      inputClassName,
      wrapperClassName,
      wrapperCombineClassNames,
      onChange,
      renderErrors,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState<string>(defaultValue?.toString() || "")

    const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    return (
      <InputWrapper
        label={label}
        errors={errors}
        showErrorMessage={showErrorMessage}
        renderErrors={renderErrors}
        id={id}
        className={wrapperClassName}
        combineClassNames={wrapperCombineClassNames}
        onMouseEnterLabel={handleFocus}
        onMouseLeaveLabel={handleBlur}
      >
        <div
          className={classNames("relative", wrapperClassName, {
            readonly: readOnly || disabled,
            active: isFocused,
            attention: showAttention,
          })}
        >
          <div className="prefix">
            <FontAwesomeIcon icon={prepareIconClass(iconClass)} />
          </div>
          <input
            ref={ref}
            type="text"
            className={classNames("input prefix-pad", inputClassName)}
            id={id}
            name={name}
            defaultValue={`${defaultValue || ""}`}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={readOnly}
            disabled={disabled}
          />
        </div>
      </InputWrapper>
    )
  },
)

export { CurrencyInput }
