import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import type { SunburstNode } from "v2/dashboards/charts/sunburst"
import { Card } from "v2/react/components/careers/Index/Card"
import { GridColumnWrapper, Section } from "v2/react/components/careers/Index/Section"
import { LoadingIndicator } from "v2/react/shared/loaders/LoadingIndicator"
import { ErrorFallback } from "v2/react/shared/overlay/Modal/ErrorFallback"
import { useFetchInfiniteListOfPositionTypesQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

interface PaginatedPositionTypeCardsProps {
  jobFamily: SunburstNode | null
  title: string
  filterByIndicatedInterest?: boolean
  hideSectionIfEmpty?: boolean
  filterByRecommended?: boolean
  setParentLoadedState?: (isLoading: boolean) => void
}

// Chose a multiple of 2 and 3 in order to ensure we always have a clean layout.
// Columns change at breakpoints from 3 -> 2 -> 1.
const PAGE_SIZE = 18

const PaginatedPositionTypeCards = ({
  jobFamily,
  title,
  filterByIndicatedInterest,
  hideSectionIfEmpty,
  filterByRecommended,
  setParentLoadedState,
}: PaginatedPositionTypeCardsProps) => {
  const { t } = useTranslation()
  const [queryParams, setQueryParams] = useState({
    jobFamilyKey: getJobFamilyKey(jobFamily),
    after: null as string | null,
    excludeNestedJobFamilies: !!jobFamily?.defaultFor,
  })

  const {
    data: connection,
    isFetching,
    isError,
    isLoading,
    isSuccess,
  } = useFetchInfiniteListOfPositionTypesQuery({
    first: PAGE_SIZE,
    excludeEmpty: true,
    filterByIndicatedInterest,
    filterByRecommended,
    ...queryParams,
  })

  // Reset query params when job family changes.
  useEffect(() => {
    setQueryParams({
      jobFamilyKey: getJobFamilyKey(jobFamily),
      after: null,
      excludeNestedJobFamilies: !!jobFamily?.defaultFor,
    })
  }, [jobFamily])

  // Handles setting the parent loaded state when the query is successful.
  useEffect(() => {
    if (setParentLoadedState && isSuccess && !isLoading) {
      setParentLoadedState(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const positionTypes = connection?.nodes ?? []
  const pageInfo = connection?.pageInfo

  const loadMore = () => {
    if (pageInfo?.hasNextPage && !isFetching) {
      setQueryParams((prev) => ({
        ...prev,
        after: pageInfo?.endCursor ?? null,
      }))
    }
  }

  if (hideSectionIfEmpty && positionTypes.length === 0) return null

  return (
    <Section title={title} isFetching={!isLoading && isFetching}>
      <ErrorFallback isError={isError} errorMessage={t("v2.defaults.error")}>
        <LoadingIndicator isLoading={isLoading}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25, type: "easeInEaseOut" }}
            className="section-content"
          >
            <GridColumnWrapper>
              {positionTypes?.map((positionType) => (
                <Card key={positionType.uniqueKey} positionType={positionType} />
              ))}
            </GridColumnWrapper>
            {pageInfo?.hasNextPage && (
              <div className="w-full items-center justify-center py-8 flex">
                <button
                  type="button"
                  className="btn--large btn--secondary"
                  onClick={loadMore}
                  disabled={isFetching}
                >
                  {isFetching ? t("v2.defaults.loading_ellipses") : t("v2.defaults.show_more")}
                </button>
              </div>
            )}
          </motion.div>
        </LoadingIndicator>
      </ErrorFallback>
    </Section>
  )
}

const getJobFamilyKey = (jobFamily: SunburstNode | null) => jobFamily?.defaultFor || jobFamily?.key

export { PaginatedPositionTypeCards }
