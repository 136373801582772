import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import React, { useEffect, useRef } from "react"

import { Person, Position } from "types/graphql"
import { ArrowNavigator } from "v2/react/components/orgChart/ProfilePanel/ArrowNavigator"
import { PositionActions } from "v2/react/components/orgChart/ProfilePanel/PositionHeader/PositionActions"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { StickyHeader } from "v2/react/components/orgChart/ProfilePanel/StickyHeader"
import { entityFromUniqueKey } from "v2/react/utils/uniqueKey"
import { setMode, setPersonId, setPositionId } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { constructSiblingsNavigationList } from "./utils/constructSiblingsNavigationList"

interface PositionHeaderProps {
  abilities: ProfilePanelAbilities
}

function PositionHeader({ abilities }: PositionHeaderProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const personId = useAppSelector((state) => state.profilePanel.personId)
  const position = useAppSelector((state) => state.profilePanel.position)
  const positionId = useAppSelector((state) => state.profilePanel.positionId)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const containerKey = useAppSelector((state) => state.container.containerKey)
  const dispatch = useAppDispatch()
  const panel = document.getElementById("side-panel__profile") as HTMLDivElement
  const panelRef = useRef<HTMLDivElement>(panel)
  const headerRef = useRef<HTMLDivElement>(null)

  const containerType = containerKey && entityFromUniqueKey(containerKey)
  const isListView = containerType === "list"
  const canReadSensitivePositionData =
    window.gon.can_manage_chart || abilities.canReadSensitivePositionData

  useEffect(() => {
    if (!position || position.id !== positionId || (personId && !person)) return
    if (person) {
      window.App.OrgChart.switchPeople(Number(positionId), Number(personId))
    }

    window.App.OrgChart.focus(Number(positionId))
  }, [person, position, personId, positionId, dispatch])

  const handlePositionNavigation = (item: Position) => {
    if (!position?.id) return
    if (position.id !== item.id) dispatch(setPositionId(item.id))
    // when navigating through positions, determine if a person should be stored as well
    // this will make sure that the person panel mode is disabled for unfilled positions
    if (item.people?.length) dispatch(setPersonId(item.people[0].id))
    if (!item.people?.length) dispatch(setPersonId(null))
  }

  if (!position) return null

  const selectPerson = (person: Person) => {
    dispatch(setPersonId(person.id))
    dispatch(setMode("person"))
    window.App.Preferences.update({ org_chart: { profile_panel_mode: "person" } })
  }

  const isTopNode =
    window.App.OrgChart.getNodeByPosition(Number(position.id))?.attributes.depth === 0

  const peopleButtons = () =>
    (position?.people || []).map((person: Person) => (
      <button
        type="button"
        key={person.id}
        className="badge--link hover"
        onClick={() => selectPerson(person)}
      >
        {person.avatarThumbUrl && (
          <img
            src={person.avatarThumbUrl}
            alt={"Profile Image".t("org_chart")}
            className="h-4 w-4 rounded-full"
          />
        )}
        {person.name}
        <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
      </button>
    ))

  const positionList = constructPositionNavigationList(position)

  return (
    <>
      <StickyHeader containerRef={panelRef} elementRef={headerRef}>
        <div className="items-center justify-between flex">
          <h1 className="title m-0 text-xl font-bold">
            {position.title || "Untitled Position".t("org_chart")}
          </h1>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={["far", "times"]}
            size="2x"
            data-action="hide-panel"
          />
        </div>
      </StickyHeader>

      <div className="react-profile-panel__position-profile" ref={headerRef}>
        <div className="w-full flex">
          <div className="w-full items-center gap-2 pr-2 flex">
            <h1 className="title m-0 text-xl font-bold">
              {position.title || "Untitled Position".t("org_chart")}
            </h1>
            {!isListView && (
              <PositionActions
                containerKey={containerKey}
                positionId={position.id}
                systemIdentifier={position.systemIdentifier}
                canEdit={abilities.canEditPosition}
                canDelete={abilities.canEditPosition}
                canViewHistory={canReadSensitivePositionData}
                canViewSystemIdentifier={canReadSensitivePositionData}
              />
            )}
          </div>

          <ArrowNavigator
            items={positionList}
            disableButtons={
              position.siblings?.length === 1 ||
              !["three_level", "tree"].includes(displayMode) ||
              isTopNode
            }
            currentItemIndex={positionList.findIndex((item) => item.id === position.id)}
            selectItem={handlePositionNavigation}
            tooltipLookup={(item: Position) => item.title || "Untitled Position".t("org_chart")}
          />
        </div>
        <p className="mb-4">
          {"reporting_to".t("org_chart", null, null, null, [determineReportsTo(position)])}
        </p>
        <div className="flex-wrap items-center gap-2 flex">
          <span className="w-fit">{"filled_by".t("org_chart")}</span>
          {peopleButtons()}
          {!position.people?.length && (
            <div className="badge--link hover">
              <span>{determineOpenSince(position)}</span>
              {abilities.canEditPosition && (
                <>
                  <span className="link-text cursor-pointer" data-action="edit-position">
                    {"Fill Position".t("org_chart")}
                  </span>
                  <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { PositionHeader }

export const determineReportsTo = (position: Position) => {
  if (position.reportsToName) {
    const nameIsNotTitle = position.reportsToName !== position.reportsToTitle
    if (nameIsNotTitle)
      return `${position.reportsToName}, ${
        position.reportsToTitle || "Untitled Position".t("org_chart")
      }`
  }
  if (position.reportsToTitle) {
    return position.reportsToTitle
  }
  return position.reportsToSystemIdentifier
}

const determineOpenSince = (position: Position) => {
  const dateDifference = dayjs(Date.now()).diff(position.openSince, "days")

  if (dateDifference < 1) {
    return "open_for_less_than_day".t("org_chart")
  }
  return "open_for_x_days".t("org_chart", null, null, null, [dateDifference])
}

/*
 * This maps over all sibling positions and returns a flattened array of
 * sibling positions. Because the array is given to us seemingly in order
 * of the 'id' attribute, it doesn't accurately reflect which position would
 * be the next position. To determine this, we need to get the 'x' attribute
 * from each node and order them smallest to largest.
 */
const constructPositionNavigationList = (position: Position) => {
  const siblings = position.siblings || []
  const withPositionId = (node: Position) => node.id

  return constructSiblingsNavigationList<Position>(position, siblings, withPositionId)
}
