import { EntityState } from "@reduxjs/toolkit"

import { Maybe, NodeInterface, SortDirection } from "types/graphql"
import {
  Column,
  GroupRow as DatasheetGroupRow,
  NodeRow as DatasheetNodeRow,
} from "v2/react/shared/Datasheet/types"
import { CellCursor } from "v2/redux/slices/GridSlice/cursor/types"
import { EnhancedNodeInterface, FieldKey } from "v2/redux/slices/NodeSlice/types"

/**
 * Tracks the data/values necessary for rendering the datasheet. `skeleton`
 * holds the state necessary to derive a flat array of group and node rows
 * that will ultimately be shown to the user. It only changes when the user
 * explicitly changes active filters, active groups, or the sort order.
 */
interface GridState {
  cursor: CellCursor
  restorableCursorValue: string | undefined

  fields: Field[]
  filters: Filter[]
  groupEntities: EntityState<GroupRow>
  groupFieldKeys: (keyof NodeInterface)[]
  // When both avatar and name are selected, they should appear in the same column
  mergeAvatarAndName: boolean
  positionStatusFilter: PositionStatusFilterOption
  skeleton: EntityState<SkeletonRow>
  sorting: { fieldKey: keyof NodeInterface; direction: `${SortDirection}` }
  followUpModal: {
    isOpen: boolean
    field: {
      fieldKey: keyof NodeInterface
      label: string
      value: Maybe<string>
    } | null
    row: DatasheetNodeRow<EnhancedNodeInterface> | null
  }
}

interface Field extends Column<NodeInterface> {
  enabled: boolean
  fieldKey: keyof NodeInterface
  filterable: boolean
  hideLabel: boolean
  label: string
  restricted: boolean
  width: number
}

interface GroupRow extends DatasheetGroupRow {
  childrenCount: number // Count of nested node rows (i.e., excludes groups).
  color?: Maybe<string>
  depth: number // Tree depth of group (= groups.indexOf(fieldKey)).
  fieldKey: GroupKey // Key for the group (e.g. 'chart_section').
  groupPath: string[] // Should match selected groups.
  id: string
  isExpanded: boolean // Indicates if the group shows its descendants.
  isHidden: boolean // Indicates if the group has a collapsed ancestor.
  label: string // Group label (e.g. 'Chart Section').
  rowType: RowType.Group
  value: GroupValue // The value of this group (e.g. 'HR').
}

interface NodeRow extends NodeInterface {
  [key: string]: unknown
  color?: Maybe<string>
  groupId?: string
  rowType: RowType.Node
}

type Row = GroupRow | NodeRow
enum RowType {
  Node = "Node",
  Group = "Group",
}
type GroupValue = string
type GroupKey = string
interface SkeletonNodeRow {
  id: string
  data: string
  color?: Maybe<string>
  groupId?: string
  rowType: RowType.Node
}
type SkeletonGroupRow = GroupRow
type SkeletonRow = SkeletonGroupRow | SkeletonNodeRow
type PositionStatusFilterOption = "all" | "open" | "filled"
type Filter = { fieldKey: FieldKey; term: string | number }
type NormalizedNodeRow = DatasheetNodeRow<NodeInterface>
type NormalizedRow = GroupRow | NormalizedNodeRow

export {
  Field,
  Filter,
  GridState,
  GroupKey,
  GroupRow,
  GroupValue,
  NodeRow,
  NormalizedNodeRow,
  NormalizedRow,
  PositionStatusFilterOption,
  Row,
  RowType,
  SkeletonNodeRow,
  SkeletonRow,
}
