import { ControlProps } from "@jsonforms/core"
import React, { ChangeEvent, FC } from "react"

// NOTE: The CompensationFollowUp component uses another import:
// import { DateInput } from "v2/react/shared/forms/DateInput"
// The EditEmployeeStatus and this component use:
// import { DateInput } from "v2/react/shared/DateInput"
// Future should reconcile these two spots.
import { DateInput } from "v2/react/shared/DateInput"

import { useValidation } from "./hooks/useValidation"

const JsonDateInput: FC<ControlProps> = ({
  config,
  data,
  id,
  handleChange,
  label,
  path,
  schema,
}: ControlProps) => {
  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) =>
    handleChange(path, event.target.value)

  return (
    <DateInput
      defaultValue={data}
      errorMessage={errorMessage}
      label={label}
      name={id}
      onChange={handleChangeEvent}
      showError={showError}
    />
  )
}

export { JsonDateInput }
