import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "usehooks-ts"

import { ChartApprover, RequestChartApprovalInput } from "types/graphql.d"
import PendingDisplay from "v2/react/components/orgChart/ChartApproval/ApprovalDropdown/PendingDisplay"
import { useSelectList } from "v2/react/hooks/useSelectList"
import { DropdownMenu } from "v2/react/shared/collection/menus/DropdownMenu"
import { ProgressBar } from "v2/react/shared/loaders/ProgressBar"
import { useRequestChartApprovalMutation } from "v2/redux/GraphqlApi/ChartApprovalsApi"
import { toggleDropdown } from "v2/redux/slices/ApprovalSlice"
import { selectDropdown } from "v2/redux/slices/ApprovalSlice/approvalSelectors"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

interface Props {
  approvers: ChartApprover[]
  canConfigure: boolean
  canEditConfiguration: boolean
  chartId: string
}

function PendingDropdown({ approvers, canConfigure, canEditConfiguration, chartId }: Props) {
  const dropdownIsOpen: boolean = useAppSelector(selectDropdown)
  const [showMenu, setShowMenu] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const [requestChartApproval, { isLoading }] = useRequestChartApprovalMutation()

  const toggleDropdownModal = () => dispatch(toggleDropdown(!dropdownIsOpen))
  const closeDropdownModal = useCallback(() => dispatch(toggleDropdown(false)), [dispatch])

  const { refs, floatingStyles, context, getReferenceProps, getFloatingProps } = useSelectList({
    showList: showMenu,
    setShowList: setShowMenu,
    floatOverrides: { placement: "top-start" },
  })

  useEffect(() => {
    setShowMenu(dropdownIsOpen)
  }, [dropdownIsOpen])

  useEffect(() => {
    const handleDropdownVisibility = () => window.innerWidth < 640 && closeDropdownModal()
    // This prevents some odd display behavior if the dropdown is open,
    // then the window is resized < 640px and then resized > 640px
    window.addEventListener("resize", handleDropdownVisibility)
    return () => window.removeEventListener("resize", handleDropdownVisibility)
  }, [closeDropdownModal])

  useOnClickOutside(containerRef, ({ target }) => {
    if (target !== refs.floating.current && !refs.floating.current?.contains(target as Node)) {
      closeDropdownModal()
    }
  })

  const submitRequest = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)

    const input: RequestChartApprovalInput = {
      chartId,
      note: formData.get("note"),
    } as RequestChartApprovalInput

    await requestChartApproval({ input }).unwrap()
    closeDropdownModal()
  }

  return (
    <div ref={containerRef}>
      <button
        className="btn--large btn--secondary relative overflow-hidden whitespace-nowrap"
        type="button"
        ref={refs.setReference}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getReferenceProps({ onClick: toggleDropdownModal })}
      >
        {isLoading ? (
          <>
            <p className="m-0">{"submitting".t("org_chart")}</p>
            <ProgressBar loading={isLoading} />
          </>
        ) : (
          <>
            {"request_approval".t("org_chart")}
            <FontAwesomeIcon icon={["fas", "caret-down"]} size="1x" />
          </>
        )}
      </button>
      <DropdownMenu
        showList={showMenu}
        floatingRef={refs.setFloating}
        floatingStyles={{ ...floatingStyles, minWidth: "min(400px, 90vw)", left: 0 }}
        floatingProps={getFloatingProps}
        wrapperClasses="dropdown-menu hidden sm:!block z-10 mt-[2px] p-4"
        context={context}
      >
        <form onSubmit={submitRequest}>
          <PendingDisplay
            approvers={approvers}
            canConfigure={canConfigure}
            canEditConfiguration={canEditConfiguration}
          />

          <hr className="m-0 mx-[-1rem]" />

          <div className="justify-end gap-2 px-4 pt-4 flex">
            <button
              type="button"
              className="btn--large btn--secondary"
              onClick={closeDropdownModal}
            >
              {"cancel".t("org_chart")}
            </button>
            <button type="submit" className="btn--large btn--primary">
              {"submit_request".t("org_chart")}
            </button>
          </div>
        </form>
      </DropdownMenu>
    </div>
  )
}

export { PendingDropdown }
