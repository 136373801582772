import { EntityId } from "@reduxjs/toolkit"
import fp from "lodash/fp"

import type { Maybe } from "types/graphql"
import type { ChartContainer, ChartSectionsChangePayload } from "v2/redux/containers/types"
import type { ProfilePanelState } from "v2/redux/slices/ProfilePanelSlice"
import type { ChartSettings } from "v2/redux/slices/VisualizationSlice/types"

type ConnectArgs = { chart: D3Chart; withChartContainer: ChartContainer }
type ChartNode = any // eslint-disable-line @typescript-eslint/no-explicit-any
type D3Chart = {
  colorCodeAllChartNodes: () => void
  find: (id: EntityId) => ChartNode
  focus: (nodeId: EntityId) => void
  getOptions: () => ChartSettings
  reloadData: () => Promise<void>
  renderNode: (node: ChartNode, options: { withChildren?: boolean; focus?: boolean }) => void
  updateOptions: <T>(options: T) => void
}

// This list can expand to watch other settings as well.
const pickObservedSettings = fp.pick(["enableChartSections"])

let cleanupActiveConnection: Maybe<() => void> = null

/**
 * Connects the "tree", "three-level", and "cards" views to Redux in order to
 * reactively render as settings, chart sections, and color codes change.
 */
export function connectChartToRedux({ chart, withChartContainer: chartContainer }: ConnectArgs) {
  if (cleanupActiveConnection) cleanupActiveConnection()

  const activeListeners = [
    chartContainer.colorCoding.startListening(chart.colorCodeAllChartNodes),
    chartContainer.chartSections.startListening(mapChartSectionChangesInto(chart)),
    chartContainer.chartSettings.startListening(updateOptionsWhenObservedSettingChanges(chart)),
    chartContainer.profilePanel.startListening(updateVisibleAvatars(chart)),
  ]

  cleanupActiveConnection = () => {
    activeListeners.forEach((cleanup) => cleanup())
    cleanupActiveConnection = null
  }

  return cleanupActiveConnection
}

// Ensures that the avatars on the org chart & utility nav are updated alongside a new avatar
// upload from the profile panel.
const updateVisibleAvatars = (chart: D3Chart) => (next: ProfilePanelState) => {
  const person = next.person
  if (!person) return
  const personId = parseInt(person.id, 10)

  const avatarThumbUrl = next.person?.avatarThumbUrl
  if (!avatarThumbUrl) return

  const positionIds = person.positions?.map((p) => parseInt(p.id, 10))
  if (!positionIds) return

  // Update the toolbar avatar if the avatar edited is the current user's.
  if (window.gon.current_person_id === personId) {
    window.$(".nav .person-dropdown-link > img").attr("src", avatarThumbUrl)
  }

  positionIds
    .map((id) => chart.find(id))
    .forEach((node) => {
      const personIsDisplayedOnNode = node.attributes.person_id === personId

      // Update the orgchart node only if the person is currently displayed on that node.
      if (personIsDisplayedOnNode) node.set({ avatar: avatarThumbUrl })

      // Update the nested people data for every node to ensure the
      // avatar change persists when the user switches between people.
      const nodePeople = node
        .get("people")
        .map((person: { id: number }) =>
          person.id === personId ? { ...person, avatar: avatarThumbUrl } : person,
        )

      node.setWithoutRender({ people: nodePeople })
    })
}

const updateOptionsWhenObservedSettingChanges = (chart: D3Chart) => (next: ChartSettings) => {
  const current = chart.getOptions()
  const observedSettings = pickObservedSettings(next)
  const incomingKeys = fp.keys(observedSettings)

  const anyChanged = fp.any((key) => fp.prop(key, next) !== fp.prop(key, current), incomingKeys)
  if (anyChanged) chart.updateOptions(observedSettings)
}

type ChangeMapperFunc = (d3Chart: D3Chart) => (changes: ChartSectionsChangePayload) => void
const mapChartSectionChangesInto: ChangeMapperFunc = (d3Chart) => (changes) => {
  const { created, deleted, updated } = changes

  updated.forEach(([currentSection, priorSection]) => {
    if (currentSection.head.id !== priorSection.head.id) {
      d3Chart.reloadData()
      return
    }

    if (fp.matches(fp.pick(["color", "name"], currentSection), priorSection)) return

    d3Chart.find(currentSection.head.id).setWithoutRender({
      chart_section: currentSection.name,
      chart_section_color: currentSection.color,
    })
    d3Chart.updateOptions({})
  })

  created.forEach((section) => {
    const chartNode = d3Chart.find(section.head.id)
    if (!chartNode) {
      d3Chart.reloadData().then(() => d3Chart.focus(section.head.id))
      return
    }

    chartNode
      .setWithoutRender({
        is_chart_section_head: true,
        chart_section: section.name,
        chart_section_color: section.color,
        chart_section_id: section.id,
      })
      .render({ withChildren: false, focus: true })
    window.App.OrgChart.reloadMetrics()
  })

  deleted.forEach((section) => {
    d3Chart
      .find(section.head.id)
      ?.setWithoutRender({ is_chart_section_head: false })
      ?.render({ withChildren: false, focus: true })
  })
}
