import React from "react"

import { ConnectedJobRequisition, FeatureFlags, PositionActiveJobRequisition } from "types/graphql"
import { SendToAts } from "v2/react/components/jobRequisitions/SendToAts/SendToAts"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { InternalReqInfo } from "v2/react/components/orgChart/ProfilePanel/PositionModules/InternalReqInfo"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface ConnectedRequisition {
  id: number
  reqId: string
  status: string
}

interface Requisition {
  reqId: string
  approvalState?: string | null
}

interface RecruitingProps {
  featureFlags: FeatureFlags
}

function Recruiting({ featureFlags }: RecruitingProps) {
  const { currentBox, toggleBox } = useSortedBoxes("recruiting", "position")
  const position = useAppSelector((state) => state.profilePanel.position)

  if (!currentBox || !position) return null
  if (!featureFlags.positionManagement) return null

  const headerContent = <div className="title">{"recruiting".t("org_chart")}</div>
  const statuses = ["pending_approval", "open", "on_hold", "offer_pending", "filled"]
  const states = ["pending_approval", "no_configuration", "changes_requested", "denied", "approved"]
  const filteredReqs: ConnectedRequisition[] = (position.connectedJobRequisitions || []).reduce(
    (accReqs: ConnectedRequisition[], req: ConnectedJobRequisition) => {
      if (req.status && req.systemUid && statuses.includes(req.status)) {
        accReqs.push({
          id: Number(req.id),
          reqId: req.systemUid,
          status: req.status,
        })
      }
      return accReqs
    },
    [],
  )

  const reqs: Requisition[] = (position.activeJobRequisitions || []).reduce(
    (accReqs: Requisition[], req: PositionActiveJobRequisition) => {
      if (req.systemUid && states.includes(req.approvalState || "")) {
        accReqs.push({
          reqId: req.systemUid,
          approvalState: req.approvalState,
        })
      }
      return accReqs
    },
    [],
  )

  const sendToAts = (
    <SendToAts
      asDropdownMenuLink={false}
      title={position.title || ""}
      positionId={position.id}
      locationName={position.location?.label || ""}
      orgUnitNames={(position.orgUnits || []).map((ou) => ou.name || "")}
      connectedRequisitions={filteredReqs}
    />
  )

  const internalReqs = (
    <div>
      <InternalReqInfo requisitions={reqs} />
    </div>
  )

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div className="w-full">{window.hasSendToAtsSupport() ? sendToAts : internalReqs}</div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { Recruiting }
