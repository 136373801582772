import {
  addListener,
  createListenerMiddleware,
  type ListenerEffectAPI,
  type TypedAddListener,
  type TypedStartListening,
} from "@reduxjs/toolkit"

import { startListeningForSubscribe } from "v2/redux/listeners/cableListeners"
import type { AppDispatch, RootState } from "v2/redux/store"

import { startListeningForFieldSuggestionEvents } from "./listeners/fieldSuggestionListeners"

type AppStartListening = TypedStartListening<RootState, AppDispatch>
type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>

const listenerMiddleware = createListenerMiddleware({
  onError: (error) => window.Sentry?.captureException?.(error),
})
const startAppListening = listenerMiddleware.startListening as AppStartListening
const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>

startListeningForSubscribe()
startListeningForFieldSuggestionEvents()

export {
  listenerMiddleware,
  startAppListening,
  addAppListener,
  AppStartListening,
  AppListenerEffectAPI,
}
