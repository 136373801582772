import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef } from "react"

import { Maybe, Person, Position } from "types/graphql"
import { ArrowNavigator } from "v2/react/components/orgChart/ProfilePanel/ArrowNavigator"
import { AvatarWithUpload } from "v2/react/components/orgChart/ProfilePanel/AvatarWithUpload"
import { EllipsisDropdown } from "v2/react/components/orgChart/ProfilePanel/EllipsisDropdown"
import { EmailIcon } from "v2/react/components/orgChart/ProfilePanel/Icons/Emailcon"
import { PhoneIcon } from "v2/react/components/orgChart/ProfilePanel/Icons/PhoneIcon"
import { SocialLink } from "v2/react/components/orgChart/ProfilePanel/Icons/SocialLink"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { StickyHeader } from "v2/react/components/orgChart/ProfilePanel/StickyHeader"
import { pjaxModalFor } from "v2/react/utils/pjax"
import { useGetCurrentPersonQuery } from "v2/redux/GraphqlApi"
import { setMode, setPersonId, setPositionId } from "v2/redux/slices/ProfilePanelSlice"
import { useAppDispatch, useAppSelector } from "v2/redux/store"

import { constructSiblingsNavigationList } from "./utils/constructSiblingsNavigationList"

type ExtendedPerson = Person & { positionId: string }

interface PersonHeaderProps {
  abilities: ProfilePanelAbilities
}

function PersonHeader({ abilities }: PersonHeaderProps) {
  const { data: currentPerson, isLoading } = useGetCurrentPersonQuery({})
  const person = useAppSelector((state) => state.profilePanel.person)
  const personId = useAppSelector((state) => state.profilePanel.personId)
  const position = useAppSelector((state) => state.profilePanel.position)
  const positionId = useAppSelector((state) => state.profilePanel.positionId)
  const displayMode = useAppSelector((state) => state.visualization.displayMode)
  const isCurrentPerson = person?.id === currentPerson?.currentPerson?.id
  const dispatch = useAppDispatch()
  const panel = document.getElementById("side-panel__profile") as HTMLDivElement
  const panelRef = useRef<HTMLDivElement>(panel)
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!person || person?.id !== personId || !position || position.id !== positionId) return
    window.App.OrgChart.switchPeople(Number(position.id), Number(person.id))
    window.App.OrgChart.focus(Number(position.id))
  }, [person, personId, position, positionId])

  if (isLoading || !person) return null

  const isTopNode =
    window.App.OrgChart.getNodeByPosition(Number(position?.id))?.attributes.depth === 0

  const handlePersonNavigation = (item: ExtendedPerson) => {
    if (!position?.id) return
    dispatch(setPersonId(item.id))
    dispatch(setPositionId(item.positionId))
  }

  const selectPosition = () => {
    dispatch(setMode("position"))
    window.App.Preferences.update({ org_chart: { profile_panel_mode: "position" } })
  }

  const { phone, tooltip } = determinePhoneNumber(
    person.workCellPhone,
    person.officePhone,
    person.officePhoneExt,
  )

  const peopleList = position ? constructPersonNavigationList(position) : []

  const nameAndMenuContent = () => (
    <>
      <h1 className="title m-0 grow text-xl font-bold">{person.fullName}</h1>
      {((isCurrentPerson && abilities.canManageOwnLogin) ||
        abilities.canManageSubordinateLogin) && (
        <EllipsisDropdown>
          <>
            {!isCurrentPerson && (
              <a
                href={`/people/${person?.id}/messages/new?invite=true`}
                className="dropdown-menu-link"
                data-feature="multiple_users"
                data-action="send-invitation"
                onClick={(event) => {
                  event.preventDefault()
                  pjaxModalFor(`/people/${person?.id}/messages/new?invite=true`)
                }}
              >
                {determineInvitationText(person)}
              </a>
            )}
            {person.userId && determineLoginEditLink(abilities, person)}
            {abilities.canManagePerson && !isCurrentPerson && (
              <a
                href={`people/${person?.id}/edit/update_status`}
                className="dropdown-menu-link cursor-pointer"
                data-action="update-status"
                onClick={(event) => {
                  event.preventDefault()
                  pjaxModalFor(`people/${person?.id}/edit/update_status`)
                }}
              >
                {"Change Employee Status".t("profile")}
              </a>
            )}
          </>
        </EllipsisDropdown>
      )}
    </>
  )

  return (
    <>
      <StickyHeader containerRef={panelRef} elementRef={headerRef}>
        <div className="items-center justify-between flex">
          <div className="person-header items-center gap-2 flex">
            <AvatarWithUpload
              personId={personId}
              thumbUrl={person?.avatarThumbUrl}
              canUploadAvatar={abilities.canEditPerson || isCurrentPerson}
            />
            <div className="items-center flex">{nameAndMenuContent()}</div>
          </div>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={["far", "times"]}
            size="2x"
            data-action="hide-panel"
          />
        </div>
      </StickyHeader>

      <div className="react-profile-panel__person-profile gap-4 flex" ref={headerRef}>
        <AvatarWithUpload
          personId={personId}
          thumbUrl={person?.avatarThumbUrl}
          canUploadAvatar={abilities.canEditPerson || isCurrentPerson}
        />
        <div className="w-full flex-col gap-2 flex">
          <div className="w-full gap-2 flex">
            {nameAndMenuContent()}
            <ArrowNavigator
              items={peopleList}
              disableButtons={
                peopleList.length === 1 ||
                !["three_level", "tree"].includes(displayMode) ||
                isTopNode
              }
              currentItemIndex={peopleList.findIndex(
                (item: ExtendedPerson) => item.id === person.id && item.positionId === position?.id,
              )}
              selectItem={handlePersonNavigation}
              tooltipLookup={(item: ExtendedPerson) => item?.name}
            />
          </div>

          <div className="profile-links items-center gap-4 flex">
            {person?.workEmail && <EmailIcon email={person.workEmail} />}
            {phone && tooltip && <PhoneIcon phone={phone} phoneTooltip={tooltip} />}
            {person.bioSocialLinks?.map((link) => <SocialLink socialLink={link} key={link.url} />)}
          </div>
          <button type="button" className="badge--link hover" onClick={selectPosition}>
            {position?.title || "Untitled Position".t("org_chart")}
            <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
          </button>
        </div>
      </div>
    </>
  )
}

export { PersonHeader }

const determinePhoneNumber = (
  cell: Maybe<string> | undefined,
  office: Maybe<string> | undefined,
  ext: Maybe<string> | undefined,
) => {
  if (!cell && !office) return { phone: null, tooltip: null }
  if (cell) return { phone: cell, tooltip: cell }
  if (office && ext) return { phone: `${office}, ${ext}`, tooltip: `${office} ext. ${ext}` }
  return { phone: office, tooltip: office }
}

const determineInvitationText = (person: Person) => {
  if (person.userId) return "Invitation Status".t("profile")
  if (person.invitationSentAt) return "Resend Invitation".t("profile")
  return "Send Invitation".t("profile")
}

const determineLoginEditLink = (abilites: ProfilePanelAbilities, person: Person) => {
  if (abilites.canManageOwnLogin) {
    return (
      <a href="/user/edit" className="dropdown-menu-link">
        {"Manage Login Info".t("profile")}
      </a>
    )
  }
  if (abilites.canManageSubordinateLogin) {
    return (
      <a
        href={`/users/${person.userId}/edit`}
        className="dropdown-menu-link cursor-pointer"
        onClick={(event) => {
          event.preventDefault()
          pjaxModalFor(`/users/${person.userId}/edit`)
        }}
      >
        {"Manage Login Info".t("profile")}
      </a>
    )
  }

  return <span />
}

/*
 * This maps over all sibling positions + their people and returns a
 * flattened array of sibling people. Because the array is given to
 * us seemingly in order of the 'id' attribute, it doesn't accurately
 * reflect which person/position would be the next position. To determine
 * this, we need to get the 'x' attribute from each node and order them
 * smallest to largest
 */
const constructPersonNavigationList = (position: Position) => {
  const withPositionId = (node: ExtendedPerson) => node.positionId
  const siblings = (position.siblings || [])
    .map(({ id, people }) => (people || []).map((person) => ({ ...person, positionId: id })))
    .flat()

  return constructSiblingsNavigationList<ExtendedPerson>(position, siblings, withPositionId)
}
