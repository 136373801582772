import dayjs from "dayjs"
import React from "react"

import { FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { ProfilePanelAbilities } from "v2/react/components/orgChart/ProfilePanel/ProfilePanel"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface CompensationProps {
  abilities: ProfilePanelAbilities
  featureFlags: FeatureFlags
}

function Compensation({ abilities, featureFlags }: CompensationProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("compensation", "person")

  if (!currentBox || !person) return null
  if (!featureFlags.compensation || !abilities.canReadCompensation || !person.basePayType)
    return null

  const basePay = (
    <ModuleListItem>
      <div>{"Base Pay".t("org_chart")}</div>
      <div>
        {window.App.Helpers.formatCurrency(person.basePay || "", { currency: person.currencyCode })}
      </div>
    </ModuleListItem>
  )

  const basePayType = (
    <ModuleListItem>
      <div>{"Base Pay Type".t("org_chart")}</div>
      <div>{person.basePayType?.t("org_chart") || ""}</div>
    </ModuleListItem>
  )

  const hoursPerWeek = (
    <ModuleListItem>
      <div>{"Hours Per Week".t("org_chart")}</div>
      <div>{person.hoursPerWeek?.toFixed(1) || ""}</div>
    </ModuleListItem>
  )

  const variablePays = (person.variablePays || []).map((payType) =>
    payType.display_name ? (
      <ModuleListItem key={payType.display_name}>
        <div>{payType.display_name}</div>
        <div>{payType.amount ? payType.formatted_value : null}</div>
      </ModuleListItem>
    ) : null,
  )

  const payEffectiveDate = (
    <ModuleListItem>
      <div>{"Pay Effective Date".t("org_chart")}</div>
      <div>{person.payEffectiveDate && dayjs(person.payEffectiveDate).format("MMM D, YYYY")}</div>
    </ModuleListItem>
  )

  const payHistory = (
    <ModuleListItem>
      <div />
      <a href={`/people/${person.id || ""}/compensation`}>{"Pay History Link".t("org_chart")}</a>
    </ModuleListItem>
  )

  const headerContent = <div className="title">{"compensation".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      {basePay}
      {basePayType}
      {person.basePayType === "hourly" && hoursPerWeek}
      {variablePays}
      {payEffectiveDate}
      {payHistory}
    </AccordionModulePanel>
  )
}

export { Compensation }
