import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import parse from "html-react-parser"
import React from "react"
import sanitizeHtml from "sanitize-html"

import { Modal } from "v2/react/shared/overlay/Modal"
import { useFinalizeHeadcountPlanMutation } from "v2/redux/GraphqlApi/HeadcountPlanningApi"

type Props = {
  canBeFinalized: boolean
  onClick?: () => void
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  display?: "menuItem" | "button"
}
export function FinalizePlanButton({
  canBeFinalized,
  setIsOpen,
  onClick,
  display = "button",
}: Props) {
  return (
    <button
      className={cn("whitespace-nowrap", {
        "btn--large btn--primary": display === "button",
        "dropdown-menu-link gap-2 flex": display === "menuItem",
        "btn-disabled": !canBeFinalized,
      })}
      type="button"
      onClick={() => {
        setIsOpen(true)
        onClick?.()
      }}
      disabled={!canBeFinalized}
    >
      {display === "menuItem" ? (
        <div className="justify-self-center">
          <FontAwesomeIcon icon={["far", "check"]} />
        </div>
      ) : null}
      {"finalize_plan_button".t("headcount_plan")}
    </button>
  )
}

type ModalProps = {
  headcountPlanId: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  headcountPlanName: string
}
export function FinalizePlanModal({
  isOpen,
  setIsOpen,
  headcountPlanId,
  headcountPlanName,
}: ModalProps) {
  const [mutate, mutation] = useFinalizeHeadcountPlanMutation()

  React.useEffect(() => {
    if (mutation.data?.updateHeadcountPlan?.success) {
      setIsOpen(false)
    }
  }, [mutation.data, setIsOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={"finalize_plan_modal_title".t("headcount_plan")}
      footer={
        <button
          type="submit"
          className="btn--large btn--primary"
          onClick={() =>
            mutate({
              headcountPlanId,
            })
          }
        >
          {mutation.isLoading
            ? "finalize_plan_button_pending".t("headcount_plan")
            : "finalize_plan_button".t("headcount_plan")}
        </button>
      }
    >
      <div className="p-6">
        <p>
          {parse(
            sanitizeHtml(
              "finalize_plan_modal_body_p1".t("headcount_plan", null, null, null, [
                headcountPlanName,
              ]),
            ),
          )}
        </p>
        <p>{"finalize_plan_modal_body_p2".t("headcount_plan")}</p>
      </div>
    </Modal>
  )
}
