import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

import { FeatureFlags } from "types/graphql"
import { ModuleListItem } from "v2/react/components/orgChart/ProfilePanel/ModuleListItem"
import { useSortedBoxes } from "v2/react/components/orgChart/ProfilePanel/useSortedBoxes"
import { AccordionModulePanel } from "v2/react/shared/layout/AccordionModulePanel"
import { useAppSelector } from "v2/redux/store"

interface ListsProps {
  featureFlags: FeatureFlags
}

function Lists({ featureFlags }: ListsProps) {
  const person = useAppSelector((state) => state.profilePanel.person)
  const { currentBox, toggleBox } = useSortedBoxes("lists", "person")

  if (!currentBox || !person) return null
  if (!featureFlags.lists || !person.lists?.length) return null

  const headerContent = <div className="title">{"lists".t("org_chart")}</div>

  return (
    <AccordionModulePanel
      headerContent={headerContent}
      additionalToggleAction={toggleBox}
      collapsed={currentBox.collapsed}
      title={currentBox.name}
    >
      <ModuleListItem>
        <div className="flex-wrap gap-2.5 flex">
          {person.lists?.map((list) => (
            <a
              key={list.id}
              className="badge--link hover mb-2"
              href={`/lists/${list.id.split("_")[1]}/select`}
            >
              {list.name}
              <FontAwesomeIcon icon={["far", "chevron-right"]} className="text-primary-50" />
            </a>
          ))}
        </div>
      </ModuleListItem>
    </AccordionModulePanel>
  )
}

export { Lists }
