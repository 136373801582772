import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import get from "lodash/get"
import React, { MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { pjaxModalFor } from "v2/react/utils/pjax"
import { UrlHelper } from "v2/react/utils/urls"
import { selectCanManageChart } from "v2/redux/slices/AbilitySlice/abilitySelectors"
import { useAppSelector } from "v2/redux/store"

function EmptyState() {
  const canManageChart = useAppSelector(selectCanManageChart)
  const { t } = useTranslation()

  const createNewChartSection = (event: MouseEvent) => {
    event.preventDefault()
    pjaxModalFor(UrlHelper.chartSectionNewPath())
    window.$.pjax.xhr = null
  }

  return (
    <div className="flex-col items-center gap-6 text-center flex">
      <h4 className="px-10">{t("v2.orgchart.super_panel.add_structure")}</h4>
      <div className="px-8">{t("v2.orgchart.super_panel.add_chart_section_beginning")}</div>
      {canManageChart && (
        <button
          type="button"
          className={cn("btn--large btn--primary add", {
            disabled: window.gon.editing_disabled,
          })}
          disabled={window.gon.editing_disabled}
          onClick={createNewChartSection}
        >
          <FontAwesomeIcon icon={["far", "plus"]} className="fa-w-14" />
          <p>{t("v2.orgchart.super_panel.add_chart_section")}</p>
        </button>
      )}
    </div>
  )
}

function ChartSectionsIntro() {
  const { t } = useTranslation()
  const [shouldHide, setShouldHide] = useState(false)

  // Update the user's preferences when switching to "hide" mode.
  useEffect(() => {
    if (!shouldHide) return
    window.App.Preferences.update({
      org_chart: { acknowledged_chart_sections_intro: true },
    })
  }, [shouldHide])

  return (
    !shouldHide && (
      <div className="alert alert-info mb-4 pr-8">
        <button
          type="button"
          className="alert-close-icon-handled bg-transparent text-inherit"
          onClick={() => setShouldHide(true)}
        >
          <FontAwesomeIcon icon={["far", "times"]} size="sm" />
        </button>
        <div className="alert-description chart-sections-intro-message">
          {t("v2.orgchart.super_panel.chart_sections_intro")}
        </div>
      </div>
    )
  )
}

function ChartSectionsTabGuide({ chartSectionsTotal }: { chartSectionsTotal: number }) {
  const [showIntro] = useState(
    !get(window.gon, "person_setting.org_chart.acknowledged_chart_sections_intro"),
  )

  if (chartSectionsTotal === 0) return <EmptyState />
  return showIntro && <ChartSectionsIntro />
}

export default ChartSectionsTabGuide
