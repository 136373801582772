import React from "react"

import { CompareValues } from "v2/react/shared/TableDatasheet/CompareValues"
import { NonEditableCell as NonEditableCellPrimitive } from "v2/react/shared/TableDatasheet/NonEditableCell"
import { StrikethroughCell } from "v2/react/shared/TableDatasheet/StrikethroughCell"
import { Column } from "v2/react/shared/TableDatasheet/types"

import { HeadcountPlanDatasheetRow } from "./types"

type Props = {
  row: HeadcountPlanDatasheetRow
  column: Column<HeadcountPlanDatasheetRow>
  readOnly: boolean
  currentValueOverride?: string
}
export function NonEditableCell({ row, column, readOnly, currentValueOverride }: Props) {
  const currentValue = currentValueOverride ?? row.positionAttributesWithEdits[column.id] ?? ""

  if (row.excluded) {
    return <StrikethroughCell value={currentValue} />
  }

  if (readOnly) {
    return <CompareValues className="non-editable-cell">{currentValue}</CompareValues>
  }

  return (
    <NonEditableCellPrimitive columnId={column.id} rowId={row.id} currentValue={currentValue} />
  )
}
