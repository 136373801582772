import cn from "classnames"
import { motion } from "framer-motion"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useDialog, type AriaDialogProps } from "react-aria"

interface DialogProps extends AriaDialogProps {
  children: ReactNode
  /**
   * By default, focus transitions to the dialog on mount. In cases where we
   * want to force the focus elsewhere on mount, provide the ref here.
   */
  focusRefOnMount?: React.RefObject<HTMLElement>
  className?: string
}

/* eslint-disable react/jsx-props-no-spreading */
function Dialog({ children, className, ...props }: DialogProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)
  const { dialogProps } = useDialog(props, ref)

  useEffect(() => {
    if (props.focusRefOnMount && isAnimationComplete) {
      props.focusRefOnMount.current?.focus()
    }
  }, [props.focusRefOnMount, isAnimationComplete])

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.97 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.97 }}
      transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.084 }}
      onAnimationComplete={() => setIsAnimationComplete(true)}
    >
      {/* eslint-disable react/jsx-props-no-spreading */}
      <div
        {...dialogProps}
        ref={ref}
        className={cn(
          "Date-Range-Dialog elevation absolute left-0 mt-2 min-w-[20rem] rounded-lg bg-white p-2",
          className,
        )}
      >
        {children}
      </div>
    </motion.div>
  )
}

export { Dialog }
