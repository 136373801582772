import classNames from "classnames"
import dayjs from "dayjs"
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react"

import { Error, PersonPositionNodeAttributes } from "types/graphql.d"
import { FollowUpModalProps } from "v2/react/components/orgChart/OrgChartDatasheet/FollowUpModals"
import {
  UpdateNodeInput,
  useUpdateNodeWithMultipleInputs,
} from "v2/react/components/orgChart/OrgChartDatasheet/Modals/hooks/useUpdateNodeWithMultipleInputs"
import { DateInput } from "v2/react/shared/DateInput"
import { Modal } from "v2/react/shared/overlay/Modal"
import { SaveFooter } from "v2/react/shared/overlay/Modal/SaveFooter"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"

function EditEmployeeStatus({ isOpen, field, row }: FollowUpModalProps) {
  const { cancelFollowUp, saveModalSuccess } = useDatasheetListenerActions()
  const [updateAttributesForNode] = useUpdateNodeWithMultipleInputs()
  const [errors, setErrors] = useState<string | React.ReactNode[]>()
  const [isSaving, setIsSaving] = useState(false)
  const [termDate, setTermDate] = useState("")
  const [termReason, setTermReason] = useState("voluntary")
  const [positionEffect, setPositionEffect] = useState("open")
  const dateRef = useRef<HTMLInputElement | null>(null)
  const { data } = row
  const { fieldKey, label, value } = field

  useEffect(() => {
    if (isOpen && dateRef) {
      setTimeout(() => dateRef.current?.focus(), 300)
    }
  }, [isOpen, dateRef])

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTermDate(event.target.value)
  }

  const handleTermReasonChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setTermReason(event.target.value)
  }

  const handleManagePositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPositionEffect(event.target.value)
  }

  const handleSave = async (event: FormEvent<HTMLFormElement>) => {
    setIsSaving(true)
    event.preventDefault()
    const submission: { [key: string]: string } = {
      terminated_at: checkForEmptyDate(termDate),
      termination_reason: termReason,
      termination_effect: positionEffect,
    }

    const entries: UpdateNodeInput[] = Object.keys(submission).map((key) => ({
      fieldKey: key,
      value: submission[key],
    })) as UpdateNodeInput[]
    const inputs: UpdateNodeInput[] = [
      ...entries,
      { fieldKey: fieldKey as keyof PersonPositionNodeAttributes, value },
    ]

    setErrors("")
    const res = await updateAttributesForNode(row.data, inputs, fieldKey)
    setIsSaving(false)

    if (res?.ok) {
      saveModalSuccess()
    } else {
      const errors = res?.errors || []
      const message: React.ReactNode[] = errors.map((s: Error) => (
        <span key={s.message}>
          {s.message}
          <br />
        </span>
      ))
      setErrors(message)
      setIsSaving(false)
    }
  }

  const title = "Edit %s".t("defaults", null, null, null, [label])
  const managePositionDescription = "Termination Effect".t(
    "profile",
    "employment_details",
    null,
    null,
    [data.name, data.title],
  )

  const labelClasses = "flex flex-col font-normal mb-4"

  return (
    <Modal
      isOpen={isOpen}
      onClose={cancelFollowUp}
      title={title}
      shouldReturnFocusAfterClose={false}
    >
      <form className="flex-col flex" onSubmit={handleSave}>
        <div className="p-6">
          {errors && (
            <div className="alert alert-critical mb-4">
              <span>{errors}</span>
            </div>
          )}
          <div className="alert alert-caution mb-4">
            <p className="m-0">{"termination_warning".t("profile")}</p>
            <ul className="m-0">
              <li>{"0".t("profile", "termination_warning_bullet_points")}</li>
              <li>{"1".t("profile", "termination_warning_bullet_points")}</li>
              <li>{"2".t("profile", "termination_warning_bullet_points")}</li>
            </ul>
          </div>
          <DateInput
            name="termDate"
            label={"Termination Date".t("people")}
            defaultValue={termDate}
            onChange={handleDateChange}
            inputRef={dateRef}
          />
          <label className={classNames(labelClasses)}>
            <span className="mb-1 font-bold">{"Termination Reason".t("people")}</span>
            <div className="select">
              <select
                name="termReason"
                id="termReason"
                value={termReason}
                onChange={handleTermReasonChange}
              >
                <option value="voluntary">{"voluntary".t("profile", "termination_reason")}</option>
                <option value="involuntary">
                  {"involuntary".t("profile", "termination_reason")}
                </option>
              </select>
            </div>
          </label>

          <div>
            <span className="mb-1 font-bold">{managePositionDescription}</span>
            <label htmlFor="open" className="radio items-start font-normal flex">
              <input
                type="radio"
                name="managePosition"
                value="open"
                id="open"
                className="mt-1"
                checked={positionEffect === "open"}
                onChange={handleManagePositionChange}
              />
              {"Termination Effect Open".t("profile", "employment_details")}
            </label>

            <label htmlFor="delete" className="radio items-start font-normal flex">
              <input
                type="radio"
                name="managePosition"
                value="delete"
                id="delete"
                className="mt-1"
                checked={positionEffect === "delete"}
                onChange={handleManagePositionChange}
              />
              {data.reports_to
                ? "Termination Effect Delete".t("profile", "employment_details", null, null, [
                    data.reports_to,
                  ])
                : "Termination Effect Delete Short".t("profile", "employment_details")}
            </label>
          </div>
        </div>
        <SaveFooter isSaving={isSaving} />
      </form>
    </Modal>
  )
}

export { EditEmployeeStatus }

const checkForEmptyDate = (date: string) =>
  date.trim().length === 0
    ? dayjs().format("YYYY-MM-DD hh:mm:ss")
    : dayjs(date).format("YYYY-MM-DD hh:mm:ss")
