import React from "react"

import { NotificationRecipient } from "types/graphql"
import { Approvers } from "v2/react/components/jobRequisitions/Overview/SidebarPanel/Approvers"
import { NotificationRecipients } from "v2/react/components/jobRequisitions/Overview/SidebarPanel/NotificationRecipients"
import { JobRequisitionAbilities } from "v2/react/components/jobRequisitions/types"
import { ApproverType } from "v2/react/shared/status/statusUtils"
import {
  setEditApproversModalOpen,
  setEditNotifyeesModalOpen,
} from "v2/redux/slices/RequisitionSlice"
import { useAppDispatch } from "v2/redux/store"

import { IntegrationInfo } from "./IntegrationInfo"

interface Props {
  abilities: JobRequisitionAbilities
  approvalState: string | null | undefined
  approvers: ApproverType[]
  atsIdentifier: string
  clientIdentifier: string
  externalStatus: string
  jobRequisitionId: string
  notificationRecipients: NotificationRecipient[]
  systemUid: string
  title: string
  workflowDate: Date
}

function SidebarPanel({
  abilities,
  approvalState,
  approvers,
  atsIdentifier,
  clientIdentifier,
  externalStatus,
  jobRequisitionId,
  notificationRecipients,
  systemUid,
  title,
  workflowDate,
}: Props) {
  const dispatch = useAppDispatch()

  const showApproversModal = () => {
    dispatch(setEditApproversModalOpen(true))
  }

  const showNotifyeesModal = () => {
    dispatch(setEditNotifyeesModalOpen(true))
  }

  return (
    <div className="page-gap flex-col flex">
      <IntegrationInfo
        abilities={abilities}
        approvalState={approvalState || ""}
        atsIdentifier={atsIdentifier}
        clientIdentifier={clientIdentifier}
        externalStatus={externalStatus}
        jobRequisitionId={jobRequisitionId}
        systemUid={systemUid}
        title={title}
      />
      <Approvers
        approvers={approvers}
        approvalState={approvalState || ""}
        onShowUpdateModal={showApproversModal}
        workflowDate={workflowDate}
        workflowStatus={approvalState}
      />
      <NotificationRecipients
        approvalState={approvalState || ""}
        onShowUpdateModal={showNotifyeesModal}
        recipients={notificationRecipients}
      />
    </div>
  )
}

export { SidebarPanel }
