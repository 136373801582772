import { withJsonFormsControlProps, withJsonFormsLayoutProps } from "@jsonforms/react"
import { vanillaRenderers } from "@jsonforms/vanilla-renderers"

import { JsonAutocompleteInput } from "v2/react/shared/JsonFormControls/AutocompleteControl"
import { JsonBasePayInput } from "v2/react/shared/JsonFormControls/BasePayControl"
import {
  AutocompleteControlTester,
  BasePayControlTester,
  CurrencyControlTester,
  DateControlTester,
  FileControlTester,
  FileUploadControlTester,
  ListBaseCompensationControlTester,
  ListItemControlTester,
  ListItemDescriptionControlTester,
  ListItemFilledByControlTester,
  ListItemSubtitledControlTester,
  ListOrgUnitsControlTester,
  ListTotalCompensationControlTester,
  ListVariableCompensationControlTester,
  MultiSelectControlTester,
  NestedTextControlTester,
  NumberControlTester,
  NumericControlTester,
  OrgUnitControlTester,
  PercentControlTester,
  PersonControlTester,
  PositionControlTester,
  RadioControlTester,
  SelectControlTester,
  TextAreaControlTester,
  TextControlTester,
  TotalCompensationControlTester,
  VariablePayControlTester,
} from "v2/react/shared/JsonFormControls/ControlTesters"
import { JsonCurrencyInput } from "v2/react/shared/JsonFormControls/CurrencyControl"
import { JsonDateInput } from "v2/react/shared/JsonFormControls/DateControl"
import { JsonFileUpload } from "v2/react/shared/JsonFormControls/FileUploadControl"
import { JsonModuleListBaseCompensation } from "v2/react/shared/JsonFormControls/ModuleListBaseCompensationControl"
import { JsonModuleFileItem } from "v2/react/shared/JsonFormControls/ModuleListFileControl"
import { JsonModuleFilledByItem } from "v2/react/shared/JsonFormControls/ModuleListFilledByControl"
import { JsonModuleListItem } from "v2/react/shared/JsonFormControls/ModuleListItemControl"
import { JsonModuleListDescriptionItem } from "v2/react/shared/JsonFormControls/ModuleListItemDescriptionControl"
import { JsonModuleSubtitledItem } from "v2/react/shared/JsonFormControls/ModuleListItemSubtitledControl"
import { JsonModuleListOrgUnits } from "v2/react/shared/JsonFormControls/ModuleListOrgUnitsControl"
import { JsonModuleListTotalCompensation } from "v2/react/shared/JsonFormControls/ModuleListTotalCompensationControl"
import { JsonModuleListVariableCompensation } from "v2/react/shared/JsonFormControls/ModuleListVariableCompensationControl"
import { JsonMultiSelectInput } from "v2/react/shared/JsonFormControls/MultiSelectControl"
import { JsonNestedTextInput } from "v2/react/shared/JsonFormControls/NestedTextControl"
import { JsonNumberInput } from "v2/react/shared/JsonFormControls/NumberControl"
import { JsonNumericInput } from "v2/react/shared/JsonFormControls/NumericControl"
import { JsonOrgUnitInput } from "v2/react/shared/JsonFormControls/OrgUnitControl"
import { JsonPercentInput } from "v2/react/shared/JsonFormControls/PercentControl"
import { JsonPersonInput } from "v2/react/shared/JsonFormControls/PersonControl"
import { JsonPositionInput } from "v2/react/shared/JsonFormControls/PositionControl/PositionControl"
import { JsonRadioInput } from "v2/react/shared/JsonFormControls/RadioControl"
import { JsonSelectInput } from "v2/react/shared/JsonFormControls/SelectControl"
import { JsonTextAreaInput } from "v2/react/shared/JsonFormControls/TextAreaControl"
import { JsonTextInput } from "v2/react/shared/JsonFormControls/TextControl"
import { JsonTotalCompensationInput } from "v2/react/shared/JsonFormControls/TotalCompensationControl"
import { JsonVariablePayInput } from "v2/react/shared/JsonFormControls/VariablePayControl"
import { GroupLayoutTester } from "v2/react/shared/JsonFormLayouts/LayoutTesters"
import { JsonModuleGroupLayout } from "v2/react/shared/JsonFormLayouts/ModuleGroupLayout"
import { JsonPanelGroupLayout } from "v2/react/shared/JsonFormLayouts/PanelGroupLayout"

export const createRenderers = [
  ...vanillaRenderers,
  { tester: AutocompleteControlTester, renderer: withJsonFormsControlProps(JsonAutocompleteInput) },
  { tester: BasePayControlTester, renderer: withJsonFormsControlProps(JsonBasePayInput) },
  { tester: CurrencyControlTester, renderer: withJsonFormsControlProps(JsonCurrencyInput) },
  { tester: DateControlTester, renderer: withJsonFormsControlProps(JsonDateInput) },
  { tester: FileUploadControlTester, renderer: withJsonFormsControlProps(JsonFileUpload) },
  { tester: GroupLayoutTester, renderer: withJsonFormsLayoutProps(JsonPanelGroupLayout) },
  { tester: MultiSelectControlTester, renderer: withJsonFormsControlProps(JsonMultiSelectInput) },
  { tester: NestedTextControlTester, renderer: withJsonFormsControlProps(JsonNestedTextInput) },
  { tester: NumberControlTester, renderer: withJsonFormsControlProps(JsonNumberInput) },
  { tester: NumericControlTester, renderer: withJsonFormsControlProps(JsonNumericInput) },
  { tester: OrgUnitControlTester, renderer: withJsonFormsControlProps(JsonOrgUnitInput) },
  { tester: PercentControlTester, renderer: withJsonFormsControlProps(JsonPercentInput) },
  { tester: PersonControlTester, renderer: withJsonFormsControlProps(JsonPersonInput) },
  { tester: PositionControlTester, renderer: withJsonFormsControlProps(JsonPositionInput) },
  { tester: RadioControlTester, renderer: withJsonFormsControlProps(JsonRadioInput) },
  { tester: SelectControlTester, renderer: withJsonFormsControlProps(JsonSelectInput) },
  { tester: TextAreaControlTester, renderer: withJsonFormsControlProps(JsonTextAreaInput) },
  { tester: VariablePayControlTester, renderer: withJsonFormsControlProps(JsonVariablePayInput) },
  {
    tester: TotalCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonTotalCompensationInput),
  },
  { tester: TextControlTester, renderer: withJsonFormsControlProps(JsonTextInput) },
]
export const viewRenderers = [
  ...vanillaRenderers,
  { tester: FileControlTester, renderer: withJsonFormsControlProps(JsonModuleFileItem) },
  { tester: GroupLayoutTester, renderer: withJsonFormsLayoutProps(JsonModuleGroupLayout) },
  {
    tester: ListBaseCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListBaseCompensation),
  },
  { tester: ListItemControlTester, renderer: withJsonFormsControlProps(JsonModuleListItem) },
  {
    tester: ListItemDescriptionControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListDescriptionItem),
  },
  {
    tester: ListItemFilledByControlTester,
    renderer: withJsonFormsControlProps(JsonModuleFilledByItem),
  },
  {
    tester: ListItemSubtitledControlTester,
    renderer: withJsonFormsControlProps(JsonModuleSubtitledItem),
  },
  {
    tester: ListOrgUnitsControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListOrgUnits),
  },
  {
    tester: ListTotalCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListTotalCompensation),
  },
  {
    tester: ListVariableCompensationControlTester,
    renderer: withJsonFormsControlProps(JsonModuleListVariableCompensation),
  },
]
