import { Meta } from "@storybook/react"
import React from "react"

import { CareerLadder } from "types/graphql"
import RootProvider from "v2/react/components/RootProvider"

import { Builder } from "./Builder"

const oneLevelCareerLadder: CareerLadder = {
  id: "1",
  uniqueKey: "career_ladder_1",
  name: "Test Career Ladder",
  careerLadderTracks: [
    {
      id: "1",
      uniqueKey: "career_ladder_track_1",
      name: "Main Track",
      parentTrackId: null,
      careerLadderTrackPositionTypes: [
        {
          sortOrder: 1,
          positionType: {
            id: "position_type_4",
            title: "Job Definition Title 4",
            jobCode: "ASDF4242",
          },
        },
        {
          sortOrder: 2,
          positionType: {
            id: "position_type_3",
            title: "Job Definition Title 3",
            jobCode: "ASDF4243",
          },
        },
        {
          sortOrder: 3,
          positionType: {
            id: "position_type_2",
            title: "Job Definition Title 2",
            jobCode: "ASDF4244",
          },
        },
        {
          sortOrder: 4,
          positionType: {
            id: "position_type_1",
            title: "Job Definition Title 1",
            jobCode: "ASDF4245",
          },
        },
      ],
    },
  ],
}

const twoLevelCareerLadder = {
  ...oneLevelCareerLadder,
  careerLadderTracks: [
    ...(oneLevelCareerLadder.careerLadderTracks || []),
    {
      id: "2",
      uniqueKey: "career_ladder_track_2",
      name: "Individual Contributor Track",
      parentTrackId: "1",
      careerLadderTrackPositionTypes: [
        {
          sortOrder: 0,
          positionType: {
            id: "position_type_5",
            title: "Job Definition Title 5",
            jobCode: "ASDF4252",
          },
        },
        {
          sortOrder: 1,
          positionType: {
            id: "position_type_6",
            title: "Job Definition Title 6",
            jobCode: "ASDF4253",
          },
        },
        {
          sortOrder: 2,
          positionType: {
            id: "position_type_7",
            title: "Principal Product Designer",
            jobCode: "GH6745",
            sortOrder: 2,
          },
        },
      ],
    },
    {
      id: "3",
      uniqueKey: "career_ladder_track_3",
      name: "Leadership Track",
      parentTrackId: "1",
      careerLadderTrackPositionTypes: [
        {
          sortOrder: 0,
          positionType: {
            id: "position_type_8",
            title: "Leadership Job Definition Title 1",
            jobCode: "ASDF4262",
          },
        },
        {
          sortOrder: 1,
          positionType: {
            id: "position_type_9",
            title: "Leadership Job Definition Title 2",
            jobCode: "ASDF4263",
          },
        },
      ],
    },
  ],
}

const threeLevelCareerLadder = {
  ...twoLevelCareerLadder,
  careerLadderTracks: [
    ...twoLevelCareerLadder.careerLadderTracks,
    {
      id: "4",
      uniqueKey: "career_ladder_track_4",
      name: "Other Track",
      parentTrackId: "1",
      careerLadderTrackPositionTypes: [
        {
          sortOrder: 2,
          positionType: {
            id: "position_type_11",
            title: "Other Job Definition Title 1",
          },
        },
        {
          sortOrder: 2,
          positionType: {
            id: "position_type_12",
            title: "Other Job Definition Title 2",
          },
        },
        {
          sortOrder: 2,
          positionType: {
            id: "position_type_13",
            title: "Other Job Definition Title 3",
          },
        },
      ],
    },
  ],
}

const meta: Meta<typeof Builder> = {
  component: Builder,
  decorators: [
    (Story) => (
      <RootProvider>
        <div style={{}}>
          <Story />
        </div>
      </RootProvider>
    ),
  ],
  title: "Career Ladders/Builder",
}
export default meta

export const OneLevel = {
  args: {
    careerLadder: oneLevelCareerLadder,
  },
}

export const TwoLevels = {
  args: {
    careerLadder: twoLevelCareerLadder,
  },
}

export const SmallMode = {
  args: {
    activePositionTypeId: "position_type_1",
    careerLadder: twoLevelCareerLadder,
    smallMode: true,
  },
}

export const ThreeLevels = {
  args: {
    careerLadder: threeLevelCareerLadder,
  },
}

export const Empty = {
  args: {
    careerLadder: null,
  },
}
