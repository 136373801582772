import React from "react"

import { AddOrgUnitModal, AddOrgUnitModalProps } from "v2/react/components/orgUnits/AddOrgUnitModal"
import type { NodeRow } from "v2/react/shared/Datasheet/types"
import { useDatasheetListenerActions } from "v2/redux/listeners/datasheetListeners"
import {
  getInitialDataFromEncoded,
  toEncodedIdNamePair,
} from "v2/redux/slices/GridSlice/gridHelpers/orgUnits"
import type { GridState } from "v2/redux/slices/GridSlice/types"
import { preparePersonPositionNodeAttributes } from "v2/redux/slices/NodeSlice/mutations/preparePersonPositionNodeAttributes"
import type { EnhancedNodeInterface } from "v2/redux/slices/NodeSlice/types"

import { usePreparedUpdatePersonPositionNodeMutation } from "../hooks/useUpdatePersonPositionNodeFunc"

type AddOrgUnitFollowUpProps = {
  /**
   * IMPORTANT! This relies on a slight hack where the incoming value will be
   * prefixed with `custom:`. We ought to clean this when time permits to
   * refactor the data flow so it supports types other than strings.
   *
   * @see helpers webpack/v2/redux/slices/GridSlice/gridHelpers/orgUnits.ts
   */
  field: NonNullable<GridState["followUpModal"]["field"]>
  row: NodeRow<EnhancedNodeInterface>
  isOpen: boolean
}

function AddOrgUnitFollowUp({ isOpen, row, field }: AddOrgUnitFollowUpProps) {
  const initialData = getInitialDataFromEncoded(field.value)
  const { cancelFollowUp, saveModalSuccess } = useDatasheetListenerActions()
  const { execute } = usePreparedUpdatePersonPositionNodeMutation()

  const handleSave: AddOrgUnitModalProps["onSave"] = async (orgUnit) => {
    const attributes = preparePersonPositionNodeAttributes({
      fieldKey: field.fieldKey,
      node: row.data,
      value: toEncodedIdNamePair(orgUnit.id ?? "", getOrgUnitFullName(orgUnit)),
    })

    // It is expected that the NodeApi module will dispatch error information
    // into Redux, so there's no special handling here.
    const result = await execute({ id: row.id, attributes, primaryTrigger: field.fieldKey })
    if (result.ok) saveModalSuccess()
  }

  return (
    <AddOrgUnitModal
      initialData={initialData}
      isOpen={isOpen}
      onClose={cancelFollowUp}
      onSave={handleSave}
      orgUnitType={{ name: field.label, id: field.fieldKey }}
    />
  )
}

const getOrgUnitFullName = (
  orgUnit: Parameters<NonNullable<AddOrgUnitModalProps["onSave"]>>[0],
) => {
  if (!orgUnit.code || orgUnit.code === "") return orgUnit.name ?? ""
  if (!orgUnit.name || orgUnit.name === "") return orgUnit.code ?? ""
  return `${orgUnit.code} - ${orgUnit.name}`
}

export { AddOrgUnitFollowUp, AddOrgUnitFollowUpProps }
