import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { Position, PositionType } from "types/graphql"
import { Avatar } from "v2/react/shared/Avatar"
import { Spinner } from "v2/react/shared/loaders/Spinner"
import { useLazyGetPositionTypePositionsQuery } from "v2/redux/GraphqlApi/PositionTypesApi"

const PAGE_SIZE = 10

interface Props {
  positionType: PositionType
}

function PeopleList({ positionType }: Props) {
  const [allPositions, setAllPositions] = React.useState<Position[]>([])
  const { t } = useTranslation()
  const scrollBoxRef = useRef<null | HTMLDivElement>(null)

  // https://stackoverflow.com/questions/62587993/how-to-render-usequery-both-on-first-render-and-onclick-reactjs-graphql
  const [queryPositionsTrigger, { data: positionsData, isLoading: positionDataLoading }] =
    useLazyGetPositionTypePositionsQuery()

  useEffect(() => {
    queryPositionsTrigger({
      id: `position_type_${positionType.id}`,
      first: PAGE_SIZE,
    })
  }, [positionType.id, queryPositionsTrigger])

  useEffect(() => {
    setAllPositions((prevValue) => [
      ...prevValue,
      ...(positionsData?.positionType?.positions?.nodes || []),
    ])
  }, [positionsData])

  useEffect(() => {
    // If subsequent pages of positions are loaded, scroll to the bottom of the
    // list to show the new positions.
    if (scrollBoxRef.current && allPositions.length > PAGE_SIZE) {
      // See: https://stackoverflow.com/a/65371198
      scrollBoxRef.current.scroll({ top: scrollBoxRef.current.scrollHeight, behavior: "smooth" })
    }
  }, [allPositions, scrollBoxRef])

  if (
    positionDataLoading ||
    !allPositions.filter((position) => position.people && position.people[0]).length ||
    !positionsData?.positionType?.totalPositionCount
  )
    return null

  const endCursor = positionsData.positionType.positions?.pageInfo?.endCursor
  const nextPageExists = positionsData.positionType.positions?.pageInfo?.hasNextPage

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{t("v2.careers.show.people_with_job")}</div>
      </div>
      <div
        className="module-card__body max-h-[400px] gap-0 overflow-y-auto border-0 border-solid border-neutral-8 p-0"
        ref={scrollBoxRef}
      >
        {(allPositions || []).map((position: Position) => (
          <PositionRow position={position} key={position.id} />
        ))}
      </div>
      {!positionDataLoading && nextPageExists && (
        <div className="justify-center border-0 border-t border-solid border-neutral-8 p-4 flex">
          <button
            className="bg-white text-neutral-64"
            type="button"
            onClick={() => {
              queryPositionsTrigger({
                id: `position_type_${positionType.id}`,
                first: PAGE_SIZE,
                after: endCursor,
              })
            }}
          >
            <span>{t("v2.position_types.show.view_more", { count: PAGE_SIZE })}</span>
            <span className="pl-2">
              <FontAwesomeIcon icon={["far", "chevron-down"]} />
            </span>
          </button>
        </div>
      )}
      {positionDataLoading && (
        <div className="p-4">
          <Spinner style={{ position: "relative", height: "1rem", width: "1rem" }} />
        </div>
      )}
    </div>
  )
}

export { PeopleList }

const PositionRow = ({ position }: { position: Position }) => {
  const primaryPerson = position.people ? position.people[0] : null
  if (!primaryPerson) return null
  return (
    <div className="border-0 border-t border-solid border-neutral-8 px-4 py-4 flex">
      <div className="items-center pr-2 flex">
        {primaryPerson?.avatarThumbUrl ? (
          <Avatar person={primaryPerson} size="med" />
        ) : (
          <div className="h-8 w-8 shrink-0 grow-0 items-center justify-center rounded-full bg-primary-8-solid text-primary-100 flex">
            <FontAwesomeIcon icon={["far", "chair-office"]} />
          </div>
        )}
      </div>
      <div className="flex-col justify-center flex">
        <p className="text-base">{primaryPerson.name}</p>
        {primaryPerson.workEmail && (
          <p className="text-sm text-neutral-64">{primaryPerson.workEmail}</p>
        )}
      </div>
    </div>
  )
}
