import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ControlProps } from "@jsonforms/core"
import classNames from "classnames"
import React, { ChangeEvent, FC, useState } from "react"

import { isDisabledFromSchema } from "v2/react/shared/JsonFormControls/utils/forms"

import { useValidation } from "./hooks/useValidation"
import { InputErrorText } from "./InputErrorText"

type PercentControlProps = Pick<
  ControlProps,
  "config" | "data" | "enabled" | "handleChange" | "id" | "label" | "path" | "schema"
>

const JsonPercentInput: FC<PercentControlProps> = ({
  config,
  data,
  enabled,
  id,
  handleChange,
  label,
  path,
  schema,
}: PercentControlProps) => {
  const isDisabled = isDisabledFromSchema({ enabled, schema })
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const { showError, errorMessage } = useValidation({
    data,
    path,
    schema,
    submitting: config.submitting,
  })

  const handleChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    // Presently percents are handled as integers, future support for decimal
    // values would need to be enabled (same goes for currency input support).
    let numberValue: number | null = Number(event.target.value)
    if (Number.isNaN(numberValue)) numberValue = null
    handleChange(path, numberValue)
  }

  return (
    <div className={classNames("input-group", { "form-error": showError })}>
      {label && (
        <label
          htmlFor={id}
          className="cursor-default"
          onMouseEnter={handleFocus}
          onMouseLeave={handleBlur}
        >
          {label}
        </label>
      )}
      <div
        className={classNames("relative", {
          active: isFocused,
          readonly: isDisabled,
        })}
      >
        <div className="prefix">
          <FontAwesomeIcon icon={["far", "percent"]} />
        </div>
        <input
          type="text"
          className="input prefix-pad"
          id={id}
          name={id}
          value={data || ""}
          onChange={handleChangeEvent}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
      </div>
      <InputErrorText message={errorMessage} showError={showError} />
    </div>
  )
}

export { JsonPercentInput }
