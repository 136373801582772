import fp from "lodash/fp"
import React, { Ref } from "react"
import { defaultCellRangeRenderer } from "react-virtualized"

import { CellProps } from "v2/react/shared/Datasheet/Cell"
import { ColumnDropzoneProps } from "v2/react/shared/Datasheet/ColumnDropzone"
import { GridBody } from "v2/react/shared/Datasheet/GridBody"
import { GridHeader } from "v2/react/shared/Datasheet/GridHeader"
import { Column, CursorConnection, GroupRow, NodeRow } from "v2/react/shared/Datasheet/types"

interface ForwardedHeaderProps<CType> {
  onClearFilter: (fieldKey: keyof CType) => void
  onSelectFilter: (fieldKey: keyof CType, term: string) => void
  onSortColumn: (a: keyof CType) => void
  selectFilterValues?: (
    field: Column<CType>,
    isFocused?: boolean,
  ) => { value: string | number; label: string }[]
}

interface CoreProps<TNode, CType> {
  CellComponent?: (props: CellProps<TNode, CType>) => React.ReactNode
  cellRangeRenderer?: typeof defaultCellRangeRenderer
  columns: Column<CType>[]
  containerStyle?: React.CSSProperties
  cursorConnection?: CursorConnection
  height: number
  onCellClick?: (row: NodeRow<TNode>, column: Column<CType>) => void
  onExpandCollapseGrouping: (row: GroupRow) => void
  rows: (NodeRow<TNode> | GroupRow)[]
  sheetRef?: Ref<{
    contains: (element: Node | null) => boolean
    scrollToCell: (c: { rowIndex: number; columnIndex: number }) => void
  }>
  // IMPORTANT: It's planned that this can be bigger than the container (thus
  // enabling horizontal scroll).
  sheetWidth: number
  sortBy: Omit<Column<CType>, "width">
  sortDirection: "asc" | "desc"
}

type DatasheetProps<TNode, CType = TNode> = ForwardedHeaderProps<CType> &
  Omit<ColumnDropzoneProps<CType>, "onAddColumn" | "onRemoveColumn"> &
  CoreProps<TNode, CType>

const fallback = () => []

function Datasheet<TNode, CType = TNode>({
  CellComponent,
  cellRangeRenderer,
  columns,
  containerStyle,
  cursorConnection,
  height,
  onCellClick = fp.noop,
  onClearFilter,
  onExpandCollapseGrouping,
  onSelectFilter,
  onSortColumn,
  rows,
  selectFilterValues,
  sheetRef,
  sheetWidth,
  sortBy,
  sortDirection,
}: DatasheetProps<TNode, CType>) {
  const containerRef = React.useRef<HTMLDivElement>(null)

  return (
    <div className="datasheet-grid Datasheet" style={prepareContainerStyle(containerStyle)}>
      <div ref={containerRef} className="relative">
        <div className="zDatasheetHeaders sticky top-0 bg-white">
          <GridHeader<TNode, CType>
            columns={columns}
            onClearFilter={onClearFilter}
            onSelectFilter={onSelectFilter}
            onSortColumn={onSortColumn}
            selectFilterValues={selectFilterValues || fallback}
            sortBy={sortBy}
            sortDirection={sortDirection}
          />
        </div>
        <GridBody
          CellComponent={CellComponent}
          cellRangeRenderer={cellRangeRenderer}
          columns={columns}
          cursorConnection={cursorConnection}
          height={height}
          onCellClick={onCellClick}
          onExpandCollapseGrouping={onExpandCollapseGrouping}
          rowCount={rows.length}
          rows={rows}
          sheetRef={sheetRef}
          width={sheetWidth}
          containerRef={containerRef}
        />
      </div>
    </div>
  )
}

const ContainerStyleDefault: React.CSSProperties = { width: "100%" }
const prepareContainerStyle = (containerStyle?: React.CSSProperties) => ({
  ...ContainerStyleDefault,
  ...(containerStyle || {}),
})

const typedMemo: <T>(c: T) => T = React.memo
const MemoedDatasheet = typedMemo(Datasheet)

export { Datasheet, DatasheetProps, MemoedDatasheet }
