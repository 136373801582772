import type { CalendarDate } from "@internationalized/date"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"

dayjs.extend(quarterOfYear)

type PresetDateRangeKey =
  | "current_month"
  | "current_quarter"
  | "current_year"
  | "last_month"
  | "last_quarter"
  | "last_year"
  | "last_12_months"
  | "month_to_date"
  | "quarter_to_date"
  | "year_to_date"
  | "custom_date_range"

type DateRangeOption = Exclude<PresetDateRangeKey, "custom_date_range">

interface Range {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
  label: string
  value: DateRangeOption
}

const buildYearOptions = ({
  minYear,
  maxYear,
  activeYear,
}: {
  minYear: number
  maxYear: number
  activeYear: number
}) => {
  const range = maxYear - minYear + 1
  const activeStart = Math.max(activeYear - 5, minYear)
  const activeEnd = Math.min(activeYear + 5, maxYear)

  // We truncate the options if the total span is greater than 50 years.
  if (range > 50) {
    const earlyYears = Array.from({ length: 10 }, (_, i) => ({
      id: minYear + i,
      label: minYear + i,
    }))
    const lateYears = Array.from({ length: 10 }, (_, i) => ({
      id: maxYear - 9 + i,
      label: maxYear - 9 + i,
    }))
    const activeYears = Array.from({ length: activeEnd - activeStart + 1 }, (_, i) => ({
      id: activeStart + i,
      label: activeStart + i,
    }))

    const uniqueYears = new Map()
    ;[...earlyYears, ...activeYears, ...lateYears].forEach((year) => uniqueYears.set(year.id, year))
    return Array.from(uniqueYears.values()).sort((a, b) => a.id - b.id)
  }

  return Array.from({ length: range }, (_, i) => ({ id: minYear + i, label: minYear + i }))
}

const buildDateRangeOptions = (currentDate: CalendarDate): Record<DateRangeOption, Range> => {
  const today = dayjs(currentDate.toString())

  return {
    current_month: {
      start: today.startOf("month"),
      end: today.endOf("month"),
      label: today.startOf("month").format("MMM YYYY"),
      value: "current_month",
    },
    current_quarter: {
      start: today.startOf("quarter"),
      end: today.endOf("quarter"),
      label: `${today.startOf("quarter").format("MMM D")} - ${today
        .endOf("quarter")
        .format("MMM D, YYYY")}`,
      value: "current_quarter",
    },
    current_year: {
      start: today.startOf("year"),
      end: today.endOf("year"),
      label: today.startOf("year").format("YYYY"),
      value: "current_year",
    },
    last_month: {
      start: today.subtract(1, "month").startOf("month"),
      end: today.subtract(1, "month").endOf("month"),
      label: today.subtract(1, "month").startOf("month").format("MMM YYYY"),
      value: "last_month",
    },
    last_quarter: {
      start: today.subtract(1, "quarter").startOf("quarter"),
      end: today.subtract(1, "quarter").endOf("quarter"),
      label: `${today.subtract(1, "quarter").startOf("quarter").format("MMM D")} - ${today
        .subtract(1, "quarter")
        .endOf("quarter")
        .format("MMM D, YYYY")}`,
      value: "last_quarter",
    },
    last_year: {
      start: today.subtract(1, "year").startOf("year"),
      end: today.subtract(1, "year").endOf("year"),
      label: today.subtract(1, "year").startOf("year").format("YYYY"),
      value: "last_year",
    },
    last_12_months: {
      start: today.subtract(1, "year"),
      end: today,
      label: `${today.subtract(1, "year").format("MMM D, YYYY")} - ${today.format("MMM D, YYYY")}`,
      value: "last_12_months",
    },
    month_to_date: {
      start: today.startOf("month"),
      end: today,
      label: `${today.startOf("month").format("MMM D")} - ${today.format("MMM D, YYYY")}`,
      value: "month_to_date",
    },
    quarter_to_date: {
      start: today.startOf("quarter"),
      end: today,
      label: `${today.startOf("quarter").format("MMM D")} - ${today.format("MMM D, YYYY")}`,
      value: "quarter_to_date",
    },
    year_to_date: {
      start: today.startOf("year"),
      end: today,
      label: `${today.startOf("year").format("MMM D")} - ${today.format("MMM D, YYYY")}`,
      value: "year_to_date",
    },
  }
}

export { buildDateRangeOptions, buildYearOptions }
export type { DateRangeOption, PresetDateRangeKey }
