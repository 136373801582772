import React from "react"
import { useTranslation } from "react-i18next"

import { EmptyState } from "v2/react/components/careers/Show/EmptyState"
import { sanitizeParse } from "v2/react/utils/sanitizeParse"

interface SummaryProps {
  summary: string
}

const Summary = ({ summary }: SummaryProps) => {
  const { t } = useTranslation()
  const summaryText = t("v2.careers.show.summary")

  return (
    <div className="module-card">
      <div className="module-card__header">
        <div className="module-title">{summaryText}</div>
      </div>
      <div className="module-card__body">
        {summary ? (
          <div>{sanitizeParse(summary)}</div>
        ) : (
          <EmptyState
            note={sanitizeParse(
              t("v2.careers.show.empty_module_html", {
                count: 1,
                property: summaryText.toLowerCase(),
              }),
            )}
            icon="fac short-text"
          />
        )}
      </div>
    </div>
  )
}

export { Summary }
