import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { forwardRef, useState } from "react"

import { InputWrapper } from "v2/react/shared/forms/InputWrapper"

interface PercentInputProps {
  id: string
  name: string
  label?: string
  defaultValue?: string | undefined | number
  errors?: string
  showErrorMessage?: boolean
  useAttentionState?: boolean
  wrapperClassName?: string
  inputClassName?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PercentInput = forwardRef<HTMLInputElement, PercentInputProps>(
  (
    {
      id,
      name,
      label,
      defaultValue,
      errors,
      showErrorMessage = true,
      useAttentionState,
      wrapperClassName,
      inputClassName,
      onChange,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputValue, setInputValue] = useState<string>(defaultValue?.toString() || "")

    const showAttention = useAttentionState && !isFocused && !errors && !inputValue.trim()

    const handleFocus = () => setIsFocused(true)
    const handleBlur = () => setIsFocused(false)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      onChange?.(e)
    }

    return (
      <InputWrapper
        label={label}
        id={id}
        errors={errors}
        showErrorMessage={showErrorMessage}
        className={wrapperClassName}
      >
        <div
          className={classNames("relative", {
            active: isFocused,
            attention: showAttention,
          })}
        >
          <div className="prefix">
            <FontAwesomeIcon icon={["far", "percent"]} />
          </div>
          <input
            ref={ref}
            type="text"
            className={classNames("input prefix-pad", inputClassName)}
            id={id}
            name={name}
            defaultValue={`${defaultValue || ""}`}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </InputWrapper>
    )
  },
)

export { PercentInput }
